import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../provider/AuthProvider";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { serverbaseURL } from "../../constant/index";
import { toast } from 'react-toastify';


const SellerRegistration = () => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate(); // Initialize useNavigate hook

    const [sellerData, setSellerData] = useState({
        Creatorname: "",
        email: "",
        phoneNumber: "",
        file: null,
    });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const sellerId = user?.uid;

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target; // Corrected `name` usage
        setSellerData((prev) => ({ ...prev, [name]: value }));
    };

    // Handle file input
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith("image/")) {
            setSellerData((prev) => ({ ...prev, file }));
            setError(""); // Clear any previous error related to the file
        } else {
            setError("File must be an image.");
        }
    };

    // Validate inputs
    const validateInputs = () => {
        const { Creatorname, email, phoneNumber, file } = sellerData;

        if (!Creatorname || !email || !phoneNumber || !file) {
            setError("All fields are required.");
            return false;
        }

        if (!/^\d{10}$/.test(phoneNumber)) {
            setError("Invalid phone number. It must be 10 digits.");
            return false;
        }
        setError(""); // Clear errors
        return true;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!sellerId) {
            setError("User not authenticated.");
            return;
        }

        if (!validateInputs()) return;

        setLoading(true);

        const formData = new FormData();
        formData.append("sellerId", sellerId);
        formData.append("Creatorname", sellerData.Creatorname);
        formData.append("email", sellerData.email);
        formData.append("phoneNumber", sellerData.phoneNumber);
        formData.append("file", sellerData.file);
        formData.append("uid", user?.uid);
        try {
            const response = await axios.post(
                `${serverbaseURL}seller-registration`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            toast.success(response.data.message);
            setSellerData({
                Creatorname: "",
                email: "",
                phoneNumber: "",
                file: null,
            }); // Reset form
            navigate("/marketplace/seller/dashboard"); // Redirect after success
        } catch (err) {
            setError(err.response?.data?.message || "Server error occurred.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className=" mx-auto mt-20 max-w-md bg-white p-6 rounded-lg shadow-md">
            <h1 className="text-3xl font-extrabold mb-6 text-center text-gray-800">Register as Seller</h1>
            <form onSubmit={handleSubmit} className="space-y-5">
                <div>
                    <label htmlFor="Creatorname" className="block text-sm font-medium text-gray-600">
                        Creator Name
                    </label>
                    <input
                        type="text"
                        id="Creatorname"
                        name="Creatorname"
                        value={sellerData.Creatorname}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        placeholder="Enter your name"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-600">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={sellerData.email}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        placeholder="Enter your email"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-600">
                        Phone Number
                    </label>
                    <input
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={sellerData.phoneNumber}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        placeholder="Enter your phone number"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="file" className="block text-sm font-medium text-gray-600">
                        Upload Logo
                    </label>
                    <input
                        type="file"
                        id="file"
                        name="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        required
                    />
                </div>
                {error && <p className="text-red-500 text-sm text-center">{error}</p>}
                <button
                    type="submit"
                    className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white py-2 rounded-md font-semibold hover:from-blue-600 hover:to-blue-700 shadow-md transition-all duration-300"
                    disabled={loading}
                >
                    {loading ? "Submitting..." : "Register as seller"}
                </button>
            </form>
        </div>

    );
};

export default SellerRegistration;
