import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ReactFlowProvider } from 'reactflow';
import { AuthContext } from "../../provider/AuthProvider";
import { serverbaseURL } from "../../constant/index";
import Flo from '../../Flo';
import { useReactFlow } from 'reactflow';

function FlowEditor({ template, mode }) {
  const { setNodes, setEdges } = useReactFlow();

  useEffect(() => {
    if (template) {
      try {
        const templateData = template.data;
        setNodes(nodes => 
          templateData.nodes.map(node => ({
            ...node,
            draggable: mode === 'edit',
            connectable: mode === 'edit',
            selectable: mode === 'edit',
            deletable: mode === 'edit',
            data: {
              ...node.data,
              isEditable: mode === 'edit',
              mode: mode
            },
            style: {
              ...node.style,
              pointerEvents: mode === 'edit' ? 'all' : 'none'
            }
          }))
        );
        setEdges(edges =>
          templateData.edges.map(edge => ({
            ...edge,
            interactionWidth: mode === 'edit' ? 20 : 0,
            selectable: mode === 'edit',
            updatable: mode === 'edit',
            deletable: mode === 'edit',
            style: {
              ...edge.style,
              pointerEvents: mode === 'edit' ? 'all' : 'none'
            }
          }))
        );
      } catch (error) {
        console.error('Error loading template:', error);
      }
    }
  }, [template, mode, setNodes, setEdges]);

  console.log('Current mode:', mode);

  return <Flo mode={mode} />;
}

export default function CreateTemplate() {
  const [showSellerModal, setShowSellerModal] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { mode, template } = location.state || {};

  useEffect(() => {
    const checkSellerStatus = async () => {
      try {
        if (!user?.uid) {
          throw new Error('User not authenticated');
        }
        const userResponse = await axios.post(`${serverbaseURL}get_user_details`, {
          uid: user.uid,
        });
        if (!userResponse.data.isSeller) {
          setShowSellerModal(true);
        }
      } catch (err) {
        console.error(err);
      }
    };
    checkSellerStatus();
  }, [user]);

  const handleRegisterAsSeller = () => {
    navigate('/marketplace/seller-registration');
  };

  return (
    <div className="pt-16">
      {!showSellerModal && (
        <ReactFlowProvider>
          <FlowEditor template={template} mode={mode} />
        </ReactFlowProvider>
      )}
      
      {showSellerModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity backdrop-blur-sm" />
          
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="relative bg-white rounded-xl max-w-md w-full p-8 shadow-2xl">
              <div className="text-center">
                <svg
                  className="mx-auto h-16 w-16 text-indigo-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                  />
                </svg>
                <h3 className="mt-4 text-xl font-semibold text-gray-900">
                  Become a Seller
                </h3>
                <p className="mt-2 text-gray-600">
                  To create and publish templates, you need to register as a seller first.
                </p>
                <div className="mt-6 space-y-3">
                  <button
                    onClick={handleRegisterAsSeller}
                    className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
                  >
                    Register as Seller
                  </button>
                  <Link
                    to="/marketplace"
                    className="block w-full px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
                  >
                    Explore Templates
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
