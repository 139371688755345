import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Loader2 } from "lucide-react";
import { serverbaseURL } from "../constant/index.jsx";
import { AuthContext } from "../provider/AuthProvider";
import { motion } from 'framer-motion';
import LoginModal from '../components/LoginModal';
import {
  Wallet,
  VideoIcon,
  Paintbrush,
  Mic,
  X,
  Spinner
} from "lucide-react";
import MyGenerations from "./MyGenerations.jsx";
import { toast } from "react-toastify";
import DataInputForm from '../components/DataInputForm';

const Templates = () => {

  const hardcodedTemplates = [
    {
      id: 1,
      title: "Gummy Bear Showdown",
      name: "The Gummy Bear Showdown",
      description: "Dwayne The Rock Johnson and Arnold Schwarzenegger face a hilarious battle with giant gummy bears that come to life, causing playful chaos in a sunny suburban playground.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1731090461/generated_test_images/trwf48fpuarcluodpybt.png",
      previewVideo: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1734499035/Preview_Videos/eypq0ikma5kmcddlnemd.mp4",
      height: 1280,
      width: 1280,
      style: 'RENDER_3D',
      styleUUID: 'debdf72a-91a4-467b-bf61-cc02bdeb69c6',
      music: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1732371604/music/jr7cwyuosslbsyjsgtse.mp3",
      musicDuration: 11.099563,
      storyPrompt: `None`,
      credits: 1,
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      themeTemplate: `i want you create a another story with dwyne johnason and divide that story into 9 scenes and then create text prompt for each scene in details to generate images in leonardo. Treat each prompt as fresh prompt while keeping the clothes and background reference in each prompt same. strictly, In each prompt out of 9, mention characters clothes in text and color in text prompt so it is not missed in any prompt and background. directly give the image prompt nothing else. for reference this is the story - In a whimsical playground sandbox, Dwayne Johnson, clad in a gray tank top adorned with a tribal tattoo, sits cross-legged, fully engrossed in a game of alphabet blocks. Beside him is an amusingly unique character: a toddler's body dressed in a blue-striped polo shirt, but with the head of Arnold Schwarzenegger, complete with a gray beard, glasses, and an enthusiastic expression. Their playful interaction is surrounded by a scatter of colorful ABC blocks, radiating joy.

      The vibrant scene is set against a sunny backdrop featuring a yellow and red playground structure with slides, lush green trees, and an American flag fluttering in the distance. The lighting is bright and cheerful, casting soft shadows in the sandbox, creating a heartwarming atmosphere.

      As the story unfolds, the playful spirit continues in subsequent scenes. In one instance, Arnold's toddler body is juxtaposed with his hyper-realistic facial features, showcasing an exaggerated intense expression while shouting with clenched fists in a bustling playground. The contrasting styles enhance the absurdity of the moment.

      Further along, Dwayne's shocked face becomes a focal point in a vibrant playground filled with children, as he reacts to a surreal swarm of menacing gummy bears that appear to chase him, setting a humorous yet tension-filled scene.

      The climax builds with Dwayne engaged in a comical martial arts battle against a giant red gummy bear, showcasing his determination. In a humorous twist, he grapples with a giant turquoise gummy bear in a suburban front yard, surrounded by an audience of smaller gummy bears watching intently, their expressions adding to the whimsical nature of the unfolding drama.

      The narrative weaves through absurd and surreal moments, emphasizing the blend of humor and charm in the delightful playground setting, creating a story that captures the imagination and tickles the funny bone.

      Scene 1: the rock and arnold A whimsical digital art piece set in a sunny playground sandbox: Dwayne johnason (wearing a gray tank top with tribal tattoo on his arm) sits cross-legged playing with alphabet blocks alongside a uniquely proportioned character - a 1-year-old's body wearing a blue-striped polo shirt but with Arnold Schwarzenegger's head with gray beard and hair with glasses and an enthusiastic expression. Both characters are joyfully engaged with colorful ABC blocks scattered around them. Background features a yellow and red playground structure with slides, green trees, and an American flag waving in the distance. The lighting is bright and cheerful, creating a heartwarming scene with soft shadows in the sandbox. The art style should be detailed 3D rendering with a slight cartoonish quality, emphasizing the playful nature of the scene. The color palette should be vibrant with emphasis on the primary colors of the alphabet blocks.

      scene 2: the rock and arnold Hyper-realistic 3D render of a surreal character: a toddler's body with Arnold Schwarzenegger's head with gray beard and hair with glasses (approximately 3 years old) with a disproportionately large head depicting intense facial expression, wearing a casual outfit consisting of a white and blue horizontal striped polo shirt, olive green shorts, and white socks with shoes. The character is posed in an exaggerated stance with clenched fists and an intense, shouting expression. The character is positioned in a playground setting with wood chips/mulch on the ground, and a yellow and red playground structure with slides in the background. Soft bokeh effect on background figures walking around. The lighting should be natural daylight with trees visible in the far background. The art style should be highly detailed 3D rendering with emphasis on the contrast between the cartoonish body proportions and the hyper-realistic facial features. The character's height should be approximately that of a toddler, around 3 feet tall.

      scene 3: Image is a digitally manipulated, hyper-realistic illustration featuring a bald man (dwayne johnson, the rock) with exaggerated facial features, including wide eyes and an open mouth, conveying a shocked expression. His skin is a medium brown tone with visible textures and pronounced veins on his neck. The background depicts a vibrant playground scene with children and adults, some wearing casual summer clothing. The playground equipment includes colorful slides and climbing structures, primarily in red, yellow, and green. The setting is outdoors, with lush green trees and a clear sky, suggesting a sunny day. The overall composition combines a humorous and surreal style, focusing on the central figure's exaggerated expression against a lively, everyday backdrop. he is the The Rock wearing the gray shirt.

      scene 4: Image is a digitally manipulated, hyper-realistic illustration featuring a bald man (dwayne johnson, the rock) with exaggerated facial features, shaved beard, including wide eyes and an open mouth, conveying a shocked expression. His skin is a medium brown tone with visible textures and pronounced veins on his neck. Behind him, a surreal scene unfolds in a playground setting: hundreds of evil red gummy bears appearing to chase/swarm in the background. The setting is outdoors, with lush green trees and a clear sky, suggesting a sunny day. The overall composition combines a humorous and surreal style, focusing on the central figure's exaggerated expression against a lively, everyday backdrop. he is the The Rock wearing the gray shirt.

      scene 5: Image is a digitally manipulated, hyper-realistic illustration featuring a bald man (dwayne johnson, the rock) in mid-kick martial arts pose wearing a white t-shirt (showing tribal tattoo on arm) and black pants, executing a high kick against a giant red gummy bear. The man's expression shows intense concentration/determination. The red gummy bear stands approximately 6 feet tall with a translucent, candy-like texture and is positioned as if in combat stance. Setting is a suburban yard with a house visible in background, surrounded by hundreds of smaller, multicolored gummy bears in various bright hues (blue, green, yellow, orange, red, purple) creating a sea of candy. The lighting is bright natural daylight filtering through trees, creating glossy highlights on the gummy bears' surfaces. Some gummy bears are shown flying through the air from the impact. The composition should capture the dynamic movement of the kick with slight motion blur. Style should be photorealistic 3D rendering with particular attention to the translucent candy texture of the bears and the natural fabric wrinkles in the clothing. Background should include a gray/white suburban house and green trees. Depth of field should keep the main action in sharp focus while maintaining the vibrant color array of background gummy bears.

      scene 6: Image is a digitally manipulated, hyper-realistic illustration featuring a bald man (dwayne johnson, the rock) gray tank top (with tribal tattoo) and dark pants is locked in battle with a giant turquoise gummy bear. The man is captured sprawled across the bear's back in a combat grapple/submission hold position, with an intense expression of effort (exaggerated screaming face, wide eyes, open mouth). The giant turquoise gummy bear is approximately 8 feet tall with a glossy, translucent candy texture, appearing to be struggling against the hold. Setting is a suburban front yard with a two-story gray house with red chimney visible in background. The scene is surrounded by hundreds of smaller multicolored gummy bears (red, green, yellow, orange) creating an audience effect, each with unique expressions watching the battle. Lighting is natural daylight with trees in background creating dynamic shadows.

      scene 7 : Create a surreal and humorous scene in a suburban neighborhood with a large, dangerous-looking bright blue bear holding a bald man (dwayne johnson, the rock) gray tank top (with tribal tattoo) and dark pants is locked in battle with a giant turquoise gummy bear in a tense bear hug. The bear has sharp, menacing teeth, wide, intense eyes, and a fierce expression, making it appear both comical and threatening. The muscular man, bald and tattooed on his shoulder, looks terrified and is dressed casually with one green Croc sandal. They are surrounded by scattered, colorful gummy bears on the ground, adding a strange contrast to the tension. The backdrop includes charming suburban houses under a cloudy sky, heightening the absurdity of the scene. the rock both hands are clearly visible and no extra body parts are there.The road is filled with 1000 gummy bears.

      scene 8: Create a surreal and humorous scene in a suburban neighborhood with a large army or swarm of big gummy bears chasing and running behind a bald man (dwayne johnson, the rock) gray tank top (with tribal tattoo, and shaved beard) and dark pants is running away from gummy bears army in fear. The muscular man, bald and tattooed on his shoulder, looks terrified and is dressed casually with one green Croc sandal. They are surrounded by scattered, colorful gummy bears on the ground in a running action pose, adding a strange contrast to the tension. The backdrop includes charming suburban houses under a cloudy sky, heightening the absurdity of the scene. the rock both hands are clearly visible and no extra body parts are there.The road is filled with 1000 gummy bears.

      scene 9: surreal, humorous scene in a suburban neighborhood featuring a large red gummy bear with sharp teeth, wide eyes, and a menacing expression, holding a knife and chasing after a terrified bald man resembling Dwayne 'The Rock' Johnson. The muscular man is dressed in a gray tank top with a tribal shoulder tattoo, dark pants, and one green Croc sandal, looking back in fear as he runs. The street is filled with colorful, scattered gummy bears, adding a strange contrast to the tense chase. The scene is set against charming suburban houses under a cloudy sky, enhancing the absurdity. The Rock's hands are clearly visible and empty, with no extra body parts present.
      IMPORTANT: Strictly format the response as [{ "prompt": "..." },{ "prompt": "..." },...] without any extra text or numbering and DO NOT CHANGE THE STRUCTURE OF THE RESPONSE.`
    },
    {
      id: 2,
      title: "Clash of Superheros",
      name: "Clash of Superheros",
      description: "Supergirl leads to a story of strength and friendship, as Supergirl trains to avenge her mentor's fall and ultimately takes down the mighty Thanos.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1730024124/generated_test_images/dl8zt4ijyryqdxayevde.png",
      height: 1280,
      width: 720,
      style: 'RENDER_3D',
      credits: 1,
      styleUUID: 'debdf72a-91a4-467b-bf61-cc02bdeb69c6',
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      music: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1732344435/music/z446mar2gq6jmzobq98f.mp3",
      musicDuration: 59.099563,
      previewVideo: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1734500856/Preview_Videos/u8eh6vitrvz9iuo3z0zk.mp4",
      storyPrompt: `Write a visually captivating revenge story featuring Supergirl, She-Hulk, and Thanos as the main characters. The story should be structured to inspire a series of dynamic and cinematic photo-based scenes. Follow these guidelines:
      Story Arc:
      The narrative revolves around Supergirl taking revenge on Thanos for injuring She-Hulk during an intense battle. Introduce a dramatic training montage where Supergirl grows stronger, and escalate the tension leading to a final showdown. End with a powerful resolution that highlights justice, triumph, and the bond between Supergirl and She-Hulk. Scene Structure: Divide the story into 10 key scenes, each crafted for visual storytelling. For example:
      Scene 1: She-Hulk and Supergirl sparring in a vibrant training ground. Scene 2: Thanos storming in, disrupting their peace. Scene 5: She-Hulk lying defeated as Supergirl vows revenge. Scene 10: Supergirl overpowering Thanos in an explosive battle. Character Details:
      Supergirl: Portray her as radiant and strong, with glowing confidence. Describe her youthful features, flowing blonde hair, and iconic red-and-blue Kryptonian suit. Highlight her resilience and fiery determination. She-Hulk: Depict her as a towering, muscular figure with vibrant green skin and long black hair. She wears a modern combat suit with purple accents. Show her as both a fighter and a symbol of loyalty. Thanos: Present him as an imposing villain with rugged, purple skin, clad in golden armor that gleams ominously. Emphasize his cruelty and unyielding power. Visual Details for Each Scene:
      Environment: Vibrant and dramatic settings—training grounds, battlefields, cosmic landscapes. Lighting: Use cinematic, dynamic lighting with contrasts to heighten emotions. Colors: Bold, vibrant hues for heroes; darker, ominous tones for Thanos. Action: Detailed and dramatic poses to convey motion and intensity. Storytelling Style:
      Use short, vivid sentences for high impact. Describe the emotions, expressions, and physical actions of characters in great detail. Focus on the visual aspects of each scene to make them easily translatable into high-quality imagery. End with a powerful and hopeful conclusion, where Supergirl and She-Hulk reaffirm their friendship, standing victorious amidst a stunning background of light and colors."`,
      themeTemplate: `None`
    },
    {
      id: 3,
      title: "Kitten and Dad",
      name: "Kitten and Dad",
      description: "A heartwarming story of a playful kitten and her loving dad bear as they navigate a hilarious and slimy mess. Together, they discover the strength of their bond amidst the chaos, creating a cozy and unforgettable adventure.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733077022/Template%20Card%20Images/rgh6wppcghpek0kysy4n.png",
      previewVideo: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1734498825/Preview_Videos/hkstd8goesmkfgdbrkk9.mp4",
      height: 1280,
      width: 720,
      style: 'RENDER_3D',
      styleUUID: 'debdf72a-91a4-467b-bf61-cc02bdeb69c6',
      credits: 1,
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      music: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1732338442/music/i61n1kk3gvhd31lihfkz.mp3",
      musicDuration: 28.099563,
      storyPrompt: `Create a unique story with kitten doing something stupid and cute then creates a problem and his dad needs to solve it add drama and excitement to it with a unexpected ending (sad or happy) using boring words and only these two characters without creating any new one -
      Tabby Cat Dad - 
      Body Color: Orange-and-brown striped fur, typical of a tabby cat. Clothing: Wears a cozy blue sweater paired with beige pants. The sweater has a soft, knitted texture, and the pants are slightly loose for a relaxed, casual look. Personality: Gentle and caring, with a patient demeanor. He exudes warmth and affection, though he's occasionally flustered by his kittens antics. Despite the chaos, he maintains a sense of humor and an unmistakable bond with his little one. Role: The responsible, loving father navigating the playful chaos of parenting.
      Kitten - 
      Body Color: Light cream-colored fur, soft and fluffy, giving an innocent and adorable appearance. Clothing: Dressed in a tiny yellow onesie that fits snugly, adding to its charm. The onesie is often messy due to its energetic and curious nature. Personality: Mischievous and full of energy, with an adventurous spirit. The kitten loves exploring and finds joy in the simplest things. Its playful demeanor contrasts with its fathers composed attitude, creating a delightful dynamic. Role: The curious and cheeky child, constantly on the move, bringing laughter and chaos to the scene.`,
      themeTemplate: `None`
    },
    {
      id: 4,
      title: "Gautam Buddha",
      name: "Gautam Buddha",
      description: "A troubled king learns the power of silence and self-reflection under the guidance of a wise monk, discovering inner peace in a quiet forest.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733077552/Template%20Card%20Images/potnmzotqqwrnwztqnwm.png",
      previewVideo: "https://res.cloudinary.com/dj3qabx11/video/upload/v1734199782/final-videos/final-video-1734199768027.mp4",
      height: 1280,
      width: 720,
      style: 'BOKEH',
      styleUUID: '9fdc5e8c-4d13-49b4-9ce6-5a74cbb19177',
      music: "None",
      credits: 1,
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      musicDuration: "None",
      storyPrompt: `Create a narration story under 125 words inspired by monk Gautama buddha which teaches the meaning of life or peace or other morals said by him.`,
      themeTemplate: `None`,
      narratorId: `pNInz6obpgDQGcFmaJgB`,
    },
    {
      id: 5,
      title: "Watermelon Cat",
      name: "Watermelon Cat",
      description: "A cat with a watermelon head and a watermelon body, wearing a red bandana around its neck, and a pair of sunglasses on its head. It is sitting on a bench with a watermelon in its hands, looking at the camera.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733077353/Template%20Card%20Images/qksiuuryd59gjjsojmbr.png",
      previewVideo: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1734499249/Preview_Videos/snstpeebaqtk64ytk4gl.mp4",
      height: 1280,
      width: 720,
      style: 'RENDER_3D',
      credits: 1,
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      styleUUID: 'debdf72a-91a4-467b-bf61-cc02bdeb69c6',
      music: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1733078303/music/ltp0lg8qzbmlsvfjhfeu.mp3",
      musicDuration: 55.099563,
      storyPrompt: `Create A unique and fantasy story under 150 words with suspense and thrill and action Using three characters, do not name the characters just refer them as mother cat and kitten and villian. also the mother cat is very giant like a fantasy character. A kitten whose fur is like (light green and dark green striped)and her mother cat who is also has fur like like (light green and dark green striped) cat, strictly mention their light and dark green striped fur. And a villian is a human and make sure there is intro , suspense, reveal, action, climax, happy ending.`,
      themeTemplate: `None`
    },
    {
      id: 6,
      title: "You have Entered Creator Mode",
      name: "You have Entered Creator Mode",
      description: "Step into an exclusive realm where creativity reigns supreme. You've been chosen to join the Creator Mode, a space bursting with limitless possibilities, where imagination takes center stage, and every idea can come to life.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733077845/Template%20Card%20Images/arfoia48iks3jxn4ttif.png",
      previewVideo: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1734499869/Preview_Videos/grfpnogkjwuurd3j5pgf.mp4",
      height: 1280,
      width: 720,
      style: 'ILLUSTRATION',
      styleUUID: '645e4195-f63d-4715-a3f2-3fb1e6eb8c70',
      music: "None",
      credits: 1,
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      musicDuration: "None",
      narratorId: `pNInz6obpgDQGcFmaJgB`,
      storyPrompt: `Create a motivation narration under 125 words inspired by the this "you're here not by coincidence not by chance something much bigger than you and I has guided you to this exact moment you've entered Creator mode and if you're still watching it's because you've been chosen by them now before we go any further let's clear something up who are they is this some cryptic Force some hidden power watching your every move the the truth is deeper than that they are not an external Force some shadowy figures pulling strings they are the universe they are the energy that runs through all 
      things the collective consciousness of all that has ever existed and will ever exist and now they have chosen you click subscribe to this channel to get more profound spiritual lessons [Music] What does Creator Mode mean you're not just another person scrolling through life passively consuming information you've been activated you're now in the driver's seat of your own reality but what does Creator mode mean it means that from this moment on you're no longer bound by the rules that confine most people you're no longer a victim of circumstance tossed around by The Winds of Fate you are the AR architect of your own Universe 
      think about it how often do you feel like life is happening to you like you're being pushed in directions you didn't choose he that ends now because the moment you entered this space you crossed a threshold you've been given access to the same energy that drives the stars that moves the oceans and breathes life into this planet you have the ability to shape and bend reality itself but the question is are you ready for that kind of Are you ready responsibility the first thing you need to understand about Creator mode is that it's not a gift it's a challenge you've been chosen because you're ready for this even if you don't fully realize it yet
      you're ready to step into your power but with great power comes great responsibility the universe has been watching you testing you preparing in you for this moment and if you're feeling a sense of unease right now that's normal Because deep down you know that once you Embrace this there's no going back now let's get practical how do you actually enter Creator mode how do you start manifesting your own reality it starts with your perception most people live their entire lives seeing the world through a foggy lens they see limitation obstacles reasons why things won't work out but in Creator mode you don't see limitations you see opportunities
      every challenge becomes a stepping stone every setback becomes fuel for your growth you stop asking why is this happening to me and start asking how can I use How can I use this this you see when you're in Creator Mode Nothing is wasted every experience every emotion every moment becomes a tool for creation you stop being reactive and start being proactive you stop waiting for the universe to hand you what you want and you start taking action knowing that the universe is working with you not against you but here's the twist you're not creating in isolation you're co-creating with the universe and this is where they come back into the picture they 
      are the subtle forces the synchronicities The Whispers of intuition that guide you you've probably already had moments where you felt this where things just seemed to align perfectly where the right person the right opportunity the right idea came to you at exactly the right time that's not a coincidence that's the universe stepping in to say we're with you keep going [Music] How do I know if Im in alignment but here's the big question how do you know if you're truly in alignment with this Universal flow how do you know if you're really co-creating or if you're just forcing things to happen the answer is simple but profound you know you're in 
      alignment when things feel effortless that doesn't mean there won't be challenges it means you flow through those challenges with a sense of calm and certainty you know deep down that everything is unfolding exactly as it should there's no need to control or manipulate you trust the process because you trust yourself and this is where a lot of people struggle they want to control every detail they want to force reality to conform to their idea of how things should be but in Creator mode you don't force you flow you set your intention you take inspired action and then you let go you trust that the universe is handling the how while you focus
      on the why and the why my friend is everything why do you want to create why do you want to manifest your desires is it for personal gain is it to prove something to others or is it because you feel a deep calling to create something bigger than Is it to prove something to others yourself here's the truth the more a your Creations are with the greater good the more power you will have the universe is not interested in ego-driven Pursuits it doesn't respond to selfish desires but when you create from a place of love from a place of genuine service to others the universe will move mountains to support you because ultimately we're all part of the 
      same fabric of existence when you lift others up you lift yourself up when you contribute to the collective the collective contributes to you now let's dive deeper what does it actually feel like to be in Creator mode it feels like a state of flow where time seems to disappear where you're so immersed in the Act of Creation that everything else Fades away it feels like being fully present Fully Alive fully connected to the moment it feels like a deep sense of Peace even amidst the chaos of life because when you're in Creator mode you're not reacting to the external world you're shaping it from Youre still human within but here's the thing you're
      still human you're going to have moments of Doubt moments where you question yourself moments where you feel like you've lost your way that's part of the journey creator mode doesn't mean you're immune to challenges it means you're equipped to handle them in a whole new way it means you have the tools to navigate the ups and downs of life with Grace and wisdom it means you're no longer at the mercy of your circumstances because you've learned how to shape your circumstances from the inside out so here's my question to you are you willing to let go of your old identity the one that's been conditioned to believe in limitations and step fully into your 
      power as a Creator are you ready to embrace the unknown to trust the process to co-create with the universe in ways you've never imagined because if you're seeing this it's because they believe you're ready the only question left is do Do you now you now that you've entered Creator mode the next step is learning how to connect with the silent forces guiding you these forces are always present always working behind the scenes but most people are too distracted to notice them you've probably felt their presence before those moments of intuition of knowing without knowing a feeling like something or someone is guiding you these aren't random they're 
      The Whispers of the universe the subtle nudges that are constantly pointing you in the right direction [Music] but here's the thing the universe doesn't shout it doesn't Force its way into your Consciousness it speaks softly in the spaces between your thoughts in The Quiet Moments when you're fully present and this is where most people miss the message they're so caught up in the noise of their own minds in the chaos of their daily lives that they drown out the very guidance they're seeking so how do you tune in how do you hear The Whispers of the universe amidst the noise it starts with Stillness in Creator mode you must cultivate a practice of 
      Stillness whether it's through meditation mindful breathing or simply taking a few moments each day to be fully present when you quiet the mind you create space for the universe to speak and when you listen closely you'll start to notice the signs the synchronicity the subtle messages that are always there guiding you toward your Highest [Music] Trust Potential but here's the key you must trust what you hear too often people receive these messages but dismiss them as mere coincidences or random thoughts they doubt their intuition second guess their inner knowing but in Creator mode doubt is your biggest enemy you must learn to trust yourself to 
      trust the guidance you receive even when it doesn't make logical sense because the universe doesn't operate according to human logic it operates according to a higher intelligence one that sees the full picture now let's get practical how do you start recognizing these signs how do you differentiate between a random thought and genuine guidance it comes down to feeling when you receive true guidance from the universe it comes with a sense of calm of certainty of Peace it feels light expansive and aligned on the other hand when a thought comes from Fear doubt or ego it feels heavy constrictive and full of tension pay attention to how your body feels 
      when you receive a message your body is a powerful tool for discernment [Music] Fear but here's where it gets tricky sometimes the guidance you receive will push you out of your comfort zone it will ask you to do things that scare you things that challenge you things that require you to let go of control and this is where many people turn back they feel the fear and they assume that it's a sign to stop but in Creator mode fear is not a stop sign it's a green light it's a signal that you're about to step into something bigger than yourself something that will stretch you grow you and ultimately Elevate you so here's my next deep question for 
      you are you willing to trust the guidance you receive even when it scares you even when it doesn't make sense to your rational mind even when it requires you to let go of everything you thought you knew because this is the true test of Creator mode it's not about creating a a comfortable life it's about creating a life that's in alignment with your Highest Potential and sometimes that means walking a path that others can't see or [Music] The beauty of it understand but here's the beauty of it when you start following this guidance when you start trusting the silent forces that are always guiding you life becomes magical synchronicities become 
      the norm doors open where there were once walls opportunities appear out of nowhere and the more you trust the more the universe responds it's like a dance you take a step the universe takes a step you make a move the Universe moves with you but remember this is not a passive process in Creator mode you're an active participant in your own Evolution you're not sitting back waiting for the universe to hand you what you want want you're co-creating with it every step of the way you're listening trusting and taking inspired action and the more you do this the more you'll start to see the power you The role of faith hold now let's talk about the 
      role of faith in all of this faith is not just a religious concept it's a fundamental principle of creation faith is the belief in things unseen the the knowing that even when you can't see the full picture everything is unfolding exactly as it should in Creator mode faith is your anchor it's what keeps you grounded when things don't go according to plan when the path seems unclear when doubt Creeps in without faith you'll constantly be second guessing yourself questioning whether you're on the right track but with faith you move through life with a sense of peace and certainty knowing that the universe is always supporting you so how do you 
      cultivate faith it starts with small steps every time you trust your intuition every time you follow the guidance you receive you build your faith muscle and over time as you see the results of your trust your faith grows stronger you start to realize that the universe is always working in your favor even when it doesn't seem like it you start to see the bigger picture the intricate web of synchronicities that are constantly unfolding behind the [Music] The role of challenges scenes but here's the thing Faith doesn't mean you'll never face challenges in fact the challenges are part of the process they're designed to help you grow to help you evolve 
      to help you step into your full power as a Creator so the next time you face a CH challenge instead of seeing it as a roadblock ask yourself what is this challenge here to teach me how can I use this to become stronger wiser more aligned with my true self now that you've entered Creator mode and learn to connect with the silent forces guiding you the next step is mastering the art of manifestation manifestation is not just about thinking positive thoughts and hoping for the best it's a science a process a deliberate Act of Creation and when you're in Creator mode you have the power to manifest anything you desire if you know how to do it 
      Thoughts are energy consciously the first thing you need to understand about manifestation is that thoughts are energy every thought you think sends out a vibration into the universe and that vibration attracts experiences people and circumstances that match it frequency this is the basic principle of the Law of Attraction but here's where most people get it wrong they think that just by thinking positive thoughts they'll automatically attract positive outcomes but it's not that simple in Creator mode it's not just your thoughts that matter it's your beliefs your emotions and your actions you can think positive thoughts all day long 
      but if deep down you don't believe that what you want is possible or if you're constantly feeling fear doubt or lack you'll continue to attract experiences that match those lower vibrations this is why so many people struggle with manifestation they're focusing on the surface level without addressing the deeper energetic patterns that are running the How to manifest [Music] show so how do you manifest consciously it starts with Clarity you need to be crystal clear about what you want to create vague desires produce vague results the universe responds to specificity to intention to focus so ask yourself what do 
      I truly want not what Society tells you to want not what your ego thinks you should want but what your soul truly desires get clear on that and then align your thoughts beliefs emotions and actions with that desire the next step is to feel as if your desire has already manifested this is where most people get tripped up they think that they need to wait for their desire to show up before they can feel happy grateful or excited but in Creator mode you understand that feeling comes first you must generate the emotions of having already received what you want before it actually shows up in your reality this is the secret to manifestation when 
      you feel as if your desire is already here you're emitting the vibration that attracts it to Selfawareness you but here's the catch you can't fake it you can't just pretend to feel happy or grateful while secretly doubting that your desire will ever come the universe doesn't respond to what you say it responds to what you truly believe and feel so if you're struggling to generate those feelings it's a sign that there's some deeper inner work to be done you may need to identify and release old limiting beliefs fears or doubts that are blocking you from fully stepping into the energy of your desire this is where self-awareness comes in
      Creator mode you must become hyper aware of your inner world you must constantly monitor your thoughts emotions and beliefs and ask yourself is this in alignment with what I want to create if not you need to make a conscious shift this might mean reframing a negative thought releasing a limiting belief or simply choosing to feel gratitude in the moment even if things aren't yet Action Manifestation perfect and here's the final piece action manifestation is not just a Al process it's a physical one you must take inspired action toward your desires this doesn't mean forcing things to happen or hustling non-stop it means tuning into your intuition 
      listening to the guidance of the universe and acting on it when you take action from a place of alignment things start to move quickly doors open opportunities appear and the universe conspires to bring you exactly what you need but remember there's no rush in Creator mode you understand that everything happens in Divine timing you don't need to force or push you simply need to trust align and take inspired action and when you do this consistently you'll start to see the results of your efforts you'll start to see that you truly are the creator of your own reality so here's my final deep question for you what are you ready to create what life are 
      you ready to manifest now that you've entered Creator mode because the universe is listening the only thing left is for you to decide"`,
      themeTemplate: `None`
    },
    {
      id: 7,
      title: "Countries as Mafia's",
      name: "AI Draws Countries as Mafia's",
      description: "Experience the dark allure of international mafia bosses, with AI-crafted portraits representing countries like Italy, Japan, Russia, Mexico, and more. Each boss captures the essence of their homeland, paired with gripping narratives and vivid imagery.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732372464/generated_test_images/ahibqtejzfllbn28vihm.png",
      previewVideo: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1734499176/Preview_Videos/kbj6jt1fwxjtuyuumxos.mp4",
      height: 1280,
      width: 720,
      style: 'CINEMATIC',
      styleUUID: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
      music: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1734118640/music/ozl7ichptda4gepp7dk9.mp3",
      musicDuration: 30.099563,
      storyPrompt: `None`,
      credits: 1,
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      themeTemplate: `Create a random list of 10 random counties and then generate 10 text prompt for Leonardo which shows high quality person portrait with a intense expression till the belly which represents that each country as a mafia boss.
      IMPORTANT: Strictly format the response as [{ "prompt": "..." },{ "prompt": "..." },...] without any extra text or numbering and DO NOT CHANGE THE STRUCTURE OF THE RESPONSE.`
    },
    {
      id: 8,
      title: "Animals X Superheros",
      name: "Animals X Superheros",
      description: "Unleash the power of imagination by pairing Marvel and DC superheroes with animals. Generate stunning visuals of superheroes alongside their animal counterparts and fused hybrids, blending their iconic costumes with the essence of the wild.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732371700/generated_test_images/mbcxrgyop2nyml9yesbi.webp",
      previewVideo: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1734498629/Preview_Videos/hpcd1cohzqbyfyzcgpua.mp4",
      height: 1280,
      width: 720,
      style: 'CINEMATIC',
      styleUUID: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
      music: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1732535982/music/zf6mnkityoivhaxrqivd.mp3",
      musicDuration: 45.0948,
      storyPrompt: `None`,
      credits: 1,
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      themeTemplate: `Create a random list of 7 super heros  only from marvel and Dc universe and 7 animals  and pair them like this superhero + animal and then create a two text prompt for each pair first prompt to generate superhero and animal standing together and 2nd prompt the animal combined and fusioned with that superhero such that the body is of that animal and the costume is of that superhero.
      Make sure that the first prompt of each pair is highly detailed and clear such that it should not make the animal wear any costume and the animal is raw, keep the text prompt in under 100 words also add keywords like highly detailed, HD, 8K, Clear Face and etc.
      Format the response as an array of 2 arrays, the first one has one video story for 30-45 seconds narration and second array has 14 objects each having 'prompt' as key containing the respective image prompt.

      IMPORTANT: Strictly format the response as [{ "prompt": "..." },{ "prompt": "..." },...] without any extra text or numbering and DO NOT CHANGE THE STRUCTURE OF THE RESPONSE.`
    },
    {
      id: 9,
      title: "A Man Did EVERYTHING",
      name: "A Man Did EVERYTHING",
      description: "Craft heartwarming stories of self-discovery and connection, inspired by life's simple moments. Generate stunning visual scenes and narratives that explore themes of mindfulness, peace, and spiritual awakening.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733077292/Template%20Card%20Images/kadzwndmgg3omvvgylhi.png",
      previewVideo: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1734499786/Preview_Videos/chmsj6msdetuze64wm4i.mp4",
      height: 1280,
      width: 720,
      style: 'ILLUSTRATION',
      styleUUID: '645e4195-f63d-4715-a3f2-3fb1e6eb8c70',
      music: "None",
      musicDuration: "None",
      credits: 1,
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      storyPrompt: `Create a Emotional and Sad Story of Brother and Sister under 150 words that shows the strong bond between them.`,
      themeTemplate: `None`,
      narratorId: `pNInz6obpgDQGcFmaJgB`,
    },
    {
      id: 10,
      title: "Power of words Motivation",
      name: "Power of words",
      description: "Witness how the magic of words can inspire, heal, and unite. Follow a captivating journey where characters learn the profound impact of their speech in a tale of transformation and resilience.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733079093/Template%20Card%20Images/lhzomdzhfje5gf3jlnkw.png",
      previewVideo: "https://res.cloudinary.com/dj3qabx11/video/upload/v1734438714/final-videos/final-video-1734438707067.mp4",
      height: 1280,
      width: 720,
      style: 'RENDER_3D',
      styleUUID: 'debdf72a-91a4-467b-bf61-cc02bdeb69c6',
      music: "None",
      musicDuration: "None",
      credits: 1,
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      storyPrompt: `I want you to write a short inspirational 600 character story about a boy based on an analogy. The story should be straightforward and anecdotal with a conversational style. The sentences need to be short and direct, contributing to the story's easy-to-follow narrative. 
      Use boring words to write the story. Don't use hyperbolic language. 
      It's told in a matter-of-fact way, which makes the twist more surprising. Use dialogue effectively to reveal character motivations and to advance the plot. 
      The story should carry a philosophical message or moral. Ensure this is clearly conveyed, but allow the reader to uncover it themselves through the narrative and twist. 
      Always follow this framework:
      1. Scenario
      2. Buildup
      3. Clever plot
      4. Deep short conclusion
      Rules:
      1. The story should be 600 characters long. Never exceed this length. 
      2. Make sure the story (scenario, buildup, twist and conclusion) make sense and work well together. 
      3. The story needs to have a clever unexpected moral. 
      4. Make the story flowing. 
      5. Use an analogy.`,
      themeTemplate: `None`,
      narratorId: `pNInz6obpgDQGcFmaJgB`,
    },
    {
      id: 11,
      title: "The cutest little animals",
      name: "The cutest little animals",
      description: "Delight in an enchanting collection of the tiniest, most adorable animals as they navigate mini adventures, spreading joy and wonder with their irresistible charm.",
      type: "Custom",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1733077613/Template%20Card%20Images/ht1r80h4kklncyzxfj9r.png",
      previewVideo: "https://res.cloudinary.com/dj3qabx11/video/upload/v1734407540/final-videos/final-video-1734407540219.mp4",
      height: 1280,
      width: 720,
      style: 'RENDER_3D',
      styleUUID: 'debdf72a-91a4-467b-bf61-cc02bdeb69c6',
      music: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1733078693/music/krvmg1adfbzawirecdgp.mp3",
      musicDuration: 19.099563,
      storyPrompt: `None`,
      credits: 1,
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      themeTemplate: `Use this list of animal babies {white fluffy lamb,rooster, kitten, cow calf, white fluffy bird, wolf cub, white tiger cub, donkey foal, puppy } and create this same prompt for each animal babies from the list. Here is the prompt: a lush, highly detailed 3D render of a close-up, extreme macro shot of a tiny, {animal baby name here} sitting delicately on the tip of a human's finger, its soft, and endearing facial features meticulously modeled, with a joyful, excited expression, as if exploring its new perch, 
      illuminated by a dramatic ring light that accentuates the subtle textures and, set against a blurred, out-of-focus background in nature, with a vibrant, exaggerated color palette reminiscent of Pixar, rendered using state-of-the-art engines like Unreal Engine, Octane Engine, or Vray, to create a stunning, dreamlike atmosphere.

      IMPORTANT: Strictly format the response as [{ "prompt": "..." },{ "prompt": "..." },...] without any extra text or numbering and DO NOT CHANGE THE STRUCTURE OF THE RESPONSE.`
    },
    //P1bg08DkjqiVEzOn76yG
    {
      id: 12,
      title: "King Vikramaditya",
      name: "King Vikramaditya",
      description: "Dive into the captivating story of King Vikramaditya as he faces off against a towering, mystical figures. This short delivers suspense, drama, and emotional depth, weaving vivid descriptions, engaging dialogue, and a thrilling climax into an unforgettable narrative.",
      type: "Hindi Narration",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1735466717/Template%20Card%20Images/slj2tgr08lmn6vzejzul.png",
      previewVideo: " https://res.cloudinary.com/dj3qabx11/video/upload/v1735472282/final-videos/final-video-1735472278417.mp4",
      height: 1280,
      width: 720,
      style: 'CINEMATIC',
      styleUUID: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
      music: "None",
      musicDuration: "None",
      credits: 1,
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      storyPrompt: `I need you to write a story that reflects the theme or key points of the narrative. The story should be approximately 140 words and should follow the structured outline of having a strong beginning, middle, and end, leading to a powerful climax and satisfying resolution. 
      Ensure that the story flows naturally through these sections and is engaging, with emotional highs and lows. Follow the style and tone mentioned earlier, whether its suspenseful, dramatic, comedic, or another tone, and keep it consistent throughout. Act as a creative storyteller, providing detailed descriptions, dialogue, and character development. 
      The storytelling should be vivid and emotionally engaging, guiding the narrative through a well-paced arc that keeps the audience hooked from start to finish. Language Option: Please write the story in Hindi. King: King Vikramaditya A muscular royal man, wearing traditional Indian attire with an orange turban, tattoos covering his muscular chest and arms, a thick beard, and a serious, determined expression. Mysterious man A towering mystical figure with long dreadlocks, a flowing beard, sacred face markings, adorned with spiritual trinkets, and wearing a red turban.
      Write the story **only in Hindi using Hindi alphabets**, and return only the story without any additional text or explanations.`,
      themeTemplate: `None`,
      narratorId: `pNInz6obpgDQGcFmaJgB`,
    },
    {
      id: 13,
      title: "Space exploration",
      name: "Space exploration",
      description: "A space exploration story where astronauts journey into deep space, their fate in the face of isolation, unknown forces, and the vast emptiness of the cosmos.",
      type: "Motion Video",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1736019286/Template%20Card%20Images/sywrfdfcdf4uw4m3hs8z.png",
      previewVideo: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1736018526/Preview_Videos/s6iyanhlawyyomxclmae.mp4",
      height: 1280,
      width: 720,
      style: 'CINEMATIC',
      styleUUID: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
      music: "None",
      musicDuration: "None",
      credits: 3,
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      videoPrompts: [
        { "prompt": "A slow zoom-out shot starting from Earth, revealing the star-filled expanse of the Milky Way. Subtle auroras shimmer over Earth's curvature, highlighting humanity's home beneath the stars. Text overlay: 'Beneath the infinite canopy of stars...'" },
        { "prompt": "A montage of historical space milestones: Sputnik launching, Apollo 11's Saturn V rocket, Neil Armstrong stepping onto the Moon, and mission control celebrating. Text overlay: 'From the first hesitant steps to transforming the impossible into reality.'" },
        { "prompt": "Shots of Mars rovers like Perseverance navigating red dunes, and the James Webb Space Telescope capturing vivid nebulae. Robotic arms collecting samples from asteroids. Text overlay: 'Our robotic explorers roam distant planets...'" },
        { "prompt": "A dynamic journey through space: zooming out from Earth, past the Solar System, and into distant galaxies glowing faintly in the dark. A supernova explodes in vivid color. Text overlay: 'Telescopes peer across time itself...'" },
        { "prompt": "A view of Earth from space, highlighting vibrant blue oceans and green continents. Contrast this with clips of environmental challenges like melting ice caps. Text overlay: 'Yet, as we gaze outward, we learn about Earth.'" },
        { "prompt": "A spacecraft descending on Mars, dust clouds rising as it lands. Astronauts in modern space suits set up habitats and study the terrain. A flag is planted on Martian soil. Text overlay: 'Mars looms as our next frontier...'" },
        { "prompt": "International teams collaborating in a futuristic Moon base, advanced spacecraft departing for Mars, and concepts of exoplanet exploration with humans aboard interstellar ships. Text overlay: 'Space beckons, and humanity answers.'" },
        { "prompt": "A silhouette of an astronaut gazing out at Earth from the Moon, with a united Earth glowing softly in the background. Fade into a bright starry sky. Text overlay: 'United in our quest to reach beyond the stars.'" }
      ],
      themeTemplate: `None`,
      narratorId: `pNInz6obpgDQGcFmaJgB`,
    },
    {
      id: 14,
      title: "Ramayana",
      name: "Ramayana Trailer",
      description: "Generate a cinematic trailer of the Ramayana with this AI-powered template. Bring the epic tale to life with stunning visuals and dramatic effects in minutes!",
      type: "Motion Video",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1736020079/Template%20Card%20Images/olrikgaczoc8funammzt.png",
      previewVideo: "https://res.cloudinary.com/dgwhzfqvm/video/upload/v1736507463/preview_Videos/lzxym4jxqcxrgviebbch.mp4",
      height: 832,
      width: 1472,
      style: 'CINEMATIC',
      styleUUID: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
      music: "None",
      musicDuration: "None",
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      credits: 5,
      imgPrompts: [
        { "prompt": "An expansive fantasy kingdom nestled in a lush mountain valley, surrounded by towering snow-capped peaks. The kingdom features a grand, ornate temple-like palace perched on a hill, with intricate golden spires and traditional architectural details. Below the palace, a sprawling village with smaller stone buildings stretches into the valley, connected by winding pathways. The scene is immersed in morning mist and soft clouds, creating a mystical atmosphere. The environment is rich with vibrant greenery and cascading waterfalls flowing from the hillsides. The lighting is bright and natural, highlighting the dramatic contrast between the verdant valley and the majestic, snowy mountains in the background with HQ, 8k, high-quality render." },
        { "prompt": "A breathtaking, cinematic view of a grand, expansive fantasy ancient temple complex framed by an intricately carved ceremonial gate, adorned with ornate patterns, golden embellishments, and weathered wooden textures. The gate features two imposing stone guardian lion statues with a fierce, regal posture, symbolizing protection. Beyond the gate lies a wide, symmetrical cobblestone pathway bathed in warm, golden sunlight, leading directly to a magnificent temple. The temple rises majestically with tiered architecture, crowned by a glowing golden spire that gleams against the sunlight. Surrounding the pathway are perfectly manicured gardens with vibrant greenery, colorful flowers, and smaller, finely detailed shrines on both sides with HQ, 8k, high-quality render." },
        { "prompt": "A close-up of two hands holding each other, one male and one female, set in an outdoor environment. The female hand is adorned with intricate gold bangles, a delicate gold ring, and traditional mehndi (henna) designs, while wearing a vibrant red saree with gold embroidery. The male hand is dressed in a simple yet elegant saffron attire, with minimal accessories. The hands are gently clasped together, symbolizing love and connection. The background is softly blurred, showcasing lush green trees and the golden glow of sunlight filtering through, adding a warm and romantic ambiance. High detail, realistic textures, warm lighting, and natural tones are emphasized with HQ, 8K, high-quality render." },
        { "prompt": "A beautifully illustrated magical forest at twilight, rendered in a whimsical and painterly art style. The scene features a deer with elegant antlers, its fur glowing softly with golden, sparkly particles, as if it is made of stardust. The deer stands gracefully in the center, its wide, innocent eyes reflecting the enchanting surroundings. The background consists of tall, shadowy trees with a warm, golden light filtering through the foliage, casting soft, diffused shadows. The air is filled with glowing, golden fireflies and floating magical orbs, adding depth and a fairy-tale ambiance. The style emphasizes vibrant golden tones, soft gradients, and intricate glowing details, evoking a serene, otherworldly, and dreamlike atmosphere with HQ, 8k, high-quality render." },
        { "prompt": "A magical forest at twilight, glowing with golden and ethereal light. At the center is an enchanting young deer with small antlers, its body shimmering and sparkling with golden particles, as if infused with stardust. The deer appears to be both running and walking gracefully in the air above the forest, its movements fluid and surreal, as if gliding effortlessly. Its hooves leave faint glowing trails of light with each step. The forest background features tall, shadowy trees, their trunks illuminated softly by warm ambient light filtering through. The air is filled with glowing golden fireflies or magical particles that float serenely, creating a dreamlike atmosphere. The scene captures a mystical, fairy-tale-like charm, emphasizing warm golden hues, soft shadows, and a sense of wonder and tranquility, rendered in high-quality 8K resolution with intricate details." },
        { "prompt": "A hyper-realistic, cinematic close-up of a colossal eagle mid-flight, its vast wingspan stretching across the frame. Its piercing golden eyes lock onto the camera with an intense, kingly presence, while fur-like feathers ripple in the dawn light, blending dark blue-black, slate grey, and golden highlights. Each feather is intricately detailed, exuding power and majesty. The eagle soars over misty, golden-lit mountains and shadowed evergreen forests, its wings rimmed with radiant light. Volumetric lighting enhances the epic scale, while subtle VFX adds dynamic motion to the feathers and air. Captured in 4K HDR, this scene radiates the raw, mythical power of the King of the Skies." },
        { "prompt": "Two legendary warriors, Ram and Lakshman, stand on the edge of a steep, rocky cliff, overlooking an expansive, lush green forest that stretches to the horizon. The forest below is dense and vibrant, blanketed in patches of mist that give the landscape a mystical, otherworldly feel. The sky above is hazy and foggy, with diffused sunlight filtering through, casting a soft, serene glow over the scene. The two warriors are dressed in traditional saffron attire glowing faintly in the soft, otherworldly light, their clothing flowing gently in the breeze. They stand side by side with their backs to the viewer, exuding calm strength and quiet reverence as they gaze out at the breathtaking view. Their bows and quivers are slung across their shoulders, a subtle reminder of their role as protectors. The rugged cliff, dotted with patches of greenery, frames the scene, creating a cinematic composition that blends the raw beauty of the wilderness with the timeless aura of myth and legend with HQ, 8k, high-quality render." },
        { "prompt": "Two warriors, Ram and Lakshman from the epic Ramayana, stand at the entrance of a cave, dressed in traditional saffron attire. They are equipped with bows and quivers full of arrows, signifying their roles as noble warriors. The cave's rugged stone walls form a natural frame around the serene and mystical scene. Outside the cave, a magnificent cherry blossom tree with soft pink flowers is illuminated by a radiant, ethereal light. The atmosphere is tranquil yet majestic, with a gentle mist adding a dreamlike quality to the environment. The warriors, with a calm yet resolute demeanor, gaze outward as if preparing for their journey. The interplay of light and shadow enhances the sacred and timeless feel of the setting, blending the grandeur of nature with the divinity of the characters with HQ, 8k, high-quality render." },
        { "prompt": "A large-scale battle scene between humans and apes, captured from a high, over-the-top cinematic perspective. The chaotic flurry of arrows dominates the foreground, their sharp points and varied trajectories creating a dynamic sense of movement and intensity. The battlefield below sprawls with clashing humans and apes, their formations scattered and desperate. In the background, a ruined city and jagged mountains loom, emphasizing the destruction and scale of the conflict. Smoke and dust rise from the battlefield, adding depth and drama to the scene. The muted, desaturated color scheme enhances the grimness and despair of the battle, while the elevated view highlights the vastness and epic scale of the confrontation. Rendered in intricate detail, the scene captures a sweeping, cinematic sense of chaos and tragedy." },
        { "prompt": "Create a hyper-realistic close-up image of an ape's face, showing only its face, filling the entire frame. The ape is looking directly into the camera with an intense, angry expression. The visible features include piercing, sharp eyes with a fiery, focused gaze; a prominent, wrinkled brow furrowed in anger; and textured fur that is thick, dark brown, and slightly messy around the visible side. The skin around the eyes and mouth is rough and detailed, showcasing every pore and crease. A bold, vivid red tilak is painted on the apes forehead, its sharp edges contrasting against the rugged texture of its fur and skin. The lighting is dramatic, emphasizing the contours of its face, with deep shadows enhancing the sense of intensity and mystery. The background is dark and blurred to further highlight the apes striking facial features." },
        { "prompt": "A large group of energetic monkeys running towards the camera in golden afternoon sunlight, kicking up dust clouds, backlit scene creating silhouettes and rim lighting, brown and golden color palette, motion blur in background, dynamic action shot, low angle perspective, multiple monkeys in different running poses, determined expressions with bared teeth, muscular primates, natural dirt path, dramatic lighting with sun rays filtering through dust particles, cinematic composition, photorealistic quality, warm earthy tones, sharp foreground detail with atmospheric depth, Cinematic style, shallow depth of field, epic scale, 4K detailed texture." },
        { "prompt": "An enormous, enraged ape with a thick, untamed mane of coarse brown fur, its fur bristling as if charged with energy, wearing a golden ornate crown embedded with gleaming jewels. The gorilla roars ferociously, baring sharp teeth that radiate primal rage. Its massive, muscular frame is wrapped in fur-lined, spiked armor, with jagged edges designed for intimidation. The scene is set on a dusty battlefield, debris particles flying through the air, as the gorilla lunges forward in a dynamic action pose, exuding raw power and menace. Dramatic lighting highlights every strand of its fur and the intricate textures of its armor and crown. In the background, more savage gorillas charge forward with wild ferocity. The atmosphere is cinematic, with a moody, dust-filled environment, motion blur accentuating the chaos. The hyper-detailed texturing on the gorilla's fur emphasizes its untamed and dangerous nature, and the slightly below eye-level camera angle heightens the beast's imposing dominance. The photorealistic rendering, muted desaturated color scheme, and 8K resolution complete the epic and terrifying scene." },
        { "prompt": "A large army of energetic muscular apes running towards the camera giving a battle cry in golden afternoon sunlight, kicking up dust clouds with arrows flying over them in motion blur in the battlefield, backlit scene creating silhouettes and rim lighting, brown and golden color palette, motion blur in background, dynamic action shot, low angle perspective, multiple apes in different running poses, determined expressions with bared teeth, muscular primates, natural dirt path, dramatic lighting with sun rays filtering through dust particles, cinematic composition, photorealistic quality, warm earthy tones, sharp foreground detail with atmospheric depth, Cinematic style, shallow depth of field, epic scale, 4K detailed texture." },
        { "prompt": "A cinematic shot from behind the massive leg of a giant ape, its thick, muscular limb covered in coarse brown hair, as it slams onto the ground with devastating force. The ground beneath the foot cracks and splinters outward, sending dust, debris, and shards of earth flying into the air. The ape's leg is adorned with ornate golden anklets engraved with intricate tribal patterns, some with dangling charms that sway with the motion. The perspective emphasizes the towering scale of the ape, with its colossal leg in the foreground and an epic battlefield stretching out in front of it. Warriors clad in armor clash with one another, wielding swords, spears, and shields, while war elephants and chariots charge through the chaos. The battlefield is shrouded in dust and smoke, lit by a fiery, blood-red sky at dusk. Volumetric lighting highlights the apes leg and the cracks in the ground, while the distant battle is cast in soft, dramatic light. The scene captures an overwhelming sense of primal power and destruction, with the giant ape looming over the chaotic, war-torn landscape like an unstoppable force. Rendered in epic cinematic style with intricate details, dynamic motion, and a mythical tone." },
        { "prompt": "The mighty Monkey King in saffron, sits on a rocky outcrop, his powerful physique and brown fur glowing with a golden aura viewed from far behind him (over the top camera view) as he gazes upon a vast ancient city burning below, his long majestic brown fur tail is visible, curving elegantly behind him as it rests on the rock. The city is in chaos, massive flames consuming ornate temples and pagodas, with embers and sparks floating up into the night sky. The scene is bathed in dark orange and amber tones, creating an apocalyptic atmosphere. His silhouette is set against the fiery landscape. The flames and smoke swirl around the city in a dramatic, cinematic composition, with cinders and sparks filling the air. The painting is highly detailed, capturing every flicker of fire and the strength of Hanuman in this dark fantasy art style. The scene conveys the weight of destruction, the sacrifice of a god, and the unyielding spirit of the warrior with HQ, 4K, high-quality render." },
        { "prompt": "A mythic and cinematic scene of Ravana, the ten-headed evil king from the Hindu mythology emerging from a towering inferno, his ten golden crowns intricately adorned with divine carvings and glinting in the fiery light. Each of his faces is distinct, exuding an aura of menace and unparalleled power, giving an evil smile. The central visage radiates wrathful dominance, its expression twisted with an evil laugh, embodying Ravana's insatiable lust for power and vengeance. His dark brown skin contrasts vividly against the inferno, while his massive, fiery aura expands outward, consuming the heavens and earth. The flames form an almost divine halo behind him, their chaotic dance adding to his otherworldly terror. Ravana has beard and is of slightly brown complexion. At the forefront, a lone, valiant warrior clad in simple saffron robes stands firm, wielding a gleaming divine bow and arrow, with a sword hanging at his side, glowing faintly with an otherworldly light. The warrior's defiance creates a striking silhouette, dwarfed by Ravana's immense form. Behind the warrior, faint outlines of divine beings and human soldiers gather, their forms partially illuminated by the fiery glow, ready to face the overwhelming evil. On either side of Ravana, the grotesque shadows of his demon army loom, their glowing weapons adding to the apocalyptic tension with HQ, 8K, high-quality render." },
        { "prompt": "A silhouetted warrior standing atop a rocky outcrop at sunset, holding a curved bow in one hand, with a quiver of arrows visible on their back, wearing traditional Indian saffron clothing with flowing garments. Dramatic golden-orange sky with a massive sun disk centered in the background, partially obscured by wispy clouds. Sun rays creating a hazy, atmospheric effect across a vast landscape. Rich, warm color palette dominated by amber, orange, and deep golden tones. The figure is muscular and heroic in pose, shown in full length from behind, with arrows visible in a quiver on their back. Desert-like battlefield terrain stretches to the horizon with subtle environmental details barely visible through the sunset haze. Cinematic lighting with strong rim lighting from the sun creating a dramatic silhouette effect. Epic scale composition with the figure positioned perfectly against the enormous setting sun. Volumetric lighting creating depth in the atmosphere. Style: Digital art, epic fantasy, highly detailed, cinematic quality, 8k resolution." },
        { "prompt": "The word RAMAYANA written in blazing, golden-orange fire, each letter radiating intense heat and emitting sparks and embers. It hovers over a vast ancient battlefield at twilight, filled with shattered weapons, broken chariots, and scattered shields. The battlefield is shrouded in dust and smoke, with silhouettes of warriors, towering war elephants, and torn battle flags fluttering in the wind. The horizon glows with a blood-red and purple sky, with storm clouds rolling in, creating an ominous yet epic atmosphere. The fiery letters cast a warm glow across the chaotic scene, symbolizing the divine struggle of dharma against adharma. Cinematic composition, high dynamic range, volumetric lighting, intricate details, and a mythical, larger-than-life tone with 8k resolution. The scene evokes power, conflict, and fate." }
      ],
      videoPrompts: [
        { "prompt": "Cinematic zoom in of a vast ancient city, with sprawling stone buildings and distant towers rising above, bathed in the warm glow of the setting sun. The camera slowly closes in on the grand architecture, revealing intricate details of the citys construction. Dust swirls in the air, adding an epic, atmospheric quality to the shot. The ancient city is surrounded by mountains in the distance, their peaks glowing faintly with the last rays of sunlight. The scene captures the scale and beauty of the city, with high-quality 8k resolution, cinematic lighting, and intricate environmental details." },
        { "prompt": "Cinematic zoom in of a fantasy ancient temple complex through a giant royal gate, featuring ornate carvings and golden embellishments. The camera moves closer to reveal the intricate architecture, with towering spires and majestic statues flanking the entrance. The royal gates towering columns are bathed in soft, golden sunlight as the camera passes through them. The entire scene conveys a sense of grandeur and mysticism, with dramatic lighting and photorealistic details in 8k resolution." },
        { "prompt": "Slow camera motion as sun rays shine behind two joined hands, the light filtering through the gaps between the fingers, casting soft, warm rays onto the hands. The hands are adorned with traditional gold bangles and subtle mehndi designs, symbolizing unity and love. The background is blurred with the focus on the hands and the magical play of light and shadow. The atmosphere is serene and romantic, with high-quality 8k resolution and detailed textures." },
        { "prompt": "A deer in a magical forest at twilight, surrounded by glowing golden fireflies and floating magical orbs. The forest is bathed in soft, fading light, with the trees towering overhead. The deer has shimmering fur that glows faintly with golden particles, moving gracefully through the forest. The atmosphere is dreamlike and mystical, with soft, ethereal lighting and depth created by the fireflies and floating orbs. The scene captures the enchanting, fairy-tale ambiance with high-quality 8k resolution." },
        { "prompt": "A deer running in the magical forest at twilight, leaving a trail of golden fireflies and floating magical orbs in its wake. The scene is filled with soft, ambient lighting that enhances the magical atmosphere, with the forest glowing faintly in the background. The deer's movement is swift and fluid, and the orbs and fireflies follow in a shimmering trail, adding depth and a sense of wonder to the shot. The setting captures the essence of a fairy-tale with high-quality 8k resolution." },
        { "prompt": "A giant eagle flapping its massive wings in slow motion, its feathers ruffling as the wind from its powerful flight creates a dynamic effect. The eagles wings are enormous, detailed with layers of feathers, and its sharp, piercing eyes are focused ahead. The surrounding landscape is vast, with misty mountains and forests in the distance. The shot emphasizes the grandeur and majesty of the eagle, captured in 8k resolution with dramatic, cinematic lighting." },
        { "prompt": "A cinematic zoom-out of the grand forest, while two people stand at the edge of a cliff overlooking the vast expanse. The forest is lush, stretching as far as the eye can see. The sunlight filters through the trees, casting warm beams of light onto the scene. The camera slowly pulls back to reveal the breathtaking scope of the forest and the two figures standing in quiet contemplation. The composition highlights both the beauty of nature and the serenity of the moment, captured in high-quality 8k resolution." },
        { "prompt": "A cinematic zoom-in of a beautiful shot of two warriors standing side by side, with dramatic lighting casting shadows on their faces. The atmosphere is tense, with a large, ancient temple looming in the background. The warriors intricate armor glints in the sunlight, and their expressions are focused and resolute. The scene highlights their strength and courage, enhanced by cinematic lighting and high-quality 8k resolution." },
        { "prompt": "Two armies clashing, with arrows flying over the battlefield, each one trailing a faint streak of light as they soar through the air. The warriors are engaged in a fierce battle, swords clashing, shields raised, and war cries echoing across the scene. The chaotic motion of battle is captured in sharp detail, with dramatic lighting and dust swirling around the warriors. The background is filled with smoke and fire, creating an intense, epic atmosphere with high-quality 8k resolution." },
        { "prompt": "Close-up shot of an angry ape looking directly into the camera, its eyes narrowed with intensity. The apes muscles are tense, and its heavy breathing can be seen, with slight mist coming from its nostrils. The details of its fur are visible, emphasizing the raw emotion and power of the creature. The lighting is harsh, casting deep shadows across its face, adding to the menacing presence. The shot is captured in high-quality 8k resolution with cinematic detail." },
        { "prompt": "A monkey army running towards the camera in slow motion, the ground shaking with the force of their movement. The camera captures their determined expressions, with some showing bared teeth, as they charge into battle. The lighting is dramatic, with sun rays filtering through the dust, adding an epic feel to the scene. The monkeys' fur is intricately detailed, and their coordinated movements convey a sense of unity and strength. The shot is rendered in high-quality 8k resolution, capturing every detail." },
        { "prompt": "The giant ape king charging forward with his army of apes behind him, the scene filled with motion and power. The camera follows the massive ape king as he leads his troops, each of them wielding weapons and roaring in unison. The dust rises around them as they move, and the light filters through the chaos of the battlefield. The shot conveys the overwhelming force of the ape army, rendered in cinematic quality with high-quality 8k resolution." },
        { "prompt": "The ape army charging towards the camera, arrows flying over their heads as they move into battle. The camera focuses on their fierce expressions, showing the determination of each warrior as they advance. The arrows create a dynamic motion in the scene, adding intensity to the charge. The lighting emphasizes the chaos of the moment, with dust and smoke swirling around them. The shot is dramatic, filled with movement and energy, captured in high-quality 8k resolution." },
        { "prompt": "The ground breaks as the giant ape king slams his foot onto the earth, creating cracks that radiate outward. The camera focuses on the impact, showing the force and power of the creature. Dust and debris are sent flying into the air, and the surrounding environment shakes with the force of the blow. The scene is dynamic, with high-quality 8k resolution capturing every detail of the impact and the surrounding destruction." },
        { "prompt": "A cinematic zoom-out shot viewed from far behind the monkey king, as he gazes upon a vast ancient city burning below. The camera slowly pulls back to reveal the destruction, with flames and smoke rising from the city. The monkey king stands atop a rocky outcrop, his figure silhouetted against the fiery backdrop. The scene is filled with dramatic lighting, emphasizing the scale of the destruction and the kings imposing presence. The shot is captured in high-quality 8k resolution." },
        { "prompt": "A cinematic zoom-out shot of the ten-headed evil king, Ravana, as flames roar around him. The camera pulls back to reveal the full extent of his fiery aura, with each of his heads exuding a different expression of power and malice. The flames twist and turn around him, creating a dynamic and dramatic effect. The lighting highlights his menacing presence, and the background is filled with chaos and destruction. The shot is rendered in high-quality 8k resolution, emphasizing Ravanas overwhelming power." },
        { "prompt": "Camera moves from right to left slowly, as sun rays shine through the trees, creating a stunning cinematic shot. The sunlight filters through the branches, casting long shadows and creating a warm, golden glow over the scene. The camera follows the rays as they move across the landscape, illuminating patches of the forest floor and highlighting the natural beauty of the environment. The scene is serene, capturing the peaceful atmosphere of the forest with high-quality 8k resolution." },
        { "prompt": "Subtle motion as the flames around the word 'RAMAYANA' burn slowly, the letters glowing with a fierce golden hue. The flames dance around the word, casting flickering shadows and adding a sense of tension and power. The background is a dark, smoky battlefield, with embers floating in the air. The shot is dramatic, with the fiery letters standing out against the chaos of the scene. The flames and the word 'RAMAYANA' are rendered in high-quality 8k resolution, with every detail of the fire captured in cinematic style." }
      ],
      narratorId: `pNInz6obpgDQGcFmaJgB`,
    },
    {
      id: 15,
      title: "Mahabharata",
      name: "Mahabharata",
      description: "Bring the Mahabharata to life with this AI-powered trailer template. Create epic visuals and dramatic storytelling in minutes!",
      type: "Motion Video",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1736020536/Template%20Card%20Images/ptkcgvhzh2n9ya3v2slv.png",
      previewVideo: "https://res.cloudinary.com/dgwhzfqvm/video/upload/v1736507364/preview_Videos/kwff4duvlawfyvch8kot.mp4",
      height: 1280,
      width: 720,
      style: 'CINEMATIC',
      credits: 5,
      styleUUID: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
      music: "None",
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      musicDuration: "None",
      storyPrompt: ``,
      themeTemplate: `None`,
      narratorId: `pNInz6obpgDQGcFmaJgB`,
    },
    {
      id: 16,
      title: "Animated Line Graph",
      name: "Animated Line Graph",
      description: "Effortlessly create animated line graphs for your YouTube Shorts or videos. Upload a CSV file, and the template turns your data into smooth, engaging visuals ideal for storytelling or showcasing trends.",
      type: "Motion Video",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1737584152/Screenshot_2025-01-23_034317_okckgg.png",
      previewVideo: "https://res.cloudinary.com/dyzcpcibf/video/upload/v1737663014/chart-animation_jawdlw.mp4",
      height: 1280,
      width: 720,
      style: 'CINEMATIC',
      credits: 5,
      styleUUID: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
      music: "None",
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      musicDuration: "None",
      storyPrompt: ``,
      themeTemplate: `None`,
      narratorId: `pNInz6obpgDQGcFmaJgB`,
    },
    {
      id: 17,
      title: "Animated Bar Graph",
      name: "Animated Bar Graph",
      description: "Upload a CSV file, and the template will transform your data into dynamic visuals perfect for engaging storytelling or presenting stats.",
      type: "Motion Video",
      image: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1737584137/Screenshot_2025-01-23_034217_psli0o.png",
      previewVideo: "https://res.cloudinary.com/dj3qabx11/video/upload/v1737662529/chart-animations/n0vqkqkzhhwrisgdf7ls.mp4",
      height: 1280,
      width: 720,
      style: 'CINEMATIC',
      credits: 5,
      styleUUID: 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
      music: "None",
      seller: {
        id: "AutoMovieCreator",
        name: "Auto Movie Creator",
        avatar: "https://res.cloudinary.com/dyzcpcibf/image/upload/v1732686895/generated_test_images/ak5djqvgqa9jjid17kha.jpg"
      },
      musicDuration: "None",
      storyPrompt: ``,
      themeTemplate: `None`,
      narratorId: `pNInz6obpgDQGcFmaJgB`,
    },
  ];

  const [activeTab, setActiveTab] = useState('templates');
  const [templates, setTemplates] = useState([]); // This will store marketplace templates
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showCustomForm, setShowCustomForm] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showGeneratedVideo, setShowGeneratedVideo] = useState(false);
  const [generatedVideoUrl, setGeneratedVideoUrl] = useState(null);
  const [generationError, setGenerationError] = useState(null);
  // const [activeTab, setActiveTab] = useState("/templates");
  const { user, userCredits, updateCredits } = useContext(AuthContext);
  const userEmail = user?.email;
  const userUID = user?.uid;
  const navigate = useNavigate();
  const [showPreGenModal, setShowPreGenModal] = useState(false);
  const [selectedNarrator, setSelectedNarrator] = useState("pNInz6obpgDQGcFmaJgB");
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [showMyGenerations, setShowMyGenerations] = useState(false);
  const [marketplaceTemplates, setMarketplaceTemplates] = useState([]); 
  const [showGeneratingModal, setShowGeneratingModal] = useState(false);
  const [templateScenes, setTemplateScenes] = useState([]);
  const [loadingScenes, setLoadingScenes] = useState(false);
  const [editableScenes, setEditableScenes] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  // Add loading state
  const [isLoading, setIsLoading] = useState(true);

  // Fetch marketplace templates on component mount
  useEffect(() => {
    const fetchMarketplaceTemplates = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${serverbaseURL}get-marketplace-templates`);
        console.log('Marketplace templates:', response.data);
        setTemplates(response.data);
        setMarketplaceTemplates(response.data);
      } catch (error) {
        console.error("Failed to fetch marketplace templates:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMarketplaceTemplates();
  }, []);

  useEffect(() => {
    const fetchUserCredits = async () => {
      if (user?.uid) {
        try {
          const response = await axios.post(`${serverbaseURL}get_user_details`, {
            uid: user.uid
          });
          updateCredits(response.data.credits);
        } catch (error) {
          console.error("Failed to fetch user credits:", error);
        }
      }
    };

    fetchUserCredits();
  }, [user]);


  // Handle tab switching
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handlePreview = (template) => {
    setSelectedTemplate(template);
    setShowPreview(true);
  };

  const handleGenerate = async (template) => {
    if (!user) {
      setShowLoginPopup(true);
      setSelectedTemplate(template);
      return;
    }

    setSelectedTemplate(template);

    // Special handling for graph templates
    if (template.id === 16 || template.id === 17) {
      setShowCustomForm(true);
      return;
    }

    setShowPreGenModal(true);
    
    setLoadingScenes(true);
    try {
      const response = await axios.get(`${serverbaseURL}api/template-scenes/${template.id}`);
      if (response.data.success) {
        setTemplateScenes(response.data);
        if (response.data.type === 'scenes') {
          setEditableScenes(response.data.scenes);
        } else if (response.data.type === 'text') {
          setEditableScenes([{ prompt: response.data.prompt }]);
        }
      } else {
        toast.error('Failed to load template content');
      }
    } catch (error) {
      console.error('Error fetching template content:', error);
      toast.error('Failed to load template content');
      setTemplateScenes({ type: 'none' });
      setEditableScenes([]);
    } finally {
      setLoadingScenes(false);
    }
  };

  const handleFinalGenerate = async () => {
    setShowPreGenModal(false);
    setIsGenerating(true);
    toast.success(`Video Generation added to render queue successfully!`);
    setGenerationError(null);

    try {
      console.log('Selected template:', selectedTemplate);
  console.log('Selected template flowData:', selectedTemplate.flowData);
      const originalCredits = userCredits;
      const newCredits = userCredits - selectedTemplate.credits;
      updateCredits(newCredits);
      const deductionResponse = await axios.post(`${serverbaseURL}deduct_credits`, {
        uid: user.uid,
        credits: selectedTemplate.credits
      });
      await axios.post(`${serverbaseURL}add-generation-status`, { 
        uid: user.uid,
        templateId: selectedTemplate.id,
        templateName: selectedTemplate.title,
      });
      const template = selectedTemplate;
      if (template.id >= 1 && template.id <= 15) {
        // Handle template 13 (portrait mode)
        // if (template.id === 13) {
        //   const response = await axios.post(`${serverbaseURL}api/generate-prompt-videos`, {
        //     prompts: template.videoPrompts
        //   });
        //   if (!response.data || !response.data.videoUrl) {
        //     throw new Error(response.data?.error || 'No video URL received from server');
        //   }
        //   setGeneratedVideoUrl(response.data.videoUrl);
        //   setShowGeneratedVideo(true);
        //   return;
        // }
        // // Handle template 14 (landscape mode)
        // if (template.id === 14) {
        //   const response = await axios.post(`${serverbaseURL}api/generate-kling-video`, {
        //     imgPrompts: template.imgPrompts,
        //     videoPrompts: template.videoPrompts,
        //     topic: template.title,
        //     uid: userUID,
        //     email: userEmail,
        //     height: template.height,
        //     width: template.width,
        //     styleUUID: template.styleUUID,
        //     presetStyle: template.style,
        //     id: template.id
        //   });
        //   if (!response.data || !response.data.videoUrl) {
        //     throw new Error(response.data?.error || 'No video URL received from server');
        //   }
        //   setGeneratedVideoUrl(response.data.videoUrl);
        //   setShowGeneratedVideo(true);
        //   return;
        // }
        const isSpecialTemplate = [4, 6, 9, 10, 12].includes(template.id);

        if (isSpecialTemplate && !template.narratorId) {
          throw new Error('Narrator ID is required for this template');
        }
        const endpoint = isSpecialTemplate
          ? `${serverbaseURL}create_template_video_cloudinary_two`
          : `https://amc-flow.onrender.com/api/generate-from-template`;

        const payload = isSpecialTemplate
          ? {
            id: template.id,
            themeTemplate: template.themeTemplate,
            topic: template.title,
            email: userEmail,
            uid: userUID,
            height: template.height,
            width: template.width,
            music: template.music || "None",
            musicDuration: template.musicDuration || 0,
            storyPrompt: template.storyPrompt,
            style: template.style,
            styleUUID: template.styleUUID,
            narratorId: template.narratorId,
            credits: template.credits
          }
          : {
            templateId: template.id.toString(),
            topic: template.title,
            email: userEmail,
            uid: userUID,
            id: template.id,
            credits: template.credits
          };
        const response = await axios.post(endpoint, payload);
        console.log(response);
        await axios.post(`${serverbaseURL}update-generation-status`, {
          uid: user.uid,
          templateId: selectedTemplate.id,
        });
        if (!response.data || !response.data.videoUrl) {
          throw new Error(response.data?.error || 'No video URL received from server');
        }
        setGeneratedVideoUrl(response.data.videoUrl);
        setShowGeneratedVideo(true);
      }

  // Use edited scenes if available
  const scenesToUse = isEditing ? editableScenes : 
  (templateScenes.type === 'scenes' ? templateScenes.scenes : 
  [{ prompt: templateScenes.prompt }]);
  // Create a modified template
  const modifiedTemplate = {
    ...template,
    flowData: {
      ...template.flowData,
      data: {
        ...template.flowData?.data,
        nodes: template.flowData?.data.nodes.map(node => {
          if (node.data?.type === 'prompt-input') {
            const updatedScenes = editableScenes.map((scene, index) => {
              const originalScene = node.data.properties.scenes[index];
              return {
                id: originalScene?.id || index + 1,
                prompt: scene.prompt
              };
            });

            return {
              ...node,
              data: {
                ...node.data,
                properties: {
                  ...node.data.properties,
                  scenes: updatedScenes
                }
              }
            };
          } else if (node.data?.type === 'text-generation') {
            return {
              ...node,
              data: {
                ...node.data,
                properties: {
                  ...node.data.properties,
                  prompt: editableScenes[0]?.prompt || ''
                }
              }
            };
          }
          return node;
        })
      }
    }
  };
  const response = await axios.post(`${serverbaseURL}api/generate-from-template-user`, {
    templateId: modifiedTemplate.id.toString(),
    topic: modifiedTemplate.title,
    email: userEmail,
    uid: userUID,
    id: modifiedTemplate.id,
    credits: modifiedTemplate.credits,
    flowData: modifiedTemplate.flowData 
  });
      // const response = await axios.post(`${serverbaseURL}api/generate-from-template-user`, {
      //   templateId: template.id.toString(),
      //   topic: template.title,
      //   email: userEmail,
      //   uid: userUID,
      //   id: template.id,
      //   credits: template.credits,
      //   flowData: template.flowData
      // });
      // await new Promise(resolve => setTimeout(resolve, 30000));
      // const response = {
      //   data: {
      //     status: 'ready',
      //     message: 'Video generated successfully!',
      //     videoUrl: "https://res.cloudinary.com/dj3qabx11/video/upload/v1736103768/final-videos/final-video-1736103767701.mp4"
      //   } }
      console.log(response);
      await axios.post(`${serverbaseURL}update-generation-status`, {
        uid: user.uid,
        templateId: selectedTemplate.id,
      });
      if (!response.data || !response.data.videoUrl) {
        throw new Error(response.data?.error || 'No video URL received from server');
      }
      setGeneratedVideoUrl(response.data.videoUrl);
      setShowGeneratedVideo(true);
    } catch (error) {
      console.error('Operation failed:', error);
      await axios.post(`${serverbaseURL}update-generation-status`, {
        uid: user.uid,
        templateId: selectedTemplate.id,
      });
      let errorMessage = 'Failed to generate video. Please try again.';
      const originalCredits = userCredits + selectedTemplate.credits;
      if (error.response?.status === 400 && error.response?.data?.message === "Not enough credits.") {
        errorMessage = "You don't have enough credits for this operation.";
        updateCredits(originalCredits);
      } else if (error.response?.status === 404) {
        errorMessage = "User account not found. Please try logging in again.";
      } else if (error.message.includes('Narrator ID')) {
        errorMessage = 'Please select a narrator voice before generating the video.';
      } else if (error.message.includes('audio')) {
        errorMessage = 'Failed to generate audio. Please try a different narrator voice.';
      }
      setGenerationError(errorMessage);
      if (error.response?.status !== 400) { 
        try {
          const userDetailsResponse = await axios.post(`${serverbaseURL}get_user_details`, {
            uid: user.uid
          });
          if (userDetailsResponse.data.credits < originalCredits) { 
            await axios.post(`${serverbaseURL}refund_credits`, {
              uid: user.uid,
              credits: selectedTemplate.credits
            });
            const finalCreditsResponse = await axios.post(`${serverbaseURL}get_user_details`, {
              uid: user.uid
            });
            updateCredits(finalCreditsResponse.data.credits);
          } else {
            updateCredits(originalCredits);
          }
        } catch (refundError) {
          console.error('Failed to handle credits:', refundError);
          updateCredits(originalCredits);
        }
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const handleCustomFormSubmit = async (formData) => {
    setShowCustomForm(false);
    setIsGenerating(true);

    try {
      const formDataObj = new FormData();
      formDataObj.append('csvFile', formData.file);
      formDataObj.append('title', formData.title);
      formDataObj.append('description', formData.description);

      const endpoint = selectedTemplate.id === 16 
        ? `${serverbaseURL}api/generate-video-line-graph`
        : `${serverbaseURL}api/generate-video-bar-graph`;

      const response = await axios.post(endpoint, formDataObj, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        setGeneratedVideoUrl(response.data.videoUrl);
        setShowGeneratedVideo(true);
        toast.success('Video generated successfully!');
      } else {
        throw new Error(response.data.error || 'Failed to generate video');
      }
    } catch (error) {
      console.error('Generation failed:', error);
      toast.error(error.message || 'Failed to generate video');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleRedirectToLogin = () => {
    setShowLoginPopup(false);
    navigate("/Login", { state: { from: { pathname: "/Templates" } } });
  };

  const handleSellerClick = async (seller) => {
    if (selectedSeller?.id === seller.id) {
      setSelectedSeller(null);
      // Reset marketplace templates to initial state
      setMarketplaceTemplates([]); // Clear first to avoid duplicates
      try {
        const response = await axios.get(`${serverbaseURL}get-marketplace-templates`);
        setMarketplaceTemplates(response.data);
      } catch (error) {
        console.error("Failed to fetch marketplace templates:", error);
      }
    } else {
      setSelectedSeller(seller);
      setMarketplaceTemplates([]); // Clear existing templates first
      try {
        const response = await axios.get(`${serverbaseURL}get-seller-templates/${seller.id}`);
        setMarketplaceTemplates(response.data);
      } catch (error) {
        console.error("Failed to fetch seller templates:", error);
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  // Modify to only use marketplace templates
  const allTemplates = [
    ...marketplaceTemplates.map(template => ({
      id: template.templateId,
      title: template.title,
      name: template.title,
      description: template.description,
      type: "Custom",
      image: template.thumbnailUrl,
      previewVideo: template.videoPreview,
      height: 1280,
      width: 720,
      style: template.flowData?.style || 'CINEMATIC',
      styleUUID: template.flowData?.styleUUID || 'a5632c7c-ddbb-4e2f-ba34-8456ab3ac436',
      credits: template.credits,
      seller: {
        id: template.sellerId,
        name: template.creatorName,
        avatar: template.brandlogo || "https://res.cloudinary.com/dyzcpcibf/image/upload/v1728106347/fh6grwe9ds9odcxzm1e1.png"
      },
      music: "None",
      musicDuration: "None",
      storyPrompt: template.flowData?.storyPrompt || "",
      themeTemplate: template.flowData?.themeTemplate || "None",
      flowData: template.flowData 
    })),
    // Add hardcoded graph templates if no seller is selected
    ...(selectedSeller ? [] : hardcodedTemplates.filter(t => t.id >= 16))
  ];

  // Update filteredTemplates to use allTemplates
  const filteredTemplates = selectedSeller
    ? allTemplates.filter(template => template.seller.id === selectedSeller.id)
    : allTemplates;

  const handleShowMyGenerations = () => {
    setShowMyGenerations(true);
  };

  // Add function to handle scene updates
  const handleSceneUpdate = (index, newPrompt) => {
    const updatedScenes = [...editableScenes];
    updatedScenes[index] = { ...updatedScenes[index], prompt: newPrompt };
    setEditableScenes(updatedScenes);
  };

  return (
    <div className="p-8">
      {/* Credits Display - Only shown when user is logged in */}
      {/* {user && (
        <div className="fixed top-20 right-4 flex items-center gap-2 bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white rounded-full px-6 py-3 shadow-lg transition-all duration-300 hover:shadow-xl" style={{ zIndex: 9999 }}>
          <Wallet className="h-5 w-5" />
          <span className="font-semibold">{userCredits} Credits</span>
        </div>
      )} */}

      {/* Add seller banner when a seller is selected */}
      {selectedSeller && (
        <div className="flex items-center justify-center">
        <div className="max-w-6xl bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white p-4 rounded-lg mb-6 flex items-center justify-center">
          <div className="flex items-center gap-3">
            <img
              src={selectedSeller.avatar}
              alt={selectedSeller.name}
              className="w-10 h-10 rounded-full"
            />
            <span className="font-semibold">Viewing templates from {selectedSeller.name}</span>
          </div>
          <button
            onClick={() => setSelectedSeller(null)}
            className="text-white hover:text-gray-200"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        </div>
      )}

      {/* Tab Navigation */}
      <div className="flex justify-center items-center pt-5">
        <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-8 items-center justify-center w-full max-w-xs sm:max-w-none">
          {/* Templates Button */}
          <motion.button
            onClick={() => handleTabChange('templates')}
            className={`px-8 py-3 rounded-full font-semibold text-lg shadow-md transition-all duration-300 ${activeTab === 'templates'
                ? "bg-gradient-to-r from-[#805af5] to-blue-600 text-white shadow-lg"
                : "bg-gray-200 text-gray-600 hover:bg-gradient-to-r hover:from-[#6eaffb] hover:to-[#4d8bfd] hover:text-white hover:shadow-lg"
              }`}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            Templates
          </motion.button>

          {/* My Generations Button - Only show if user is logged in */}
          {user && (
            <motion.button
            onClick={() => {
              setSelectedSeller(null);
              handleTabChange('generations');
            }}
              className={`px-8 py-3 rounded-full font-semibold text-lg shadow-md transition-all duration-300 ${activeTab === 'generations'
                  ? "bg-gradient-to-r from-[#805af5] to-blue-600 text-white shadow-lg"
                  : "bg-[#efefff] text-gray-600 hover:bg-gradient-to-r from-[#805af5] to-blue-600 hover:text-white hover:shadow-lg"
                }`}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              My Generations
            </motion.button>
          )}
        </div>
      </div>

      {/* Conditionally render MyGenerations component */}
      {showMyGenerations && <MyGenerations />}

      {/* Generation Progress Modal */}
      {isGenerating && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100]">
          <div className="bg-white rounded-lg p-6 shadow-xl w-80 text-center relative"> {/* Added relative positioning */}
            {/* Close button */}
            <button 
              onClick={() => setIsGenerating(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition-colors"
            >
              <X className="h-5 w-5" />
            </button>

            <Loader2 className="animate-spin h-8 w-8 mx-auto mb-4 text-primary" />
            <h2 className="text-xl font-semibold mb-2">Generating Your Video</h2>
            <p className="text-gray-600">This may take a few minutes...</p>
            <p className="text-sm text-gray-500 mt-2">You can close this popup and check the progress in "My Generations"</p>
          </div>
        </div>
      )}

      {/* Generated Video Modal */}
      {showGeneratedVideo && generatedVideoUrl && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            {/* Background overlay */}
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
              onClick={() => {
                setShowGeneratedVideo(false);
                setGeneratedVideoUrl(null);
              }}
            ></div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            {/* Modal panel */}
            <div className="inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
              <div className="relative">
                {/* Close button */}
                <button
                  onClick={() => {
                    setShowGeneratedVideo(false);
                    setGeneratedVideoUrl(null);
                  }}
                  className="absolute top-4 right-4 z-10 p-2 bg-white bg-opacity-25 rounded-full hover:bg-opacity-40 transition-all duration-200"
                >
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>

                {/* Video player */}
                <div className="aspect-video w-full">
                  <video
                    className="w-full h-full object-contain"
                    controls
                    autoPlay
                  >
                    <source src={generatedVideoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>

              {/* Video information */}
              <div className="bg-white px-4 py-3">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Generated Video
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Your generated video is ready
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {generationError && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-300 z-[100] ">
          <div className="bg-white rounded-lg p-4 shadow-xl w-80">
            <h2 className="text-xl font-bold text-red-600 mb-3">Generation Failed</h2>
            <p className="text-gray-800 mb-3">{generationError}</p>
            <button
              onClick={() => setGenerationError(null)}
              className="w-full bg-gray-300 text-gray-800 py-2 rounded-md font-semibold hover:bg-gray-400 transition duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Content based on active tab */}
      <div className="mt-8">
        {activeTab === 'templates' ? (
          <>
            <h1 className="text-4xl font-bold text-center m-10 font-custom text-black">
              {selectedSeller ? `${selectedSeller.name}'s Templates` : 'Explore Our Templates'}
            </h1>

            {isLoading ? (
              <div className="flex flex-col items-center justify-center min-h-[400px] space-y-4">
                <div className="relative w-20 h-20">
                  <div className="absolute inset-0 rounded-full border-4 border-gray-200"></div>
                  <div className="absolute inset-0 rounded-full border-4 border-t-violet-600 animate-spin"></div>
                </div>
                <div className="bg-gradient-to-r from-[#805af5] to-[#cd99ff] bg-clip-text text-transparent text-xl font-semibold">
                  Loading Templates...
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mx-auto max-w-6xl">
                {/* Template cards here */}
                {filteredTemplates.map((template) => (
                  <div key={template.id} className="bg-gradient-to-br from-[#f8f8ff] via-[#e6e6fa] to-[#dcdcdc] rounded-xl overflow-hidden shadow-lg transform transition duration-300 hover:scale-105 flex flex-col">
                    <img src={template.image} alt={template.name} className="w-full h-56 object-cover rounded-t" />

                    <div className="p-6 flex-grow flex flex-col justify-between">
                      <div className="flex-grow">
                        <h2 className="text-xl font-custom font-semibold mb-2">{template.name}</h2>
                        <p className="mb-4 text-sm text-[#55595c]">{template.description}</p>
                      </div>

                      {/* Update the seller section to be clickable */}
                      <div
                        className="flex items-center space-x-2 cursor-pointer hover:opacity-80 transition-opacity"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleSellerClick(template.seller);
                        }}
                      >
                        <img
                          src={template.seller.avatar}
                          alt={template.seller.name}
                          className="w-8 h-8 rounded-full"
                        />
                        <span className="text-sm font-medium text-gray-700">
                          {template.seller.name}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-between p-4">
                      < motion.button
                        onClick={() => handlePreview(template)}

                        className="bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-semibold w-full px-3 py-2 rounded-lg shadow-lg hover:bg-opacity-90 transition duration-200 mr-2  hover:bg-gradient-to-r hover:from-[#6a3ec2] hover:to-[#a68cfc]     text-center "
                      >
                        Preview
                      </motion.button>
                      <motion.button
                        onClick={() => handleGenerate(template)}

                        className="bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-semibold w-full px-3 py-2 rounded-lg shadow-lg hover:bg-opacity-90 transition duration-200 mr-2  hover:bg-gradient-to-r hover:from-[#6a3ec2] hover:to-[#a68cfc]  ml-2  text-center "
                      >
                        Generate
                      </motion.button>

                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        ) : (
          <MyGenerations />
        )}
      </div>

      {/* Preview Modal */}
      {showPreview && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            {/* Background overlay */}
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
              onClick={() => setShowPreview(false)}
            ></div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            {/* Modal panel */}
            <div className="inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
              <div className="relative">
                {/* Close button */}
                <button
                  onClick={() => setShowPreview(false)}
                  className="absolute top-4 right-4 z-10 p-2 bg-white bg-opacity-25 rounded-full hover:bg-opacity-40 transition-all duration-200"
                >
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>

                {/* Video player */}
                <div className="aspect-video w-full">
                  <video
                    className="w-full h-full object-contain"
                    controls
                    autoPlay
                  >
                    <source src={selectedTemplate.previewVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>

              {/* Video information */}
              <div className="bg-white px-4 py-3">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {selectedTemplate.name} - Preview
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Sample video preview of the template
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Custom Form Modal for Graph Templates */}
      {showCustomForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[100]">
          <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">
                {selectedTemplate.id === 16 ? 'Line Graph Generator' : 'Bar Graph Generator'}
              </h2>
              <button
                onClick={() => setShowCustomForm(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <DataInputForm onSubmit={handleCustomFormSubmit} />
          </div>
        </div>
      )}

      {/* Login Modal */}
      {showLoginPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]">
          <div 
            className="bg-white rounded-lg shadow-xl max-w-md w-full mx-4 relative animate-fadeIn"
            onClick={(e) => e.stopPropagation()}
          >
            <LoginModal 
              isModal={true} 
              onSuccess={() => {
                setShowLoginPopup(false);
                setShowPreGenModal(true);
              }}
              onClose={() => setShowLoginPopup(false)}
            />
          </div>
        </div>
      )}

      {/* Pre-Generation Modal */}
      {showPreGenModal && selectedTemplate && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
          <div className="bg-white rounded-xl w-full max-w-lg mx-auto max-h-[90vh] flex flex-col overflow-hidden">
            {/* Header */}
            <div className="p-4 border-b">
              <div className="flex justify-between items-start">
                <h2 className="text-2xl font-bold text-gray-800">Generate Video</h2>
                <button
                  onClick={() => setShowPreGenModal(false)}
                  className="text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
            </div>

            {/* Scrollable Content */}
            <div className="flex-1 overflow-y-auto p-4 space-y-4">
              {/* Template Image */}
              <div className="relative h-32 w-full rounded-lg overflow-hidden">
                <img
                  src={selectedTemplate.image}
                  alt={selectedTemplate.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
              </div>

              {/* Template Details */}
              <div className="space-y-3">
                {/* Title Section */}
                <div className="bg-gray-50 p-3 rounded-lg">
                  <div className="flex items-center gap-3 text-gray-700">
                    <VideoIcon className="h-5 w-5 text-violet-600 shrink-0" />
                    <div className="min-w-0">
                      <p className="text-sm text-gray-500">Title</p>
                      <p className="font-medium truncate">{selectedTemplate.title}</p>
                    </div>
                  </div>
                </div>

                {/* Content Section */}
                <div className="bg-gray-50 p-3 rounded-lg">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="font-semibold text-gray-800">Content</h3>
                    <button
                      onClick={() => setIsEditing(!isEditing)}
                      className="text-sm text-violet-600 hover:text-violet-700 font-medium"
                    >
                      {isEditing ? 'Save Changes' : 'Edit Content'}
                    </button>
                  </div>
                  
                  {loadingScenes ? (
                    <div className="text-center py-4">
                      <p className="text-gray-600">Loading...</p>
                    </div>
                  ) : templateScenes.type === 'scenes' && editableScenes.length > 0 ? (
                    <div className="space-y-2 max-h-48 overflow-y-auto">
                      {editableScenes.map((scene, index) => (
                        <div 
                          key={index} 
                          className="bg-white p-3 rounded border border-gray-200"
                        >
                          <div className="flex justify-between items-start mb-2">
                            <span className="font-medium text-sm text-gray-700">
                              Scene {index + 1}
                            </span>
                          </div>
                          {isEditing ? (
                            <textarea
                              value={scene.prompt}
                              onChange={(e) => handleSceneUpdate(index, e.target.value)}
                              className="w-full p-2 text-sm text-gray-600 border rounded focus:outline-none focus:ring-1 focus:ring-violet-500"
                              rows="2"
                            />
                          ) : (
                            <p className="text-sm text-gray-600">
                              {scene.prompt}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : templateScenes.type === 'text' && templateScenes.prompt ? (
                    <div className="bg-white p-3 rounded border border-gray-200">
                      <span className="font-medium text-sm text-gray-700">
                        ChatGPT Story Prompt
                      </span>
                      {isEditing ? (
                        <textarea
                          value={editableScenes[0]?.prompt || ''}
                          onChange={(e) => handleSceneUpdate(0, e.target.value)}
                          className="w-full mt-1 p-2 text-sm text-gray-600 border rounded focus:outline-none focus:ring-1 focus:ring-violet-500"
                          rows="3"
                        />
                      ) : (
                        <p className="text-sm text-gray-600 mt-1">
                          {templateScenes.prompt}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="text-center py-4">
                      <p className="text-gray-500">No content available for this template</p>
                    </div>
                  )}
                </div>

                {/* Credits Section */}
                <div className="bg-gray-50 p-3 rounded-lg">
                  <div className="flex items-center gap-3 text-gray-700">
                    <Wallet className="h-5 w-5 text-violet-600 shrink-0" />
                    <div className="min-w-0">
                      <p className="text-sm text-gray-500">Credits Required</p>
                      <div className="flex items-center gap-2">
                        <p className="font-medium">{selectedTemplate.credits}</p>
                        <span className="text-sm text-gray-500">
                          (You have: {userCredits})
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Footer with Actions here*/}
            <div className="p-4 border-t bg-gray-50">
              <button
                onClick={handleFinalGenerate}
                disabled={userCredits < selectedTemplate.credits}
                className={`w-full py-3 px-4 rounded-lg shadow-lg transition-all duration-300 flex items-center justify-center gap-2 text-white font-semibold
                  ${userCredits >= selectedTemplate.credits
                    ? 'bg-gradient-to-r from-[#805af5] to-[#cd99ff] hover:shadow-xl hover:opacity-90'
                    : 'bg-gray-400 cursor-not-allowed'}`}
              >
                {userCredits >= selectedTemplate.credits ? (
                  <span>Generate Video</span>
                ) : (
                  <span>Insufficient Credits</span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Templates;
