import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Star, Mail, Award, Package, Clock } from 'lucide-react';
import TemplateCard from '../../components/MarketPlace/TemplateCard';
import { serverbaseURL } from "../../constant/index";
import axios from 'axios';

export default function SellerProfile() {
  const { id } = useParams();
  const [seller, setSeller] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSellerData = async () => {
      try {
        const response = await axios.get(`${serverbaseURL}user/${id}`);
        const data = response.data;
        const formattedSeller = {
          sellerId: data.sellerId,
          name: data.creatorName,
          email: data.email,
          brandLogoLink: data.brandLogo,
          rating: Math.round(data.rating * 10) / 10,
          reviews: data.templates.length,
          templates: data.templates.map(template => ({
            id: template.id,
            title: template.title,
            description: template.description,
            price: template.price,
            rating: Math.round(template.rating * 10) / 10 || 0,
            category: template.category,
            creator: { name: data.creatorName, id: data.sellerId },
            thumbnail: template.thumbnail || 'No thumbnail available'
          })),
        };
        setSeller(formattedSeller);
        setLoading(false);
      } catch (error) {
        setError('Failed to load seller data');
        setLoading(false);
      }
    };
    fetchSellerData();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen mt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-lg p-8 mb-8 animate-pulse">
          <div className="flex items-center space-x-8">
            <div className="h-32 w-32 rounded-full bg-gray-200" />
            <div className="space-y-4 flex-1">
              <div className="h-8 w-1/3 bg-gray-200 rounded" />
              <div className="h-6 w-1/4 bg-gray-200 rounded" />
              <div className="h-12 w-full bg-gray-200 rounded" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen mt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 rounded-lg p-6">
          <p className="text-red-600 text-center">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen mt-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-xl shadow-lg mb-8 overflow-hidden">
        <div className="p-8">
          <div className="flex flex-col md:flex-row md:items-center gap-8">
            <div className="relative">
              <img
                src={seller.brandLogoLink}
                alt={seller.name}
                className="h-32 w-32 rounded-full object-cover border-4 border-white shadow-xl"
              />
              <span className="absolute -bottom-2 right-0 bg-blue-500 text-white px-3 py-1 rounded-full text-sm flex items-center">
                <Award className="w-4 h-4 mr-1" />
                Verified
              </span>
            </div>

            <div className="flex-1 space-y-4">
              <div>
                <h1 className="text-3xl font-bold text-gray-900">{seller.name}</h1>
                <div className="flex items-center mt-2 space-x-4">
                  <div className="flex items-center">
                    <Star className="h-5 w-5 text-yellow-400 fill-current" />
                    <span className="ml-1 font-medium">{seller.rating}</span>
                    <span className="ml-1 text-gray-500">({seller.reviews} reviews)</span>
                  </div>
                  <div className="flex items-center text-gray-500">
                    <Package className="h-5 w-5 mr-1" />
                    <span>{seller.templates.length} Templates</span>
                  </div>
                  <div className="flex items-center text-gray-500">
                    <Clock className="h-5 w-5 mr-1" />
                    <span>Quick Response</span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center">
                <div className="flex items-center bg-gray-50 px-4 py-3 rounded-lg flex-1">
                  <Mail className="h-5 w-5 text-gray-600" />
                  <span className="ml-3 text-gray-800">{seller.email}</span>
                </div>
                <button className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg transition-colors">
                  Contact Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold text-gray-900">Templates by {seller.name}</h2>
          <button className="border border-gray-200 hover:bg-gray-50 px-6 py-2 rounded-lg transition-colors">
            View All
          </button>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {seller.templates.map((template) => (
            <TemplateCard key={template.id} {...template} />
          ))}
        </div>
      </div>
    </div>
  );
}