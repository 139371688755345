import React from 'react';
import { Shield, Clock, CreditCard, Mail, DollarSign ,Phone} from 'lucide-react';

function RefundPolicyindia() {
  return (
    <div className="min-h-screen mt-10">
      {/* Header */}
      <header>
        <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
          <div className="flex items-center space-x-3">
            <Shield className="w-8 h-8 text-indigo-600" />
            <h1 className="text-3xl font-bold text-gray-900">Refund Policy</h1>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-md p-6 md:p-8 space-y-8">
          {/* Introduction */}
          <section className="space-y-4">
            <div className="bg-indigo-50 border-l-4 border-indigo-600 p-4 rounded">
              <p className="text-indigo-700">
                At Next Solutions (“we”, “us”, or “our”), we value our customers and aim to provide exceptional IT services through our website. This Refund Policy outlines our commitment to customer satisfaction and the process for requesting a refund.
              </p>
              <p className="text-gray-600 mt-3">
                Please read this policy carefully before purchasing any of our products or services. By using our services, you agree to the terms and conditions of this Refund Policy.
              </p>
            </div>
          </section>

          {/* Refund Eligibility */}
          <section className="space-y-4">
            <div className="grid gap-4 md:grid-cols-2">
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="font-semibold text-gray-900 mb-2 flex items-center gap-2">
                  <Clock className="w-5 h-5 text-indigo-600" />
                  Refund Eligibility
                </h3>
                <p className="text-gray-600">
                  Refunds may be granted under the following conditions:
                </p>
                <ul className="list-disc pl-6 text-gray-600 mt-3">
                  <li>The product or service does not meet the description provided on our website.</li>
                  <li>The product or service has significant defects or malfunctions.</li>
                  <li>A valid refund request is submitted within the specified refund window.</li>
                </ul>
                <p className="text-gray-600 mt-3">
                  Refunds are not granted for:
                </p>
                <ul className="list-disc pl-6 text-gray-600 mt-3">
                  <li>Change of mind or dissatisfaction not related to a policy breach.</li>
                  <li>Incompatibility issues not disclosed in the product description.</li>
                  <li>Misuse, unauthorized modifications, or damage caused by the user.</li>
                </ul>
              </div>

              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="font-semibold text-gray-900 mb-2 flex items-center gap-2">
                  <CreditCard className="w-5 h-5 text-indigo-600" />
                  Refund Request Process
                </h3>
                <p className="text-gray-600">
                  To request a refund, follow these steps:
                </p>
                <ul className="list-decimal pl-6 text-gray-600 mt-3">
                  <li>Contact our support team at <a href="mailto:info@automoviecreator.com" className="text-indigo-600 hover:underline">info@automoviecreator.com</a> with the subject line “Refund Request.”</li>
                  <li>Provide your order number, product details, and a detailed explanation of the issue.</li>
                  <li>Attach relevant documentation such as screenshots, error messages, or correspondence with support.</li>
                </ul>
                <p className="text-gray-600 mt-3">
                  Allow up to 5 business days for our team to review your request and respond. Refunds will be processed using the original payment method. Processing times may vary depending on your financial institution.
                </p>
              </div>
            </div>
          </section>

          {/* Exceptions and Policy Changes */}
          <section className="grid gap-4 md:grid-cols-2">
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="font-semibold text-gray-900 mb-2 flex items-center gap-2">
                <DollarSign className="w-5 h-5 text-indigo-600" />
                Exceptions
              </h3>
              <p className="text-gray-600">
                In certain circumstances, we may provide a refund or exchange at our sole discretion, even if the conditions outlined in this Refund Policy are not met. These exceptions will be assessed on a case-by-case basis.
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="font-semibold text-gray-900 mb-2 flex items-center gap-2">
                <CreditCard className="w-5 h-5 text-indigo-600" />
                Changes to This Refund Policy
              </h3>
              <p className="text-gray-600">
                We reserve the right to modify this Refund Policy at any time. Any changes will be effective when posted on our website. We encourage you to review this policy periodically to stay informed about our refund practices.
              </p>
            </div>
          </section>

          {/* Contact Section */}
          <section className="bg-indigo-50 p-6 rounded-lg">
            <div className="flex items-start gap-4">
              <Mail className="w-6 h-6 text-indigo-600 flex-shrink-0 mt-1" />
              <div>
                <h3 className="font-semibold text-gray-900 mb-2">Contact Us</h3>
                <div className="flex items-start gap-2">
                  {/* Gmail Logo and Email */}
                  <img src="https://www.gstatic.com/images/branding/product/1x/gmail_2020q4_48dp.png" alt="Gmail" className="w-5 h-5 mt-1" />
                  <p className="text-gray-600">
                    Email: <a href="mailto:info@automoviecreator.com" className="text-indigo-600 hover:underline">info@automoviecreator.com</a>
                  </p>
                </div>
                <div className="flex items-start gap-2 mt-2">
                  {/* Phone Icon and Number */}
                  <Phone className="w-5 h-5 text-indigo-600 mt-1" />
                  <p className="text-gray-600">Phone: +918130339698</p>
                </div>
                
              </div>
            </div>
          </section>



        </div>
      </main>
    </div>
  );
}

export default RefundPolicyindia;
