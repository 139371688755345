import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Gradient definitions for languages
const languageColors = {
  Java: {
    gradient: "linear-gradient(90deg, #f89b29 0%, #ff0f7b 100%)",
    textColor: "#ff0f7b"
  },
  JavaScript: {
    gradient: "linear-gradient(90deg, #4facfe 0%, #00f2fe 100%)",
    textColor: "#4facfe"
  },
  Python: {
    gradient: "linear-gradient(90deg, #43e97b 0%, #38f9d7 100%)",
    textColor: "#43e97b"
  },
  "C++": {
    gradient: "linear-gradient(90deg, #fa709a 0%, #fee140 100%)",
    textColor: "#fa709a"
  },
  PHP: {
    gradient: "linear-gradient(90deg, #667eea 0%, #764ba2 100%)",
    textColor: "#667eea"
  },
  "C#": {
    gradient: "linear-gradient(90deg, #30cfd0 0%, #330867 100%)",
    textColor: "#30cfd0"
  },
  Ruby: {
    gradient: "linear-gradient(90deg, #ff758c 0%, #ff7eb3 100%)",
    textColor: "#ff758c"
  },
  Swift: {
    gradient: "linear-gradient(90deg, #f77062 0%, #fe5196 100%)",
    textColor: "#f77062"
  },
  Kotlin: {
    gradient: "linear-gradient(90deg, #7F00FF 0%, #E100FF 100%)",
    textColor: "#7F00FF"
  },
  Go: {
    gradient: "linear-gradient(90deg, #00c6fb 0%, #005bea 100%)",
    textColor: "#00c6fb"
  }
};

const VideoProgrammingChart = ({ data, title, description }) => {
  const [currentYear, setCurrentYear] = useState(null);
  const [yearlyData, setYearlyData] = useState({});
  const [chartData, setChartData] = useState([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!data) return;

    // Process CSV data into yearly groups
    const processedData = {};
    data.forEach(row => {
      const year = row.date.split('-')[0];
      if (!processedData[year]) {
        processedData[year] = [];
      }

      // Convert all non-date columns to data points
      Object.entries(row).forEach(([key, value]) => {
        if (key !== 'date') {
          processedData[year].push({
            language: key,
            value: parseFloat(value),
            ...(languageColors[key] || {
              gradient: "linear-gradient(90deg, #808080 0%, #A9A9A9 100%)",
              textColor: "#808080"
            })
          });
        }
      });
    });

    setYearlyData(processedData);
    setCurrentYear(Object.keys(processedData)[0]);
    
    // Signal that the chart is ready
    setTimeout(() => {
      setIsReady(true);
    }, 500);
  }, [data]);

  useEffect(() => {
    if (!currentYear || !yearlyData[currentYear]) return;

    // Sort and update data for current year
    const sortedData = [...yearlyData[currentYear]]
      .sort((a, b) => b.value - a.value)
      .slice(0, 5); // Take top 5 languages
    setChartData(sortedData);

    // Auto-advance through years
    const years = Object.keys(yearlyData);
    const currentIndex = years.indexOf(currentYear);

    const timer = setTimeout(() => {
      if (currentIndex < years.length - 1) {
        setCurrentYear(years[currentIndex + 1]);
      } else {
        setCurrentYear(years[0]); // Loop back to start
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [currentYear, yearlyData]);

  const maxValue = Math.max(...(chartData.map(item => item.value) || [0]));

  // Add data ready event handler
  useEffect(() => {
    if (isReady) {
      window.dispatchEvent(new Event('chartDataReady'));
    }
  }, [isReady]);

  if (!isReady) {
    return <div>Loading chart...</div>;
  }

  return (
    <div className="w-full max-w-5xl p-12 bg-gradient-to-br from-gray-900 to-black rounded-xl shadow-2xl chart-container">
      {/* Year Display */}
      <motion.div 
        key={currentYear}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex justify-between items-center mb-12"
      >
        <h2 className="text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
          {currentYear || ''}
        </h2>
        <div className="text-2xl font-semibold text-gray-400">
          {title || 'Programming Language Rankings'}
        </div>
      </motion.div>

      {/* Chart */}
      <div className="relative">
        <AnimatePresence mode="popLayout">
          {chartData.map((item, index) => (
            <motion.div
              key={item.language}
              layout
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 50 }}
              transition={{
                type: "spring",
                stiffness: 300,
                damping: 30,
                duration: 0.5
              }}
              className="flex items-center mb-8"
            >
              {/* Language name */}
              <div className="w-40">
                <span 
                  className="text-xl font-bold"
                  style={{ color: item.textColor }}
                >
                  {item.language}
                </span>
              </div>

              {/* Bar */}
              <div className="flex-1 relative h-16">
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: `${(item.value / maxValue) * 100}%` }}
                  transition={{ duration: 1, type: "spring" }}
                  className="absolute top-0 left-0 h-full rounded-r-lg"
                  style={{ 
                    background: item.gradient,
                    boxShadow: `0 0 20px ${item.textColor}33`
                  }}
                />
                {/* Value */}
                <div className="absolute right-0 top-1/2 transform -translate-y-1/2 px-4">
                  <span className="text-2xl font-bold text-white">
                    {item.value.toFixed(1)}%
                  </span>
                </div>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      {/* Progress bar */}
      <motion.div 
        className="h-1 bg-gray-800 mt-8 rounded-full overflow-hidden"
      >
        <motion.div
          className="h-full bg-gradient-to-r from-purple-400 to-pink-600"
          initial={{ width: "0%" }}
          animate={{ width: "100%" }}
          transition={{ duration: 3, ease: "linear", repeat: Infinity }}
        />
      </motion.div>
    </div>
  );
};

export default VideoProgrammingChart;
