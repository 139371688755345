import { Mail, MapPin, Phone } from 'lucide-react';

export function ContactUs() {
    return (
        <div className="min-h-screen ">
            <div className="container mx-auto px-4 py-16">
                {/* Header */}
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold text-gray-800 mb-2">
                        Get in Touch
                    </h1>
                    <div className="w-20 h-1 bg-blue-600 mx-auto" />
                </div>

                {/* Main Content */}
                <div className="max-w-6xl mx-auto">
                    {/* Contact Cards */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
                        {/* Address Card */}
                        <div className="bg-[#efefff] p-8 rounded-xl shadow-lg border border-indigo-300/50 hover:border-indigo-500 transition-all duration-300 ease-in-out">
                            <div className="flex items-center gap-4 mb-4">
                                <div className="p-2 bg-blue-50 rounded-lg">
                                    <MapPin className="w-6 h-6 text-blue-600" />
                                </div>
                                <h2 className="text-xl font-semibold text-gray-800">
                                    Visit Us
                                </h2>
                            </div>
                            <p className="text-gray-600 leading-relaxed">
                                Ideashacks, Metro Pillar Number 564,
                                <br />
                                14/3, NH-19, Sector 31,
                                <br />
                                Faridabad, Haryana 121003
                            </p>
                        </div>

                        {/* Phone Card */}
                        <div className="bg-[#efefff] p-8 rounded-xl shadow-lg border border-indigo-300/50 hover:border-indigo-500 transition-all duration-300 ease-in-out">
                            <div className="flex items-center gap-4 mb-4">
                                <div className="p-2 bg-blue-50 rounded-lg">
                                    <Phone className="w-6 h-6 text-blue-600" />
                                </div>
                                <h2 className="text-xl font-semibold text-gray-800">
                                    Call Us
                                </h2>
                            </div>
                            <a
                                href="tel:+918130339698"
                                className="text-gray-600 hover:text-blue-600 transition-colors duration-300"
                            >
                                +91 813-033-9698
                            </a>
                        </div>

                        {/* Email Card */}
                        <div className="bg-[#efefff] p-8 rounded-xl shadow-lg border border-indigo-300/50 hover:border-indigo-500 transition-all duration-300 ease-in-out">
                            <div className="flex items-center gap-4 mb-4">
                                <div className="p-2 bg-blue-50 rounded-lg">
                                    <Mail className="w-6 h-6 text-blue-600" />
                                </div>
                                <h2 className="text-xl font-semibold text-gray-800">
                                    Email Us
                                </h2>
                            </div>
                            <a
                                href="mailto: info@automoviecreator.com"
                                className="text-gray-600 hover:text-blue-600 transition-colors duration-300"
                            >
                                info@automoviecreator.com
                            </a>
                        </div>
                    </div>

                    {/* Map Section */}
                    <div className="rounded-lg w-50 m-auto overflow-hidden shadow-lg border border-gray-100">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.6161377165584!2d77.31824147556661!3d28.46489497583931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce866e3c6f58d%3A0x0!2zMjjCsDI3JzUzLjYiTiA3N8KwMTknMTQuNyJF!5e0!3m2!1sen!2sin!4v1706108427404!5m2!1sen!2sin"
                            width="100%"
                            height="450"
                            style={{ border: 0 }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            className="50"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}