import React, { useEffect, useState } from 'react';
import VideoProgrammingChart from '../components/VideoProgrammingChart';

// Create a recording-only layout without navbar
const RecordingLayout = ({ children }) => (
  <div className="w-screen h-screen bg-black flex items-center justify-center overflow-hidden">
    {children}
  </div>
);

const ChartRecorder = () => {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      const data = window.chartData?.data;
      const title = window.chartData?.title;
      const description = window.chartData?.description;

      if (!data) {
        console.error('No chart data found in window.chartData');
        return;
      }

      setChartData({
        data,
        title,
        description
      });
    } catch (error) {
      console.error('Error setting up chart:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <div>Loading chart data...</div>;
  }

  if (!chartData) {
    return <div>No chart data available</div>;
  }

  return (
    <RecordingLayout>
      <VideoProgrammingChart 
        data={chartData.data}
        title={chartData.title}
        description={chartData.description}
      />
    </RecordingLayout>
  );
};

export default ChartRecorder; 