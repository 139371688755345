import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from "../provider/AuthProvider";
import axios from 'axios';
import { serverbaseURL } from "../constant/index";
import { toast } from "react-toastify";

const LoginModal = ({ isModal, onSuccess, onClose }) => {
  const { googleSignIn, setLoading, setPostLoginCallback } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      const googleData = await googleSignIn();
      const user = googleData.user;
      const userData = {
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        uid: user.uid,
      };

      const saveUserToDB = async () => {
        try {
          const response = await axios.post(`${serverbaseURL}user`, userData);
          if (response.status === 201) {
            console.log("User successfully registered:", response.data);
          } else {
            console.log("User already exists:", response.data);
          }
          
          // If used as modal, call onSuccess
          if (isModal && onSuccess) {
            onSuccess();
          } else {
            navigate("/"); // Default navigation if not modal
          }
        } catch (error) {
          console.error("Error saving user to database:", error);
          toast.error("An error occurred while saving your data. Please try again.");
        }
      };

      setPostLoginCallback(() => saveUserToDB);
      // Execute the callback immediately
      await saveUserToDB();
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      toast.error("An error occurred during login. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full max-w-md bg-white rounded-lg p-8">
      {isModal && (
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-gray-800 text-3xl font-semibold">Login</h1>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
      )}
      
      {!isModal && (
        <h1 className="text-gray-800 text-3xl text-center font-semibold">
          Login
        </h1>
      )}
      
      <p className="text-gray-500 text-center mt-2">Access your account</p>
      <div className="flex justify-center items-center mt-6">
        <button
          className="w-full bg-blue-600 text-white rounded-lg px-6 py-3 font-medium shadow-sm hover:bg-blue-700 transition duration-150"
          onClick={handleGoogleSignIn}
        >
          Sign in with Google
        </button>
      </div>
    </div>
  );
};

export default LoginModal;