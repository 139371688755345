import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "../../provider/AuthProvider";
import { serverbaseURL } from "../../constant/index";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFlowStore } from '../../store/flowStore';

export default function MyTemplates() {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [publishForm, setPublishForm] = useState({
    title: '',
    description: '',
    category: '',
    credits: '',
    creatorName: '',
    videoPreview: '',
  });
  const [file, setFile] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [publishError, setPublishError] = useState('');
  const [isPublishing, setIsPublishing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [showSellerModal, setShowSellerModal] = useState(false);
  const navigate = useNavigate();
  const {
    setNodes,
    setEdges
  } = useFlowStore();

  const handleViewClick = (template) => {
    console.log('View template data:', template);

    try {
      // The data structure is template.data.data
      const nodes = template?.data?.data?.nodes || [];
      const edges = template?.data?.data?.edges || [];

      console.log('Extracted nodes and edges:', { nodes, edges });

      // Create the template data structure that matches the flow import format
      const templateData = {
        name: template.name,
        data: {
          data: {
            nodes: nodes,
            edges: edges
          }
        },
        templateId: template.templateId
      };

      console.log('Processed template data:', templateData);
      
      // Set the nodes and edges in the flow store
      setNodes(nodes);
      setEdges(edges);
      
      localStorage.setItem('viewTemplate', JSON.stringify(templateData));
      navigate('/marketplace/createTemplate', { 
        state: { 
          mode: 'view', 
          template: templateData.data.data
        } 
      });
    } catch (error) {
      console.error('Error processing template data:', error);
      toast.error('Error loading template data');
    }
  };

  const handleEditClick = (template) => {
    console.log('Edit template data:', template);

    try {
      // The data structure is template.data.data
      const nodes = template?.data?.data?.nodes || [];
      const edges = template?.data?.data?.edges || [];

      console.log('Extracted nodes and edges:', { nodes, edges });

      // Create the template data structure that matches the flow import format
      const templateData = {
        name: template.name,
        data: {
          data: {
            nodes: nodes,
            edges: edges
          }
        },
        templateId: template.templateId
      };

      console.log('Processed template data:', templateData);

      // Set the nodes and edges in the flow store
      setNodes(nodes);
      setEdges(edges);
      
      localStorage.setItem('editTemplate', JSON.stringify(templateData));
      navigate('/marketplace/createTemplate', { 
        state: { 
          mode: 'edit', 
          template: templateData.data.data
        } 
      });
    } catch (error) {
      console.error('Error processing template data:', error);
      toast.error('Error loading template data');
    }
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        if (!user?.uid) {
          throw new Error('User not authenticated');
        }

        // Check if user is a seller using get_user_details
        const userResponse = await axios.post(`${serverbaseURL}get_user_details`, {
          uid: user.uid,
        });

        if (!userResponse.data.isSeller) {
          setShowSellerModal(true);
          return;
        }

        const response = await fetch(`${serverbaseURL}api/users-flows?userId=${user.uid}`);
        if (!response.ok) {
          throw new Error('Failed to fetch templates');
        }
        const data = await response.json();
        console.log('Fetched templates data structure:', data);
        setTemplates(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchTemplates();
  }, [user]);
  const handlePublishClick = (template) => {
    setSelectedTemplate(template);
    setPublishForm({
      title: template.name,
      description: '',
      category: '',
      credits: '',
      creatorName: '',
      videoPreview: '',
    });
    setPublishError('');
    setShowPublishModal(true);
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handlePriceFocus = () => {
    setShowTooltip(true);
  };
  const handlePriceBlur = () => {
    setShowTooltip(false);
  };
  const handlePublishSubmit = async (e) => {
    e.preventDefault();
    setPublishError('');
    setIsPublishing(true);

    // Validate credits
    if (Number(publishForm.credits) < 3) {
      setPublishError('Credits cannot be less than 3');
      setIsPublishing(false);
      return;
    }

    if (!publishForm.description || !publishForm.credits || !publishForm.category) {
      setPublishError('All fields are required');
      setIsPublishing(false);
      return;
    }

    try {
      // First upload the file if it exists
      let thumbnailUrl = '';
      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        const uploadResponse = await fetch(`${serverbaseURL}upload-file`, {
          method: 'POST',
          body: formData
        });

        if (!uploadResponse.ok) {
          throw new Error('Failed to upload file');
        }

        const uploadResult = await uploadResponse.json();
        thumbnailUrl = uploadResult.url;
      }

      const templateData = {
        templateId: selectedTemplate.templateId,
        title: publishForm.title,
        description: publishForm.description,
        category: publishForm.category,
        credits: publishForm.credits,
        creatorName: publishForm.creatorName,
        videoPreview: publishForm.videoPreview,
        userId: user.uid,
        email: user.email,
        data: selectedTemplate.data,
        thumbnailUrl: thumbnailUrl,
        flowData: selectedTemplate.data
      };

      const response = await fetch(`${serverbaseURL}add-template`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(templateData)
      });

      if (!response.ok) {
        throw new Error('Failed to publish template');
      }

      // Reset form data
      setPublishForm({
        title: '',
        description: '',
        category: '',
        credits: '',
        creatorName: '',
        videoPreview: '',
      });
      setFile(null);
      setShowPublishModal(false);

      toast.success("Template published successfully!");
      navigate("/templates");
    } catch (err) {
      setPublishError(err.message);
      toast.error("Failed to publish template");
    } finally {
      setIsPublishing(false);
    }
  };
  const handleDeleteClick = (template) => {
    setTemplateToDelete(template);
    setShowDeleteModal(true);
  };
  const handleDeleteConfirm = async () => {
    setIsDeleting(true);
    try {
      console.log("Deleting template:", templateToDelete.templateId);
      const response = await axios({
        method: 'DELETE',
        url: `${serverbaseURL}api/delete-template`,
        data: { templateId: templateToDelete.templateId },
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        setTemplates(templates.filter(t => t.templateId !== templateToDelete.templateId));
        setDeleteSuccess(true);
        // Auto close after 1.5 seconds
        setTimeout(() => {
          setShowDeleteModal(false);
          setDeleteSuccess(false);
        }, 1500);
      }
    } catch (err) {
      console.error('Delete error:', err);
      toast.error('Failed to delete template: ' + (err.response?.data?.message || err.message));
    } finally {
      setIsDeleting(false);
    }
  };
  const handleRegisterAsSeller = () => {
    navigate('/marketplace/seller-registration');
  };
  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }
  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-red-600">Error: {error}</div>
      </div>
    );
  }
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      
      <div className="mb-12 transform hover:scale-[1.01] transition-all duration-200">
        <div className="bg-white rounded-xl shadow-md hover:shadow-xl transition-shadow duration-200">
          <div className="p-6 border-b border-gray-100">
            <div className="flex items-center space-x-2">
              <svg 
                className="w-6 h-6 text-indigo-600" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>
              <h2 className="text-xl font-bold text-gray-900">Template Builder</h2>
            </div>
          </div>
          <div className="p-8">
            <div className="flex justify-between items-center">
              <div className="max-w-xl">
                <h3 className="text-3xl font-bold text-gray-900 mb-4 bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
                  Create New Template
                </h3>
                <p className="text-gray-600 text-lg leading-relaxed">
                  Start your creative journey by building a new template from scratch. Transform your ideas into reality with our powerful template builder.
                </p>
              </div>
              <Link
                to="/marketplace/createTemplate"
                state={{ mode: 'create' }}
                className="inline-flex items-center px-8 py-4 text-lg font-semibold rounded-xl bg-gradient-to-r from-indigo-600 to-purple-600 text-white shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 transition-all duration-200 hover:from-indigo-700 hover:to-purple-700"
              >
                <svg
                  className="mr-2 -ml-1 h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                </svg>
                Create Template
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold text-gray-900 bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent">
            My Templates
          </h1>
          <div className="flex items-center space-x-2 text-gray-500">
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
            <span>{templates.length} templates</span>
          </div>
        </div>

        {templates.length === 0 ? (
          <div className="text-center py-16 bg-white rounded-xl shadow-sm">
            <div className="max-w-md mx-auto">
              <svg
                className="mx-auto h-24 w-24 text-gray-400 mb-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">No Templates Yet</h3>
              <p className="text-gray-600 mb-8 text-lg">
                Start your creative journey by creating your first template. It's easy and fun!
              </p>
              <Link
                to="/marketplace/createTemplate"
                className="inline-flex items-center px-6 py-3 text-lg font-semibold rounded-xl bg-gradient-to-r from-indigo-600 to-purple-600 text-white shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 transition-all duration-200"
              >
                <svg
                  className="mr-2 -ml-1 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                </svg>
                Create Your First Template
              </Link>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {templates.map((template) => (
              <div
                key={template._id}
                className="bg-white rounded-xl shadow-sm hover:shadow-lg transition-all duration-200 transform hover:-translate-y-1"
              >
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-900 mb-3">
                    {template.name || 'Untitled Template'}
                  </h3>
                  <div className="flex items-center space-x-2 text-gray-500 text-sm mb-4">
                    <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <span>{template.createdAt ? new Date(template.createdAt).toLocaleDateString() : 'N/A'}</span>
                  </div>
                  <div className="flex flex-col space-y-3">
                    {template?.data?.data?.nodes ? (
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleViewClick(template)}
                          className="flex-1 bg-indigo-50 text-indigo-600 px-4 py-2 rounded-lg hover:bg-indigo-100 transition-colors duration-200"
                        >
                          View
                        </button>
                        <button
                          onClick={() => handleEditClick(template)}
                          className="flex-1 bg-blue-50 text-blue-600 px-4 py-2 rounded-lg hover:bg-blue-100 transition-colors duration-200"
                        >
                          Edit
                        </button>
                      </div>
                    ) : (
                      <span className="text-sm text-gray-500">No flow data available</span>
                    )}
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handlePublishClick(template)}
                        className="flex-1 bg-green-50 text-green-600 px-4 py-2 rounded-lg hover:bg-green-100 transition-colors duration-200"
                      >
                        Publish
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleDeleteClick(template);
                        }}
                        className="flex-1 bg-red-50 text-red-600 px-4 py-2 rounded-lg hover:bg-red-100 transition-colors duration-200"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {showPublishModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity backdrop-blur-sm"
            onClick={() => setShowPublishModal(false)}
          />

          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="relative bg-white rounded-xl max-w-3xl w-full p-8 shadow-2xl transform transition-all">
              <button
                onClick={() => setShowPublishModal(false)}
                className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
              >
                <svg className="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>

              <div className="text-center mb-8">
                <h2 className="text-3xl font-bold text-gray-900 mb-2">
                  Publish Template
                </h2>
                <p className="text-gray-600">
                  Share your template with the community
                </p>
              </div>

              {publishError && (
                <div className="mb-6 p-4 bg-red-50 border-l-4 border-red-500 rounded-md">
                  <p className="text-red-700">{publishError}</p>
                </div>
              )}

              <form onSubmit={handlePublishSubmit} className="space-y-6">
                <div className="mb-4 p-4 bg-gray-50 rounded-md">
                  <p className="text-sm text-gray-600">
                    <span className="font-medium">Template ID:</span> {selectedTemplate?.templateId}
                  </p>
                </div>

                <InputField
                  label="Template Title"
                  name="title"
                  value={publishForm.title}
                  onChange={(e) => setPublishForm(prev => ({ ...prev, title: e.target.value }))}
                  placeholder="Enter template title"
                  disabled
                  className="bg-gray-50"
                />

                <InputField
                  label="Template Description"
                  name="description"
                  value={publishForm.description}
                  onChange={(e) => setPublishForm(prev => ({ ...prev, description: e.target.value }))}
                  placeholder="Describe your template..."
                  isTextArea
                />

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <InputField
                    label="Category"
                    name="category"
                    value={publishForm.category}
                    onChange={(e) => setPublishForm(prev => ({ ...prev, category: e.target.value }))}
                    placeholder="e.g., Business, Education"
                  />

                  <div className="relative">
                    <InputField
                      label="Credits"
                      name="credits"
                      value={publishForm.credits}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || Number(value) >= 3) {
                          setPublishForm(prev => ({ ...prev, credits: value }));
                        }
                      }}
                      placeholder="Minimum 3 credits"
                      type="number"
                      min="3"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <InputField
                    label="Creator Name"
                    name="creatorName"
                    value={publishForm.creatorName}
                    onChange={(e) => setPublishForm(prev => ({ ...prev, creatorName: e.target.value }))}
                    placeholder="Your name"
                  />

                  <InputField
                    label="Video Preview URL"
                    name="videoPreview"
                    value={publishForm.videoPreview}
                    onChange={(e) => setPublishForm(prev => ({ ...prev, videoPreview: e.target.value }))}
                    placeholder="https://..."
                    type="url"
                  />
                </div>

                <div className="mt-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Template File
                  </label>
                  <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-indigo-500 transition-colors duration-200">
                    <div className="space-y-1 text-center">
                      <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      <div className="flex text-sm text-gray-600">
                        <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500">
                          <span>Upload a file</span>
                          <input
                            type="file"
                            onChange={handleFileChange}
                            className="sr-only"
                          />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs text-gray-500">
                        ZIP up to 50MB
                      </p>
                    </div>
                  </div>
                  {file && (
                    <p className="mt-2 text-sm text-gray-600">
                      Selected file: {file.name}
                    </p>
                  )}
                </div>

                <div className="flex justify-end space-x-4 mt-8">
                  <button
                    type="button"
                    onClick={() => setShowPublishModal(false)}
                    className="px-6 py-2.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
                    disabled={isPublishing}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isPublishing}
                    className="px-6 py-2.5 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200 flex items-center space-x-2"
                  >
                    {isPublishing ? (
                      <>
                        <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent"></div>
                        <span>Publishing...</span>
                      </>
                    ) : (
                      <span>Publish Template</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {showDeleteModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity backdrop-blur-sm"
            onClick={() => !isDeleting && setShowDeleteModal(false)}
          />

          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="relative bg-white rounded-xl max-w-md w-full p-6 shadow-2xl">
              {deleteSuccess ? (
                <div className="text-center py-4">
                  <svg
                    className="mx-auto h-12 w-12 text-green-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <h3 className="mt-2 text-xl font-semibold text-gray-900">
                    Template Deleted Successfully
                  </h3>
                </div>
              ) : (
                <>
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">
                    Confirm Delete
                  </h3>
                  <p className="text-gray-600 mb-6">
                    Are you sure you want to delete this template? This action cannot be undone.
                  </p>
                  <div className="flex justify-end space-x-4">
                    <button
                      onClick={() => setShowDeleteModal(false)}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                      disabled={isDeleting}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleDeleteConfirm}
                      className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700"
                      disabled={isDeleting}
                    >
                      {isDeleting ? (
                        <div className="flex items-center space-x-2">
                          <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent"></div>
                          <span>Deleting...</span>
                        </div>
                      ) : (
                        'Delete'
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {showSellerModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity backdrop-blur-sm" />

          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="relative bg-white rounded-xl max-w-md w-full p-8 shadow-2xl">
              <div className="text-center">
                <svg
                  className="mx-auto h-16 w-16 text-indigo-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                  />
                </svg>
                <h3 className="mt-4 text-xl font-semibold text-gray-900">
                  Become a Seller
                </h3>
                <p className="mt-2 text-gray-600">
                  To create and publish templates, you need to register as a seller first.
                </p>
                <div className="mt-6 space-y-3">
                  <button
                    onClick={handleRegisterAsSeller}
                    className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
                  >
                    Register as Seller
                  </button>
                  <Link
                    to="/templates"
                    className="block w-full px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
                  >
                    Explore Templates
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
};

function PriceTooltip({ price }) {
  const baseAmount = price * 0.8;
  const commission = price * 0.2;

  return (
    <div className="absolute top-full mt-1 bg-gray-100 border border-gray-300 rounded-md shadow-lg p-2 text-gray-700 text-sm w-full">
      <p><strong>Base Amount:</strong> ₹{baseAmount.toFixed(2)} (80%)</p>
      <p><strong>Commission:</strong> ₹{commission.toFixed(2)} (20%)</p>
      <p>Total: ₹{price}</p>
    </div>
  );
}

function InputField({ label, name, value, onChange, placeholder, type = 'text', isTextArea = false, ...rest }) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      {isTextArea ? (
        <textarea
          name={name}
          value={value}
          onChange={onChange}
          className="mt-2 p-4 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder={placeholder}
          {...rest}
        />
      ) : (
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder={placeholder}
          {...rest}
        />
      )}
    </div>
  );
}
