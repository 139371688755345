import axios from "axios";
import GradientHeading from "../components/GradientHeading";
import { useContext, useState, useEffect } from "react";
import { serverbaseURL } from "../constant/index";
import { AuthContext } from "../provider/AuthProvider";
import correct from "../assets/icons8-correct-50.png";
import wrong from "../assets/icons8-wrong-50.png";
import { motion } from 'framer-motion';
import { FaCheck, FaTimes, FaCrown, FaRocket, FaFire, FaRegGem } from 'react-icons/fa';
import { div } from "framer-motion/client";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';




const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const Billing = () => {
  const { user } = useContext(AuthContext);
  const [responseId, setResponseId] = useState("");
  const [responseState, setResponseState] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();

  

  const videoLimits = {
    'free': 1,
    'starter': 3,
    'daily': 7,
    'hardcore': 14
  };

  // Fetch user details when component mounts
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.post(`${serverbaseURL}get_user_details`, {
          uid: user.uid
        });
        setUserDetails(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    if (user?.uid) {
      fetchUserDetails();
    }
  }, [user]);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = src;

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };
  
  
  const handlePayment = async (e, price) => {
    e.preventDefault();
    if (!user) {
      // Redirect to login if the user is not logged in
      navigate('/login');
      return;
    }
    const amount = price * 100;
    const currency = "INR";
    const receipt = `receipt_${Math.random().toString(36).substring(7)}`;

    const paymentData = {
      userId: user,
      amount,
      currency,
      receipt,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${serverbaseURL}order`,
      headers: {
        "Content-Type": "application/json",
      },
      data: paymentData,
    };

    axios
      .request(config)
      .then((response) => {
        handleRazorpayScreen(response.data.amount);
      })
      .catch((error) => {
        console.error("error at", error);
      });
  };

  const handleRazorpayScreen = async (amount) => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      toast.error("Some error at razorpay screen loading");
      return;
    }

    const options = {
      key: "rzp_live_k9cKl94apbk7aE",
      amount: amount,
      currency: "INR",
      name: "AutoMovieCreator",
      description: "Payment to AutoMovieCreator",
      image: "https://papayacoders.com/demo.png",
      handler: async function (response) {
        setResponseId(response.razorpay_payment_id);
        // Make the second API call after Razorpay payment is completed
        try {
          const paymentResponse = await axios.get(
            `${serverbaseURL}payment/${response.razorpay_payment_id}`
          );
          setResponseState(paymentResponse.data);
          setIsModalOpen(true);
          console.log('payment response', paymentResponse.data);
          // Make the new API call to payment-confirmation
          await axios.post(`${serverbaseURL}payment-confirmation`, {
            email: user?.email,
            amount: amount / 100,
            paymentId: paymentResponse.data.paymentId,
            method: paymentResponse.data.method,
            success: true,
          });
        } catch (error) {
          // Make the new API call to payment-confirmation in case of error
          await axios.post(`${serverbaseURL}payment-confirmation`, {
            email: user.email,
            amount: amount / 100,
            success: false,
          });
          console.error("Error occurred while fetching payment details", error);
        }
      },
      prefill: {
        name: "Auto Movie Creator",
        email: "info@automoviecreator.com",
      },
      theme: {
        color: "#F4C430",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  // Modal component
  const PaymentModal = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
        <h2 className="text-2xl font-bold mb-4">Payment Details</h2>
        {responseState.length !== 0 && (
          <ul>
            <li>Amount: {responseState.amount / 100} Rs.</li>
            <li>Currency: {responseState.currency}</li>
            <li>Status: {responseState.status}</li>
            <li>Method: {responseState.method}</li>
          </ul>
        )}
        <button
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
          onClick={() => setIsModalOpen(false)}
        >
          Close
        </button>
      </div>
    </div>
  );

  const getPlanIcon = (planName) => {
    switch (planName?.toLowerCase()) {
      case 'free':
        return <FaCrown className="text-4xl text-yellow-500 mb-2" />;
      case 'starter':
        return <FaRocket className="text-4xl text-blue-500 mb-2" />;
      case 'daily':
        return <FaFire className="text-4xl text-orange-500 mb-2" />;
      case 'hardcore':
        return <FaRegGem className="text-4xl text-purple-500 mb-2" />;
      default:
        return null;
    }
  };

  const renderBuyButton = (planName, price) => {
    const isCurrentPlan = userDetails?.plan?.toLowerCase() === planName.toLowerCase();

    return (
      <motion.button
        className={`bg-gradient-to-r ${isCurrentPlan
            ? 'from-gray-400 to-gray-500 cursor-not-allowed'
            : 'from-[#805af5] to-[#cd99ff] hover:from-[#6a3ec2] hover:to-[#a68cfc]'
          } text-white font-medium sm:text-lg py-2 sm:py-2 px-3 sm:px-4 rounded-lg w-full leading-[40px] tracking-[0.5px] mt-6 border-0 text-center inline-block`}
        whileHover={{ scale: isCurrentPlan ? 1 : 1.05 }}
        onClick={(e) => !isCurrentPlan && handlePayment(e, price)}
        disabled={isCurrentPlan}
      >
        {isCurrentPlan ? 'CURRENT PLAN' : 'BUY'}
      </motion.button>
    );
  };

  return (
    <div className=" min-h-screen">
      <div className="max-w-6xl mx-auto">
        <div className="pt-20 px-5 ml-5 mb-2 pb-5">
          <GradientHeading text="CURRENT PLAN" />
        </div>
        <div className="px-5">
          <div className="px-5">
            <motion.div className="bg-[#805af5] max-w-2xl px-8 py-10 md:px-16 shadow-xl rounded-lg"
              initial={{ opacity: 0, x: -1000, scale: 0.95 }} // Starts off-screen to the left with slight scale down
              animate={{ opacity: 1, x: 0, scale: 1 }} // Animates to full opacity and position with scale up
              transition={{
                duration: 0.4, // Duration for smooth transition
                ease: 'easeInOut', // Smooth easing function for smooth and natural animation
                delay: 0.1, // A slight delay for a more fluid effect
              }}
            >
              <p className="text-lg text-white">
                <span className="font-bold">Current Plan:</span> {userDetails?.plan ? capitalizeFirstLetter(userDetails.plan) : 'Loading...'}
              </p>
              <p className="text-lg text-white">
                <span className="font-bold">Credits Remaining:</span> {userDetails?.credits || 0}
              </p>
              {/* <p className="text-lg text-white">
                <span className="font-bold">Last Reset:</span> {userDetails?.lastVideoReset
                  ? new Date(userDetails.lastVideoReset).toLocaleDateString()
                  : 'N/A'}
              </p> */}
              <p className="text-lg text-white">
                <span className="font-bold">Status:</span> {userDetails?.status || 'Loading...'}
              </p>
            </motion.div>
          </div>

        </div>
        <div className="pt-14 px-5 mt-2 ml-6 ">
          <GradientHeading text="CHANGE PLAN" />
        </div>
        {/* card container */}

        <div className=" p-5 rounded-lg grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-5 ">
          {/* card 1 */}
          <div className="h-full relative inline-block p-4 text-white">
            <div className="relative z-10 border-2 border-[#805af5] rounded-xl bg-[#f8f8ff] p-8 h-full shadow-lg hover:shadow-2xl hover:border-[#0d6efd]">
              <div className="flex flex-col items-center">
                {getPlanIcon('free')}
                <p className="text-black text-[48px] leading-tight block text-center bg-opacity-100 pb-1 font-serif">FREE</p>
                <h1 className="text-gray-700 mb-5 text-[44px] leading-tight block text-center bg-opacity-100 pb-2">₹0</h1>
                {/* Features Heading */}
                <h2 className="text-black text-left pb-2 opacity-100  text-[17px]  font-[500] font-serif mb-4">Features</h2>
                {/* Features List */}
                <div className="">
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                      1 Free Credit
                    </p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                      Schedule Post to Socials
                    </p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                      Edit & Preview Videos
                    </p>
                  </div>
                  {/* Disabled Features */}
                  <div className="flex justify-start items-center gap-2">
                    <FaTimes className="h-4 w-4 text-red-400" />
                    <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">Auto Post To Channel</p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaTimes className="h-4 w-4 text-red-400" />
                    <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">HD Video Resolution</p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaTimes className="h-4 w-4 text-red-400" />
                    <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">Background Music</p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaTimes className="h-4 w-4 text-red-400" />
                    <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">No Watermark</p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaTimes className="h-4 w-4 text-red-400" />
                    <p className="line-through text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">Download Videos</p>
                  </div>
                </div>
                {renderBuyButton('free', 0)}
              </div>
            </div>
          </div>

          {/* card 2 */}
          <div className="h-full relative inline-block p-4 text-white">
            <div className="relative z-10 border-2 border-[#805af5] rounded-xl bg-[#f8f8ff] p-8 h-full shadow-lg hover:shadow-2xl hover:border-[#0d6efd]">
              <div className="flex flex-col items-center">
                {getPlanIcon('starter')}
                <p className="text-black text-[48px] leading-tight block text-center bg-opacity-100 pb-1 font-serif">STARTER</p>
                <h1 className="text-gray-700 mb-5 text-[44px] leading-tight block text-center bg-opacity-100 pb-2">₹1597</h1>
                <h2 className="text-black text-left pb-2 opacity-100  text-[17px]  font-[500] font-serif mb-4">Features</h2>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    Get 20 Credits
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    Schedule Post to Socials
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    Edit & Preview Videos
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    Auto Post To Channel
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    HD Video Resolution
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    Background Music
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    No Watermark
                  </p>
                </div>
                <div className="flex justify-start items-center gap-2">
                  <FaCheck className="h-4 w-4 text-green-400" />
                  <p className="text-[#55595c]    text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                    Download Videos
                  </p>
                </div>
                {renderBuyButton('starter',  1597)}
                {/* <script src="https://buy.automoviecreator.com/js/gumroad.js"></script>
                <a class="gumroad-button bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 transition duration-200 "  href="https://buy.automoviecreator.com/l/membershipplan" data-gumroad-single-product="true">Buy on</a> */}
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="h-full relative inline-block p-4 text-white">
            <div className="relative z-10 border-2 border-[#805af5] rounded-xl bg-[#f8f8ff] p-8 h-full shadow-lg hover:shadow-2xl hover:border-[#0d6efd]">
              <div className="flex flex-col items-center">
                {getPlanIcon('daily')}
                <p className="text-black text-[48px] leading-tight block text-center bg-opacity-100 pb-1 font-serif">DAILY</p>
                <h1 className="text-gray-700 mb-5 text-[44px] leading-tight block text-center bg-opacity-100 pb-2">₹3279</h1>
                <h2 className="text-black text-left pb-2 opacity-100 text-[17px] font-[500] font-serif mb-4">Features</h2>
                <div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">Get 50 Credits</p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">Schedule Post to Socials</p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">Edit & Preview Videos</p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">Auto Post To Channel</p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">HD Video Resolution</p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">Background Music</p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">No Watermark</p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">Download Videos</p>
                  </div>
                </div>
                {renderBuyButton('daily', 3279)}
              </div>
            </div>
          </div>
          {/* card 4 */}
          <div className="h-full relative inline-block p-4 text-white">
            <div className="relative z-10 border-2 border-[#805af5] rounded-xl bg-[#f8f8ff] p-8 h-full shadow-lg hover:shadow-2xl hover:border-[#0d6efd]">
              <div className="flex flex-col items-center">
                {getPlanIcon('hardcore')}
                <p className="text-black text-[48px] leading-tight block text-center bg-opacity-100 pb-1 font-serif">HARDCORE</p>
                <h1 className="text-gray-700 mb-5 text-[44px] leading-tight block text-center bg-opacity-100 pb-2">₹5801</h1>
                <h2 className="text-black text-left pb-2 opacity-100 text-[17px] font-[500] font-serif mb-4">Features</h2>
                <div className="">
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                      Get 100 Credits
                    </p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                      Schedule Post to Socials
                    </p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                      Edit & Preview Videos
                    </p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                      Auto Post To Channel
                    </p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                      HD Video Resolution
                    </p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                      Background Music
                    </p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                      No Watermark
                    </p>
                  </div>
                  <div className="flex justify-start items-center gap-2">
                    <FaCheck className="h-4 w-4 text-green-400" />
                    <p className="text-[#55595c] text-[16px] leading-[1.67] font-[500] bg-opacity-100">
                      Download Videos
                    </p>
                  </div>
                </div>
                {renderBuyButton('hardcore', 5801)}
              </div>
            </div>
          </div>

        </div>
        {/* Modal */}
        {isModalOpen && <PaymentModal />}
      </div>
    </div>
  );
};

export default Billing;
