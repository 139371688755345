import React, { useState } from "react";
import axios from "axios";
import { serverbaseURL } from "../constant/index";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

const Support = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    category: "",
    description: "",
  });
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [activeIndex, setActiveIndex] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => {
    const regex = /^[A-Za-z0-9]+@[A-Za-z0-9.]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
  };

  const validateName = (name) => {
    const regex = /^[A-Za-z]+(?:['\s][A-Za-z]+)*$/;
    return regex.test(name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(formData.email)) {
      toast.error(
        "Please enter a valid email address without any special characters."
      );
      return;
    }
    if (!validateName(formData.name)) {
      toast.error(
        "Please enter a valid name. Special characters like %, ^, and & are not allowed."
      );
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${serverbaseURL}support-tickets`,
        formData
      );
      setShowModal(true); setFormData({
        name: "",
        email: "",
        subject: "",
        category: "",
        description: "",
      });
      // // Additional confirmation message (you can add this part to show a popup or message)
      // toast.info("We have received your request. Our support team will contact you shortly.");
    } catch (error) {
      console.error("Error submitting support request:", error);
      toast.error("Failed to submit support request. Please try again.");
    }
    finally {
      setLoading(false);
    }
  };

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const faqs = [
    {
      question: "What is Our Sales CRM Software Call QA Platform?",
      answer:
        "Our Sales CRM Software Call QA Platform is a comprehensive solution for call center quality assurance, designed to help businesses improve revenue, reduce churn, and deliver superior customer service. It offers advanced features for call monitoring, analysis, and evaluation to optimize call center operations.",
    },
    {
      question: "How does the software ensure accurate speech-to-text transcription?",
      answer:
        "Our Sales CRM Software uses advanced speech-to-text technology to achieve exceptional accuracy levels across various English dialects, ensuring the most precise transcriptions and analysis for your call center operations.",
    },
    {
      question: "Can Our Sales CRM Software handle different English dialects?",
      answer:
        "Yes, Our Sales CRM Software supports a variety of English dialects, including American, Australian, Canadian, Indian, South African, South East Asian, and UK dialects.",
    },
    {
      question: "Does the software analyze every call made by agents?",
      answer:
        "Yes, Our Sales CRM Software analyzes 100% of calls handled by your agents, ensuring no conversation is overlooked in the quality assurance process.",
    },
    {
      question: "How does the software help improve individual agent performance?",
      answer:
        " Our Sales CRM Software can identify and analyze calling behaviors and patterns of individual agents across multiple dimensions and call moments, providing valuable insights for optimizing agent performance and improving overall call center operations.",
    },
    {
      question: "Can I filter calls based on specific criteria?",
      answer:
        "Yes, Our Sales CRM Software offers exhaustive call filtering options, allowing you to filter calls based on various criteria such as dates, agents, call moments, feedback, duration, call type, and more.",
    },
    {
      question: "How does the auto seek audio feature work?",
      answer:
        "The auto seek audio feature allows you to review a 30-minute call in under 30 seconds by automatically seeking to the specific duration where dialogue is spoken, eliminating the need to listen to the call end-to-end.",
    },

    {
      question: "What is moment highlighting, and how does it help with call analysis?",
      answer:
        "Moment highlighting tags all calls against critical call moments that were spoken, allowing you to view the entire call summary in a single glance by identifying achieved or missed moments.",
    },
    {
      question: "Can I evaluate calls and provide feedback within the software?",
      answer:
        " Yes, Our Sales CRM Software comes with a built-in evaluation module, allowing you to rate calls, provide feedback, and share notes instantly with agents and supervisors.",
    },
    {
      question: " Is there a feature to bookmark calls for future reference?",
      answer:
        "Yes, the software offers a playback library where you can bookmark calls for various purposes such as escalation, team coaching, new onboardings , and more.",
    },
    {
      question: "What is the refund policy for Our Sales CRM Software?",
      answer:
        " For information on the refund policy, please refer to the terms and conditions on our website or contact our customer support team, who will be happy to assist you with your inquiry.",
    },
    {
      question: " Data Deletion?",
      answer:
        "Whenever someone requests for Cancellation or Data deletion, the data for the user will be deleted within 7 working days. ",
    },
  ];

  return (
    <div className="flex flex-col items-center my-10 space-y-10">
      {/* Support Form */}
      <motion.div
        className="flex justify-center items-center w-full"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          ease: "easeInOut",
          delay: 0.3,
        }}
      >
        <div className="bg-[#efefff] w-full max-w-md p-8 rounded-xl shadow-md border border-[#805af5]/20 hover:border-[#805af5] transition-all duration-300 ease-in-out">
          <h1 className="bg-black font-custom text-transparent bg-clip-text text-3xl text-center font-semibold mb-6">
            Support Request
          </h1>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              className="w-full py-2 text-[#21242d] border-2 border-[#805af5]/15 rounded-[12px] h-[50px] text-[16px] px-[15px] bg-transparent shadow-none outline-none leading-[28px] font-normal transition-all max-w-full align-baseline"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              className="w-full py-2 text-[#21242d] border-2 border-[#805af5]/15 rounded-[12px] h-[50px] text-[16px] px-[15px] bg-transparent shadow-none outline-none leading-[28px] font-normal transition-all max-w-full align-baseline"
              required
            />
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="Subject"
              className="w-full py-2 text-[#21242d] border-2 border-[#805af5]/15 rounded-[12px] h-[50px] text-[16px] px-[15px] bg-transparent shadow-none outline-none leading-[28px] font-normal transition-all max-w-full align-baseline"
              required
            />
            <select
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="w-full py-2 text-[#21242d] border-2 border-[#805af5]/15 rounded-[12px] h-[50px] text-[16px] px-[15px] bg-transparent shadow-none outline-none leading-[28px] font-normal transition-all max-w-full align-baseline"
              required
            >
              <option value="">Select Category</option>
              <option value="technical">Technical Issue</option>
              <option value="billing">Billing</option>
              <option value="general">General Inquiry</option>
            </select>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Describe your issue"
              className="w-full py-2 text-[#21242d] border-2 border-[#805af5]/15 rounded-[12px] text-[16px] px-[15px] bg-transparent shadow-none outline-none leading-[28px] font-normal transition-all max-w-full align-baseline"
              rows="4"
              required
            ></textarea>
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white font-medium text-lg sm:text-sm py-2 sm:py-3 px-6 sm:px-8 rounded-lg hover:shadow-lg transition duration-400 ease-in-out leading-[50px] tracking-[0.5px] mt-8 border-0 text-center inline-block"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit Request"}
            </button>
          </form>
        </div>
      </motion.div>
      {/* Success Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
            <h2 className="text-xl font-semibold mb-4">Success!</h2>
            <p>We have received your request. Our support team will contact you shortly.</p>
            <button
              onClick={() => setShowModal(false)}
              className="mt-4 px-6 py-2 bg-[#805af5] text-white rounded-lg"
            >
              Close
            </button>
          </div>
        </div>
      )}
      {/* FAQ Section */}
      <motion.div
        className="w-full max-w-4xl bg-[#efefff] p-8 rounded-xl shadow-md border border-[#805af5]/20 hover:border-[#805af5] transition-all duration-300 ease-in-out"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.8,
          ease: "easeInOut",
          delay: 0.5,
        }}
      >
        <h2 className="text-3xl font-semibold text-[#21242d] mb-6 text-center">
          Frequently Asked Questions
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="border-b border-[#805af5]/15 pb-4"
            >
              <button
                onClick={() => toggleFAQ(index)}
                className="w-full text-left flex justify-between items-center text-lg font-medium text-[#21242d] focus:outline-none"
              >
                <span>{faq.question}</span>
                <span>
                  {activeIndex === index ? "-" : "+"}
                </span>
              </button>
              {activeIndex === index && (
                <p className="mt-2 text-[#21242d]">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Support;
