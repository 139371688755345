import React, { useState, useEffect } from 'react';
import { Sliders, ChevronDown } from 'lucide-react';

import TemplateCard from '../../components/MarketPlace/TemplateCard';
import { serverbaseURL } from "../../constant/index";
import axios from 'axios';
import { Loader, Search } from 'lucide-react';
import Flo from '../../Flo';
import { div } from 'framer-motion/client';
import { Link } from 'react-router-dom';

const SORT_OPTIONS = [
  'Featured',
  'Newest',
  'Date: Oldest to Newest',
  'Date: Newest to Oldest',
  'Price: Low to High',
  'Price: High to Low',
  'Rating',
];

export default function TemplatesListing() {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [sortBy, setSortBy] = useState('Featured');
  const [templates, setTemplates] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [maxPrice, setMaxPrice] = useState(100000);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true); // Set loading to true when fetching data
      try {
        const response = await axios.get(`${serverbaseURL}get-template`);
        const fetchedTemplates = response.data.templates;

        setTemplates(fetchedTemplates);
        const highestPrice = Math.max(...fetchedTemplates.map((template) => template.price));
        setMaxPrice(highestPrice || 100000);
        setPriceRange([0, highestPrice || 100000]);
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setLoading(false); // Set loading to false when fetching is done
      }
    };

    fetchTemplates();
  }, []);

  const categories = ['All', ...new Set(templates.map((template) => template.category))];

  const handleCategoryFilterChange = (category) => {
    setCategoriesFilter((prevFilters) =>
      prevFilters.includes(category)
        ? prevFilters.filter((c) => c !== category)
        : [...prevFilters, category]
    );
  };

  const filteredTemplates = templates.filter((template) => {
    const matchesCategory =
      selectedCategory === 'All' || template.category === selectedCategory;
    const matchesCategoriesFilter =
      categoriesFilter.length === 0 || categoriesFilter.includes(template.category);
    const matchesSearch =
      template.title.toLowerCase().includes(searchText.toLowerCase()) ||
      template.category.toLowerCase().includes(searchText.toLowerCase());
    const matchesPrice =
      template.price >= priceRange[0] && template.price <= priceRange[1];

    return matchesCategory && matchesCategoriesFilter && matchesSearch && matchesPrice;
  });

  const sortedTemplates = filteredTemplates.sort((a, b) => {
    switch (sortBy) {
      case 'Price: Low to High':
        return a.price - b.price;
      case 'Price: High to Low':
        return b.price - a.price;
      case 'Rating':
        return b.averageRating - a.averageRating;
      case 'Newest':
        return new Date(b.createdAt) - new Date(a.createdAt);
      case 'Date: Oldest to Newest':
        return new Date(a.createdAt) - new Date(b.createdAt);
      case 'Date: Newest to Oldest':
        return new Date(b.createdAt) - new Date(a.createdAt);
      default:
        return 0;
    }
  });

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div className='mt-0'>
      {/* <Link
          to="/marketplace/createTemplate"
          className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700"
        >
          Create Template
        </Link>
    <Flo/> */}
    <div className="w-full px-4 mt-16 pt-14 min-h-screen bg-white sm:px-6 lg:px-8 py-8 grid grid-cols-12 gap-6">
      {/* Sidebar */}
      <div className="col-span-12 lg:col-span-3  p-4 sticky top-20 h-max ">
        <h2 className="text-2xl font-bold mb-10 font-custom text-blue-900">Filter  by</h2>
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-black  mb-1">Price Range</h3>
          <input
            type="range"
            min="0"
            max={maxPrice}
            value={priceRange[1]}
            onChange={(e) => setPriceRange([0, Number(e.target.value)])}
            className="w-full accent-blue-800"
          />
          <p className="text-gray-800 mt-1">
            Rs. {priceRange[0]} - Rs. {priceRange[1]}
          </p>
        </div>

        <div className="mt-10">
          <h3 className="text-lg font-semibold text-black  mb-2">Categories</h3>
          {categories.map((category) => (
            <div key={category} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={category}
                checked={categoriesFilter.includes(category)}
                onChange={() => handleCategoryFilterChange(category)}
                className="mr-3 accent-blue-600"
              />
              <label htmlFor={category} className="text-gray-800">{category}</label>
            </div>
          ))}
        </div>


      </div>

      {/* Main Content */}
      < div className="w-full mt-3 col-span-12 lg:col-span-9">
        <div className="flex flex-col sm:flex-row justify-end gap-4  mr-6 items-center mb-8 space-y-3 sm:space-y-0 sm:space-x-4">
          {/* Search Input */}
          <input
            value={searchText}
            onChange={handleSearchChange}
            type="text"
            placeholder="Search templates..."
            className="w-full sm:w-1/2 lg:w-1/3 px-3 py-1.5 rounded-md border border-gray-600 text-gray-800 placeholder-gray-500 shadow-sm bg-white focus:ring-2 focus:ring-blue-800 focus:outline-none transition-colors"
          />

          {/* Sort Dropdown */}
          <div className="flex items-center space-x-2">
            <span className="text-gray-800 text-lg font-medium">Sorted by</span>
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="px-3 py-1.5 rounded-md border border-gray-600 bg-white text-gray-800 shadow-sm hover:border-gray-700 focus:ring-2 focus:ring-blue-800 focus:outline-none transition-colors"
            >
              {SORT_OPTIONS.map((option) => (
                <option key={option} value={option} className="text-gray-800 bg-white">
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>



        {/* Loading State */}
        {loading ? (
          <div className="flex justify-center items-center py-8">
            <Loader className="animate-spin text-blue-900" size={48} />
          </div>
        ) : filteredTemplates.length === 0 ? (
          <div className="text-center text-gray-600 py-8">
            <h3 className="text-xl font-bold mb-4">No Templates Found</h3>
            <p>Try changing your filters or search criteria to find templates that match.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {sortedTemplates.map((template) => (
              <TemplateCard
                key={template._id}
                id={template._id}
                title={template.title}
                price={template.price}
                rating={template.averageRating}
                category={template.category}
                creator={{ name: template.creatorName || template.creator }}
                thumbnail={template.thumbnailUrl}
                sellerId={template.sellerId}
              />
            ))}
          </div>
        )}
      </div>
    </div>
    </div>

  );
}
