import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';

const DataInputForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    file: null,
  });
  const [error, setError] = useState('');

  useEffect(() => {
    // Check for injected data from Puppeteer
    if (window.chartData) {
      onSubmit(window.chartData);
      window.chartData = null; // Clear the data after use
    }
  }, [onSubmit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && !file.name.endsWith('.csv')) {
      setError('Please upload a CSV file');
      return;
    }
    setFormData(prev => ({
      ...prev,
      file
    }));
    setError('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!formData.file) {
      setError('Please upload a CSV file');
      return;
    }

    // Create FormData object for file upload
    const submitData = {
      title: formData.title,
      description: formData.description,
      file: formData.file  // Pass the actual file object
    };

    onSubmit(submitData);
  };

  return (
    <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-6">Enter Chart Data</h2>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Chart Title
          </label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            placeholder="Enter chart title"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Description
          </label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            placeholder="Enter chart description"
            rows="3"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Upload CSV File
          </label>
          <input
            type="file"
            name="csvFile"  // Add name attribute
            accept=".csv"
            onChange={handleFileChange}
            className="w-full"
            required
          />
          <p className="mt-2 text-sm text-gray-500">
            CSV format example:<br />
            date,Python,JavaScript,Java,C++,Ruby<br />
            2020-01,30,25,20,15,10<br />
            2020-02,32,27,19,16,11
          </p>
        </div>

        {error && (
          <div className="text-red-500 text-sm">
            {error}
          </div>
        )}

        <button
          type="submit"
          className="w-full bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white py-2 px-4 rounded-md hover:opacity-90 transition-all duration-300"
        >
          Generate Chart
        </button>
      </form>
    </div>
  );
};

export default DataInputForm; 