import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../provider/AuthProvider';
import axios from 'axios';
import { serverbaseURL } from '../constant/index.jsx';
import { 
  Calendar, 
  Clock, 
  MessageSquare, 
  Loader2, 
  Repeat, 
  ArrowLeft,
  Video,
  Share2,
  Type
} from 'lucide-react';
import { toast } from 'react-toastify';

const SetupAutomation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user , userCredits} = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [connectedAccounts, setConnectedAccounts] = useState([]);

  const video = location.state?.video;

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!user) return;
      try {
        const response = await axios.post(`${serverbaseURL}get_user_details`, {
          uid: user.uid
        });
        setUserDetails(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [user]);

  useEffect(() => {
    const fetchConnectedAccounts = async () => {
      if (!user) return;
      
      try {
        const response = await axios.post(`${serverbaseURL}fetch-accounts`, {
          uid: user.uid
        });
        setConnectedAccounts(response.data.accounts || []);
      } catch (error) {
        console.error('Error fetching connected accounts:', error);
        toast.error('Failed to fetch connected accounts');
      }
    };

    fetchConnectedAccounts();
  }, [user]);

  const planLimits = {
    free: 0,
    starter: 2,
    daily: 4,
    hardcore: 7
  };

  const userPlan = userDetails?.plan || 'free';
  const maxDays = planLimits[userPlan];
  const templateCost = video?.templateCost || 1;
  const possiblePosts = Math.floor(userCredits / templateCost);
  const lastPostDate = new Date();
  lastPostDate.setDate(lastPostDate.getDate() + Math.floor(possiblePosts / (selectedDays.length || 1)) * 7);

  const daysOfWeek = [
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
    { id: 0, name: 'Sunday' }
  ];

  const handleSubmit = async () => {
    if (!video.flowData) {
      alert('Cannot setup autoposting for this template at the moment');
      return;
    }

    if (!selectedTime || selectedDays.length === 0 || selectedAccounts.length === 0 || !title || !description) {
      toast.error('Please fill in all required fields');
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(`${serverbaseURL}create-series`, {
        uid: user.uid,
        templateId: video.templateId,
        videoUrl: video.videoUrl,
        flowData: video.flowData,
        title,
        description,
        days: selectedDays,
        time: selectedTime,
        accountIds: selectedAccounts,
        templateCost
      });

      if (response.data.success) {
        toast.success('Automation series created successfully!');
        navigate('/my-generations');
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to create automation series');
    } finally {
      setIsLoading(false);
    }
  };

  if (userPlan === 'free') {
    return (
      <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Upgrade Required</h2>
          <p className="text-gray-600 mb-6">
            Automation features are available on paid plans only. Upgrade to access this feature.
          </p>
          <div className="flex justify-end gap-4">
            <button
              onClick={() => navigate('/my-generations')}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            >
              Go Back
            </button>
            <button
              onClick={() => navigate('/pricing')}
              className="px-4 py-2 bg-gradient-to-r from-[#805af5] to-blue-600 text-white rounded"
            >
              Upgrade Now
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <button
          onClick={() => navigate('/my-generations')}
          className="flex items-center text-gray-600 hover:text-gray-900 mb-6"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to My Generations
        </button>

        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="flex items-center gap-3 mb-8">
            <Repeat className="w-8 h-8 text-purple-600" />
            <h1 className="text-3xl font-bold text-gray-900">Setup Content Automation</h1>
          </div>

          <div className="space-y-8">
            {/* Video Preview Section */}
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                <Video className="w-5 h-5 mr-2 text-gray-700" />
                Selected Video
              </h3>
              <p className="text-gray-700">{video?.topic}</p>
            </div>

            {/* Post Details Section */}
            <div className="space-y-6">
              <h3 className="text-lg font-semibold text-gray-900 flex items-center">
                <Type className="w-5 h-5 mr-2" />
                Post Details
              </h3>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Post Title
                </label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  placeholder="Enter post title"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Post Description
                </label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={4}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  placeholder="Enter post description"
                />
              </div>
            </div>

            {/* Schedule Section */}
            <div className="space-y-6">
              <h3 className="text-lg font-semibold text-gray-900 flex items-center">
                <Calendar className="w-5 h-5 mr-2" />
                Schedule Settings
              </h3>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select Days (Max {maxDays})
                </label>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                  {daysOfWeek.map((day) => (
                    <button
                      key={day.id}
                      onClick={() => {
                        if (selectedDays.includes(day.id)) {
                          setSelectedDays(selectedDays.filter(d => d !== day.id));
                        } else if (selectedDays.length < maxDays) {
                          setSelectedDays([...selectedDays, day.id]);
                        }
                      }}
                      className={`p-3 rounded-lg transition-all duration-200 ${
                        selectedDays.includes(day.id)
                          ? 'bg-purple-600 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      } ${selectedDays.length >= maxDays && !selectedDays.includes(day.id) ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={selectedDays.length >= maxDays && !selectedDays.includes(day.id)}
                    >
                      {day.name}
                    </button>
                  ))}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select Time (UTC)
                </label>
                <input
                  type="time"
                  value={selectedTime}
                  onChange={(e) => setSelectedTime(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>
            </div>

            {/* Social Accounts Section */}
            <div className="space-y-6">
              <h3 className="text-lg font-semibold text-gray-900 flex items-center">
                <Share2 className="w-5 h-5 mr-2" />
                Social Accounts
              </h3>
              
              <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                {connectedAccounts.map((account) => (
                  <button
                    key={account.id}
                    onClick={() => {
                      if (selectedAccounts.includes(account.id)) {
                        setSelectedAccounts(selectedAccounts.filter(id => id !== account.id));
                      } else {
                        setSelectedAccounts([...selectedAccounts, account.id]);
                      }
                    }}
                    className={`p-4 rounded-lg border-2 transition-all duration-200 ${
                      selectedAccounts.includes(account.id)
                        ? 'border-purple-600 bg-purple-50'
                        : 'border-gray-200 hover:border-purple-300'
                    }`}
                  >
                    <p className="font-medium">{account.name}</p>
                    <p className="text-sm text-gray-500 capitalize">
                      {account.type.split('.').pop()}
                    </p>
                  </button>
                ))}
                {connectedAccounts.length === 0 && (
                  <div className="col-span-full text-center py-4 text-gray-500">
                    No social accounts connected yet
                  </div>
                )}
              </div>
            </div>

            {/* Summary Section */}
            {selectedDays.length > 0 && (
              <div className="bg-gray-50 p-6 rounded-lg space-y-2">
                <p className="text-gray-700">Posts possible with current credits: {possiblePosts}</p>
                <p className="text-gray-700">Last post will be on: {lastPostDate.toLocaleDateString()}</p>
              </div>
            )}

            {/* Action Buttons */}
            <div className="flex justify-end gap-4 pt-6">
              <button
                onClick={() => navigate('/my-generations')}
                className="px-6 py-3 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors duration-200"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={isLoading}
                className="px-6 py-3 bg-gradient-to-r from-[#805af5] to-blue-600 text-white rounded-lg hover:shadow-lg transition-all duration-200 flex items-center gap-2"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="w-5 h-5 animate-spin" />
                    Setting up...
                  </>
                ) : (
                  <>
                    <Repeat className="w-5 h-5" />
                    Setup Automation
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupAutomation; 