import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../provider/AuthProvider";
import axios from "axios";
import { serverbaseURL } from "../constant/index";
import { narrationOptions } from "../constant/index.jsx";
import { motion } from 'framer-motion';
import { FaCalendar, FaVideo, FaExternalLink, FaShareAlt, FaSpinner, FaYoutube, FaTwitter, FaPinterest, FaLinkedin, FaInstagram, FaFacebook, FaReddit, FaButterfly } from 'react-icons/fa';
import { SiThreads, SiMastodon } from 'react-icons/si';
import { toast } from 'react-toastify';
import { div } from "framer-motion/client";


const View = () => {
  const { user, userPlan, setLoading } = useContext(AuthContext);
  const [seriesData, setSeriesData] = useState([]);
  const [googleId, setGoogleId] = useState("");
  const [taskId, setTaskId] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const useruid = user?.uid;

  console.log('series data', seriesData);
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.post(`${serverbaseURL}get_user_details`, {
          uid: useruid
        });
        setIsAdmin(response.data.isAdmin);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [useruid]);

  useEffect(() => {
    const fetchSeriesData = async () => {
      setLoading(true); // Start loading
      try {
        const response = await axios.post(`${serverbaseURL}template_videos`, {
          uid: useruid, // Send uid in the body for POST request
        });

        // Check if the response contains the data array
        if (response?.data?.data?.length > 0) {
          setSeriesData(response.data.data); // Access the actual array inside response.data
        } else {
          console.log("No series data available");
        }
      } catch (error) {
        console.error("Error fetching series data:", error);
      } finally {
        //   if(seriesData.length == 0) {
        //     seriesData.push( {
        //       "_id": "6437a8f9c2e1f23d4b5e6f7a",
        //       "channelType": "History",
        //       "imageGenPrompt": "What is your favorite image?",
        //       "storyPrompt": "What is your favorite movie?",
        //       "imageGenTool": "Midjourney",
        //       "voiceModel": "Default",
        //       "imageSlideCount": 1,
        //       "whereToPost": "email",
        //       "content": "Introduction to JavaScript",
        //       "language": "English",
        //       "generations": 1
        //   },{
        //     "_id": "6437a8f9c2e1f23d12317a",
        //     "channelType": "History",
        //     "imageGenPrompt": "What is your favorite image?",
        //     "storyPrompt": "What is your favorite movie?",
        //     "imageGenTool": "Midjourney",
        //     "voiceModel": "Default",
        //     "imageSlideCount": 1,
        //       "whereToPost": "email",
        //     "content": "Introduction to JavaScript",
        //     "language": "English",
        //     "generations": 4
        // });
        //   }
        setLoading(false);
      }
    };
    fetchSeriesData();
  }, [useruid]);

  const handleSchedulePost = (videoUrl, topic) => {
    navigate('/schedulePost', { state: { videoUrl, topic } });
  };
  // Function to find the narrator name by ID
  const getNarratorNameById = (id) => {
    const narrator = narrationOptions.find((option) => option.id === id);
    return narrator ? narrator.name : "Unknown Narrator";
  };

  const handlePostToYoutube = async (item) => {
    try {
      console.log('handlePostToYoutube hit');
      console.log(useruid);
      console.log("hi");
      console.log(item);
      const response = await axios.post(`${serverbaseURL}post_to_youtube`, {
        uid: useruid,
        itemToPost: item
      });

      console.log('video posting response', response)
    } catch (error) {
      console.log('video posting response error', error)

    }
  }
  const handleAdmin = async (item) => {
    navigate("/dashboard/admin-dashboard", { state: { item } });
  };

  const handleConnectYoutube = (item) => {
    sessionStorage.setItem("taskId", item._id);
    window.location.href = `${serverbaseURL}connect_youtube`;
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const updateGoogleIdInDB = async () => {
      // Extracting googleId from URL
      const params = new URLSearchParams(window.location.search);
      const googleIdFromUrl = params.get("googleId");
      if (googleIdFromUrl) {
        setGoogleId(googleIdFromUrl);
        window.history.replaceState(null, null, window.location.pathname);
      }

      const taskIdFromStorage = sessionStorage.getItem("taskId");
      if (taskIdFromStorage) {
        setTaskId(taskIdFromStorage);
      }

      if (googleIdFromUrl && taskIdFromStorage) {
        try {
          const response = await axios.patch(
            `${import.meta.env.VITE_BACKEND
            }/googleId?taskId=${taskIdFromStorage}`,
            {
              googleId: googleIdFromUrl,
            }
          );
        } catch (error) {
          console.error("Error making PATCH request:", error);
        }
      }
    };
    updateGoogleIdInDB();
  }, []);

  const handleScheduleVideo = async (item) => {
    if (!googleId) {
      toast.warning("Connect a google account for the series.");
      return;
    }
    try {
      const response = await axios.post(`${serverbaseURL}get_Generations`, {
        email: userPlan?.email,
        seriesId: item._id,
        postADay: 1,
        googleId,
      });
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.toString());
      console.error(error);
    }
  };
  console.log('s', seriesData.length);
  const handleEditVideo = (item) => {
    navigate("/dashboard/edit-video", { state: { item } });
  };

  const handleViewGenerations = (item) => {
    navigate("/dashboard/viewGenerations", { state: { item } });
  };
  return (
    <div className="  min-h-screen w-full pt-4 bg-gradient-to-b from-white via-[#f1f5f9] to-[#e2e8f0]
">
      <div className="max-w-6xl  mx-auto p-8">
        <div className="flex justify-between items-center pb-3">
          <h1 className="text-2xl font-bold text-black">YOUR CHANNELS</h1>
          <Link to="/create">
            <button className="bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-5 rounded-md text-lg mr-11 font-semibold hover:shadow-lg transition duration-200">
              ADD CHANNEL
            </button>
          </Link>
        </div>
        <div className="pb-10">
          <hr className="h-[2px] bg-gray-600 " />
        </div>

        {seriesData.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-[#805af5] rounded-lg shadow-md overflow-hidden">
              <thead>
                <tr className="bg-[#805af5] text-white">
                  <th className="py-3 px-6 text-start font-semibold text-sm uppercase tracking-wider">
                    Topic
                  </th>
                  <th className="py-3 px-6 text-left font-semibold text-sm uppercase tracking-wider">
                    Language
                  </th>
                  {/* <th className="py-3 px-6 text-left font-semibold text-sm  uppercase tracking-wider">
                    Narrator
                  </th> */}
                  {/* <th className="py-3 px-6 text-left font-semibold text-sm  uppercase tracking-wider">
                    Where to Post
                  </th> */}
                  <th className="py-3 px-6 text-center font-semibold text-sm uppercase tracking-wider">
                    Generated
                  </th>
                  <th className="py-3 px-6  mr-6 font-semibold text-sm  uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {seriesData.map((item) => (
                  <tr key={item?._id} className="hover:bg-gray-50">
                    <td className="py-4 px-6  text-gray-700 text-sm">
                      {item?.topic || "N/A"} {/* Assuming `content` is the topic */}
                    </td>
                    <td className="py-4 px-6  text-gray-700 text-sm">
                      {item?.language || "English"}
                    </td>
                    {/* <td className="py-4 px-6  text-gray-700 text-sm">
                      {getNarratorNameById(item?.narrator)} 
                    </td> */}
                    {/* <td className="py-4 px-6  text-gray-700 text-sm">
                      {item?.whereToPost || "N/A"}
                    </td> */}
                    <td className="py-4 px-6 text-center  text-gray-700 text-sm">
                      {item?.generations || 1} {/* Assuming `generations` is the generated videos count */}
                    </td>
                    <td className="py-3 px-4 sm:px-6  text-gray-700 text-xs sm:text-sm">
                      <div className=" flex flex-col justify-center space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
                        {/* <button
                           className=" bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-4 rounded-md text-sm font-semibold hover:shadow-lg transition duration-200"
                          onClick={() => handlePostToYoutube(item)}
                        >
                          Post
                        </button> */}
                        {isAdmin && ( // Check if the user is an admin
                          <button
                            className=" bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-4 rounded-md text-sm font-semibold hover:shadow-lg transition duration-200"
                            onClick={() => handleAdmin(item)}
                          >
                            Admin Dashboard
                          </button>
                        )}
                        {/* <button
                           className=" bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-4 rounded-md text-sm font-semibold hover:shadow-lg transition duration-200"
                          onClick={() => handleConnectYoutube(item)}
                        >
                          Youtube
                        </button> */}
                        <button
                          className=" bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-4 rounded-md text-sm font-semibold hover:shadow-lg transition duration-200"
                          onClick={() => handleViewGenerations(item)}
                        >
                          Generations
                        </button>
                        {/* <button
                           className="bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-4 rounded-md text-sm font-semibold hover:shadow-lg transition duration-200"
                          onClick={() => handleEditVideo(item)}
                        >
                          Edit / Stop
                        </button> */}
                        <button
                          className="inline-flex items-center rounded-lg gap-2 bg-gradient-to-r from-[#805af5] to-blue-600 text-white px-4 py-2 rounded-lghover:shadow-lg transition duration-200"
                          onClick={() => handleSchedulePost(item.videoUrl, item.topic)}
                        >
                          <FaShareAlt className="w-4 h-4" />
                          Schedule Post
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>

            </table>
          </div>
        ) : (
          <div>
          <motion.div
            className="p-8 w-full md:px-16 shadow-xl rounded-lg bg-[#805af5]"
            initial={{ opacity: 0, x: -1000, scale: 0.95 }}
            animate={{ opacity: 1, x: 0, scale: 1 }}
            transition={{
              duration: 0.4,
              ease: 'easeInOut',
              delay: 0.1,
            }}
          >
            <p className="text-white text-xl ml-6  mt-3 font-bold pb-3">
              You haven't started a Faceless Video channel yet.
            </p>
            <Link to="/dashboard/create" >
              <button className=" text-white py-3 px-6 text-lg font-semibold mb-5">
                CREATE YOUR CHANNEL
              </button>
            </Link>
          </motion.div>
          <motion.div
            className="p-8 w-full md:px-16 shadow-xl rounded-lg bg-[#805af5] mt-5"
            initial={{ opacity: 0, x: -1000, scale: 0.95 }}
            animate={{ opacity: 1, x: 0, scale: 1 }}
            transition={{
              duration: 0.4,
              ease: 'easeInOut',
              delay: 0.1,
            }}
          >
            <div className="mt-5">
              <h2 className="text-white text-lg font-semibold mb-2">Supported Platforms:</h2>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center">
                  <FaYoutube className="w-6 h-6 text-white mr-2" />
                  <span className="text-white">YouTube Channels</span>
                </div>
                <div className="flex items-center">
                  <FaTwitter className="w-6 h-6 text-white mr-2" />
                  <span className="text-white">Twitter</span>
                </div>
                <div className="flex items-center">
                  <FaPinterest className="w-6 h-6 text-white mr-2" />
                  <span className="text-white">Pinterest</span>
                </div>
                <div className="flex items-center">
                  <FaLinkedin className="w-6 h-6 text-white mr-2" />
                  <span className="text-white">LinkedIn</span>
                </div>
                <div className="flex items-center">
                  <FaTwitter className="w-6 h-6 text-white mr-2" />
                  <span className="text-white">Bluesky</span>
                </div>
                <div className="flex items-center">
                  <FaInstagram className="w-6 h-6 text-white mr-2" />
                  <span className="text-white">Instagram</span>
                </div>
                <div className="flex items-center">
                  <SiThreads className="w-6 h-6 text-white mr-2" />
                  <span className="text-white">Threads</span>
                </div>
                <div className="flex items-center">
                  <SiMastodon className="w-6 h-6 text-white mr-2" />
                  <span className="text-white">Mastodon</span>
                </div>
                <div className="flex items-center">
                  <FaFacebook className="w-6 h-6 text-white mr-2" />
                  <span className="text-white">Facebook</span>
                </div>
                <div className="flex items-center">
                  <FaReddit className="w-6 h-6 text-white mr-2" />
                  <span className="text-white">Reddit</span>
                </div>
              </div>
            </div>
          </motion.div>
          </div>
        )}
      </div>
    </div>
  );
};

export default View;
