import React from 'react';
import { Button } from './ui/Button';
import { Plus, Save, Upload, Play } from 'lucide-react';
import axios from 'axios';
import { useFlowStore } from '../store/flowStore';
import { serverbaseURL } from '../constant/index';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { FlowManagementDialog } from './FlowManagementDialog';
import { useContext } from 'react';
import { AuthContext } from "../provider/AuthProvider";
import { Spinner } from './ui/Spinner';
import { ToastContainer } from 'react-toastify';

export function Header({ onAddNode }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState('save');
  const { nodes, edges, setNodes, setEdges, setNodeOutput, updateNodeProperties, getNodeOutput } = useFlowStore();
  const location = useLocation();
  const isEditMode = location.state?.mode === 'edit';
  const { user } = useContext(AuthContext);
  const [isRunning, setIsRunning] = useState(false);

  const handleUpdate = async () => {
    try {
      // Get the template data from localStorage
      const editTemplate = JSON.parse(localStorage.getItem('editTemplate'));
      
      if (!editTemplate?.templateId) {
        toast.error('Template ID not found', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }

      const flowData = {
        nodes,
        edges,
      };

      const response = await axios.put(`${serverbaseURL}api/update-user-flow`, {
        templateId: editTemplate.templateId,
        name: editTemplate.name,
        data: {
          data: flowData
        }
      });

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        // Optionally, update localStorage with the new data
        localStorage.setItem('editTemplate', JSON.stringify({
          ...editTemplate,
          data: {
            data: flowData
          }
        }));
      } else {
        toast.error(response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error('Error updating flow:', error);
      toast.error('Failed to update flow: ' + (error.response?.data?.error || error.message), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleSaveFlow = async (flowName) => {
    if (!flowName) return;

    const flowData = {
      name: flowName,
      data: {
        nodes,
        edges
      }
    };

    try {
      await axios.post(`${serverbaseURL}api/users-flows`, {
        flowData,
        uid: user.uid,
        name: flowName
      });
      setDialogOpen(false);
      toast.success(`Flow "${flowName}" saved successfully!`);
    } catch (error) {
      console.error('Error saving flow:', error);
      toast.error(`Failed to save flow: ${error.message}`);
    }
  };

  const handleImportFlow = (flow) => {
    if (!flow.data || !flow.data.data.nodes || !flow.data.data.edges) {
      toast.error('Invalid flow data structure');
      return;
    }

    setNodes(flow.data.data.nodes);
    setEdges(flow.data.data.edges);
    setDialogOpen(false);
    toast.success(`Flow "${flow.name}" imported successfully!`);
  };

  const processNode = async (node) => {
    try {
      console.log('Processing node:', {
        id: node.id,
        type: node.data.type,
        name: node.data.name,
        properties: node.data.properties
      });

      const inputEdges = edges.filter(edge => edge.target === node.id);
      const inputNodes = inputEdges.map(edge =>
        nodes.find(n => n.id === edge.source)
      );
      const inputResults = await Promise.all(
        inputNodes.map(async (inputNode) => {
          if (!getNodeOutput(inputNode.data.name)) {
            await processNode(inputNode);
          }
          return getNodeOutput(inputNode.data.name);
        })
      );

      let response;
      switch (node.data.type) {
        case 'prompt-input':
          const scenes = node.data.properties.scenes || [];
          const output = scenes.map((scene, index) => ({
            prompt: scene.prompt,
          }));
          setNodeOutput(node.id, output);
          response = { data: output };
          break;

        case 'video-composition':
          // Process imageSource placeholder
          let processedImageSource = node.data.properties.imageSource;
          if (processedImageSource) {
            const placeholderRegex = /{([^}]+)\.output}/g;
            processedImageSource = processedImageSource.replace(placeholderRegex, (match, nodeName) => {
              const sourceNode = nodes.find(n => n.data.name === nodeName);
              if (!sourceNode) {
                console.warn(`Node "${nodeName}" not found`);
                return match;
              }
              const output = getNodeOutput(nodeName);
              return JSON.stringify(output) || match;
            });
          }

          // Process audioSource placeholder
          let processedAudioSource = node.data.properties.audioSource;
          if (processedAudioSource) {
            const placeholderRegex = /{([^}]+)\.output}/g;
            processedAudioSource = processedAudioSource.replace(placeholderRegex, (match, nodeName) => {
              const sourceNode = nodes.find(n => n.data.name === nodeName);
              if (!sourceNode) {
                console.warn(`Node "${nodeName}" not found`);
                return match;
              }
              const output = getNodeOutput(nodeName);
              return JSON.stringify(output) || match;
            });
          }

          const imageOutput = processedImageSource
            ? JSON.parse(processedImageSource)
            : getNodeOutput(inputNodes.find(n => n.data.type === 'image-generation')?.data.name);

          const audioOutput = processedAudioSource
            ? JSON.parse(processedAudioSource)
            : getNodeOutput(inputNodes.find(n => n.data.type === 'audio-generation')?.data.name);

          if (!imageOutput || !audioOutput) {
            throw new Error('Video composition requires both image and audio inputs');
          }

          const imageUrls = Array.isArray(imageOutput) && imageOutput[0]?.imageUrls
            ? imageOutput[0].imageUrls
            : (Array.isArray(imageOutput) ? imageOutput : [imageOutput]);

          const videoPayload = {
            imageSources: imageUrls,
            audioSources: {
              audioUrl: audioOutput.audioUrl,
              audioDuration: audioOutput.duration || 30.00,
              type: audioOutput.type,
            },
            height: node.data.properties.height || 720,
            width: node.data.properties.width || 1280,
            topic: node.data.properties.topic || 'flow'
          };

          console.log('Sending video composition request:', videoPayload);

          response = await axios.post(`${serverbaseURL}create-video-with-subtitles`, videoPayload);
          if (response.data?.videoUrl) {
            response.data = {
              url: response.data.videoUrl,
              type: 'video'
            };
          }
          break;

        case 'text-generation':
          let processedPrompt = node.data.properties.prompt;
          if (processedPrompt) {
            const placeholderRegex = /{([^}]+)\.output}/g;
            processedPrompt = processedPrompt.replace(placeholderRegex, (match, nodeName) => {
              const sourceNode = nodes.find(n => n.data.name === nodeName);
              if (!sourceNode) {
                console.warn(`Node "${nodeName}" not found`);
                return match;
              }

              const output = getNodeOutput(nodeName);
              console.log('Using output from node:', {
                nodeName,
                output
              });
              return output || match;
            });
          }

          response = await axios.post(`${serverbaseURL}chat-completion`, {
            ...node.data.properties,
            prompt: processedPrompt,
            nodeId: node.id,
            nodeType: node.data.type
          });
          break;

        case 'image-generation':
          try {
            let processedPrompt = node.data.properties.prompt;
            if (processedPrompt) {
              const placeholderRegex = /{([^}]+)\.output}/g;
              processedPrompt = processedPrompt.replace(placeholderRegex, (match, nodeName) => {
                const sourceNode = nodes.find(n => n.data.name === nodeName);
                if (!sourceNode) {
                  console.warn(`Node "${nodeName}" not found`);
                  return match;
                }

                const output = getNodeOutput(nodeName);
                // Handle the case where output is already an object
                if (typeof output === 'object') {
                  return JSON.stringify(output);
                }
                return output || match;
              });
            }

            let imagePrompts;
            const promptText = processedPrompt.trim();

            // Handle different input formats
            if (typeof promptText === 'string') {
              if (promptText.startsWith('[')) {
                try {
                  imagePrompts = JSON.parse(promptText);
                } catch (parseError) {
                  console.error('JSON Parse Error:', parseError);
                  console.log('Invalid JSON string:', promptText);
                  throw new Error('Failed to parse prompt JSON: ' + parseError.message);
                }
              } else {
                imagePrompts = [{ prompt: promptText }];
              }
            } else if (Array.isArray(promptText)) {
              imagePrompts = promptText;
            } else if (typeof promptText === 'object') {
              imagePrompts = [promptText];
            } else {
              throw new Error('Invalid prompt format');
            }

            // Ensure all prompts have the correct structure
            imagePrompts = imagePrompts.map(item => {
              if (typeof item === 'string') {
                return { prompt: item };
              }
              return item;
            });

            console.log('Sending to Leonardo API:', imagePrompts);
            response = await axios.post(`${serverbaseURL}generate-images-leonardo`, imagePrompts);
          } catch (error) {
            console.error('Error processing image prompts:', error);
            throw new Error(`Failed to process image prompts: ${error.message}`);
          }
          break;

        case 'audio-generation':
          let processedProperties = { ...node.data.properties };
          if (processedProperties.text) {
            const placeholderRegex = /{([^}]+)\.output}/g;
            processedProperties.text = processedProperties.text.replace(placeholderRegex, (match, nodeName) => {
              const sourceNode = nodes.find(n => n.data.name === nodeName);
              if (!sourceNode) {
                console.warn(`Node "${nodeName}" not found`);
                return match;
              }

              const output = getNodeOutput(nodeName);
              return output || match;
            });
          }

          response = await axios.post(`${serverbaseURL}audio-generation`, {
            ...processedProperties,
            nodeId: node.id,
            nodeType: node.data.type
          });
          break;

        case 'video-generation':
          try {
            const mode = node.data.properties.mode;
            let payload;

            if (mode === 'Text to Video') {
              const textPrompts = node.data.properties.textPrompts.split('\n')
                .filter(prompt => prompt.trim())
                .map(prompt => ({ prompt: prompt.trim() }));

              payload = {
                mode: 'text',
                prompts: textPrompts,
                ratio: node.data.properties.ratio,
                length: node.data.properties.length
              };
            } else {
              const imagePrompts = node.data.properties.imagePrompts.split('\n')
                .filter(prompt => prompt.trim())
                .map(prompt => ({ prompt: prompt.trim() }));

              const imageUrls = node.data.properties.imageUrls.split('\n')
                .filter(url => url.trim());

              if (imagePrompts.length !== imageUrls.length) {
                throw new Error('Number of prompts must match number of images');
              }

              payload = {
                mode: 'image',
                prompts: imagePrompts,
                images: imageUrls,
                ratio: node.data.properties.ratio,
                length: node.data.properties.length
              };
            }

            response = await axios.post(`${serverbaseURL}video-generation`, payload);
            break;
          } catch (error) {
            console.error('Video Generation Error:', error);
            throw error;
          }

        case 'text-to-video':
          let processedPrompts = node.data.properties.prompts;
          if (processedPrompts) {
            const placeholderRegex = /{([^}]+)\.output}/g;
            processedPrompts = processedPrompts.replace(placeholderRegex, (match, nodeName) => {
              const sourceNode = nodes.find(n => n.data.name === nodeName);
              if (!sourceNode) {
                console.warn(`Node "${nodeName}" not found`);
                return match;
              }
              const output = getNodeOutput(nodeName);
              if (Array.isArray(output)) {
                return output.map(item => item.prompt).join('\n');
              }
              return output || match;
            });
          }

          const textPrompts = processedPrompts.split('\n')
            .filter(prompt => prompt.trim())
            .map(prompt => ({ prompt: prompt.trim() }));

          response = await axios.post(`${serverbaseURL}api/generate-prompt-videos`, {
            prompts: textPrompts
          });

          // Convert single URL to array format for video composition node
          response.data = [response.data];
          
          updateNodeProperties(node.id, {
            ...node.data.properties,
            output: response.data
          });
          setNodeOutput(node.id, response.data);
          break;

        case 'image-prompt-video':
          let processedImagePrompts = node.data.properties.imagePrompts;
          let processedVideoPrompts = node.data.properties.videoPrompts;

          if (processedImagePrompts) {
            const placeholderRegex = /{([^}]+)\.output}/g;
            processedImagePrompts = processedImagePrompts.replace(placeholderRegex, (match, nodeName) => {
              const sourceNode = nodes.find(n => n.data.name === nodeName);
              if (!sourceNode) return match;
              const output = getNodeOutput(nodeName);
              if (Array.isArray(output)) {
                return JSON.stringify(output, null, 2);
              }
              return output || match;
            });
          }

          if (processedVideoPrompts) {
            const placeholderRegex = /{([^}]+)\.output}/g;
            processedVideoPrompts = processedVideoPrompts.replace(placeholderRegex, (match, nodeName) => {
              const sourceNode = nodes.find(n => n.data.name === nodeName);
              if (!sourceNode) return match;
              const output = getNodeOutput(nodeName);
              if (Array.isArray(output)) {
                return output.map(item => item.prompt).join('\n');
              }
              return output || match;
            });
          }

          const imgPrompts = JSON.parse(processedImagePrompts);
          const videoPrompts = processedVideoPrompts.split('\n')
            .filter(prompt => prompt.trim())
            .map(prompt => ({ prompt: prompt.trim() }));

          if (imgPrompts.length !== videoPrompts.length) {
            throw new Error('Number of image prompts must match number of video prompts');
          }

          response = await axios.post(`${serverbaseURL}api/generate-kling-video`, {
            imgPrompts,
            videoPrompts,
            topic: node.data.properties.topic || 'flow',
            height: node.data.properties.height || 720,
            width: node.data.properties.width || 1280
          });

          // Convert single URL to array format for video composition node
          response.data = [response.data];

          updateNodeProperties(node.id, {
            ...node.data.properties,
            output: response.data
          });
          setNodeOutput(node.id, response.data);
          break;

        default:
          throw new Error(`Unsupported node type: ${node.data.type}`);
      }

      console.log('API Response:', response.data);

      const result = response.data;
      updateNodeProperties(node.id, {
        ...node.data.properties,
        output: result
      });
      setNodeOutput(node.id, result);
      return result;

    } catch (error) {
      console.error(`Error processing node ${node.id}:`, error);
      setNodeOutput(node.id, { error: error.message });
      throw error;
    }
  };

  const handleRun = async () => {
    if (nodes.length === 0) {
      toast.warning('Please add at least one node to the canvas before running the flow.');
      return;
    }

    const nodeWithBlankPrompt = nodes.find(node => {
      const properties = node.data.properties || {};
      if (['text-generation', 'image-generation'].includes(node.data.type)) {
        return !properties.prompt || properties.prompt.trim() === '';
      }
      if (node.data.type === 'audio-generation') {
        return !properties.text || properties.text.trim() === '';
      }
      return false;
    });

    if (nodeWithBlankPrompt) {
      toast.warning(`Please fill in the required prompt for node "${nodeWithBlankPrompt.data.name || 'Unnamed Node'}"`);
      return;
    }

    setIsRunning(true);
    try {
      const orderedNodes = [...nodes];
      for (const node of orderedNodes) {
        try {
          await processNode(node);
        } catch (error) {
          console.error(`Error processing node ${node.id}:`, error);
          toast.error(`Error processing node "${node.data.name || 'Unnamed Node'}": ${error.message}`);
          break;
        }
      }
    } catch (error) {
      console.error('Error running flow:', error);
    } finally {
      setIsRunning(false);
    }
  };

  return (
    <>
      <div className="p-4 bg-white border-b border-gray-200 flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h1 className="text-xl font-semibold">GLIF Builder</h1>
          <Button
            onClick={handleRun}
            className="bg-black hover:bg-gray-800 min-w-[120px]"
            disabled={isRunning}
          >
            {isRunning ? (
              <>
                <Spinner />
                Processing...
              </>
            ) : (
              <>
                <Play className="w-4 h-4 mr-2" />
                Run Flow
              </>
            )}
          </Button>
        </div>
        <div className="flex items-center gap-2">
          {isEditMode ? (
            <Button 
              onClick={handleUpdate}
              className="flex items-center gap-2"
            >
              <Save className="w-4 h-4" />
              Update
            </Button>
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  setDialogMode('save');
                  setDialogOpen(true);
                }}
              >
                <Save className="w-4 h-4 mr-2" />
                Save Flow
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setDialogMode('import');
                  setDialogOpen(true);
                }}
              >
                <Upload className="w-4 h-4 mr-2" />
                Import Flow
              </Button>
            </>
          )}
          <Button onClick={onAddNode}>
            <Plus className="w-4 h-4 mr-2" />
            Add Node
          </Button>
        </div>
      </div>

      <FlowManagementDialog
        isOpen={dialogOpen}
        mode={dialogMode}
        onClose={(flowName) => {
          if (flowName) {
            handleSaveFlow(flowName);
          }
          setDialogOpen(false);
        }}
        onImport={handleImportFlow}
      />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}