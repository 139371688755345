import React, { useState } from 'react';
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FlowCanvas } from './components/FlowCanvas';
import { Header } from './components/Header';
import { NodeSequence } from './components/NodeSequence';
import { NodeTypeSelector } from './components/NodeTypeSelector';
import { useFlowStore } from './store/flowStore';

function Flo({ mode = 'edit' }) {
  const [isAddNodeOpen, setIsAddNodeOpen] = useState(false);
  const addNewNode = useFlowStore((state) => state.addNewNode);

  const handleAddNode = (type) => {
    addNewNode(type, { x: 100, y: 100 });
    setIsAddNodeOpen(false);
  };

  return (
    <ReactFlowProvider>
      <div className="flex h-screen bg-gray-100">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        
        <NodeSequence mode={mode} />
        <div className="flex-1 flex flex-col">
          {mode === 'edit' && (
            <Header onAddNode={() => setIsAddNodeOpen(true)} />
          )}
          <div className="flex-1 relative">
            <FlowCanvas mode={mode} />
          </div>
        </div>

        {mode === 'edit' && (
          <NodeTypeSelector
            isOpen={isAddNodeOpen}
            onClose={() => setIsAddNodeOpen(false)}
            onSelect={handleAddNode}
          />
        )}
      </div>
    </ReactFlowProvider>
  );
}

export default Flo;