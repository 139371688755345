import { useContext, useEffect, useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../provider/AuthProvider";
import logo from "../assets/logo.png";
import { motion } from "framer-motion";
import { Wallet, X, Plus } from "lucide-react";

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const { logOut, user, userCredits } = useContext(AuthContext);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [isTopUpOpen, setIsTopUpOpen] = useState(false);

  // Scroll to top when the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 40) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
      setIsUserDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTopUpClick = () => {
    setIsTopUpOpen(!isTopUpOpen);
  };

  const handleCloseTopUp = () => {
    setIsTopUpOpen(false);
  };

  return (
    <div className={`w-full px-4 sm:px-10 lg:px-14 ${isScrolled ? 'fixed top-0 left-0 z-50 shadow-lg transition-all duration-300 ease-in-out bg-white border-b-2 border-gray-300' : ''
      }`}>
      <div className={`relative flex justify-between items-center py-2 transition-all duration-300 ease-in-out ${isScrolled ? 'bg-white' : 'bg-light-blue-100'
        }`}>
        {/* Mobile Menu Button - Left Corner */}
        <div className="lg:hidden">
          <div
            className="text-xl text-accent border border-accent px-3 py-1.5 rounded-md"
            onClick={toggleDropdown}
          >
            <GiHamburgerMenu />
          </div>
        </div>

        {/* Logo - Centered on Mobile */}
        <Link to="/" className="flex-1 lg:flex-none lg:w-1/3">
          <div className="flex items-center justify-center lg:justify-start gap-2 sm:gap-3">
            <div className="text-4xl sm:text-5xl rounded-full p-2">
              <img src={logo} alt="Logo" className="w-8 sm:w-10 h-auto" />
            </div>
            <h1 className="text-gray-800 text-xl sm:text-2xl font-semibold ">AutoMovieCreator</h1>
          </div>
        </Link>

        {/* Desktop Menu - Hidden on Mobile */}
        <div className="hidden lg:flex w-1/3 justify-center items-center">
          <div className="flex space-x-2 sm:space-x-4 px-4 sm:px-7 py-2">
            {user ? (
              <>
                <motion.div
                  className="group relative inline-block"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  <Link
                    to="/templates"
                    className={`nav-link text-[#55595c] text-lg font-medium p-2 rounded-full  transition-all duration-300 
                      ${location.pathname === '/templates'
                        ? ' bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white'
                        : 'border-transparent group-hover:bg-gray-200'
                      }`}
                  >
                    Create
                  </Link>
                </motion.div>
                <motion.div
                  className="group relative inline-block"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                >
                  <Link
                    to="/dashboards"
                    className={`nav-link text-[#55595c] text-lg font-medium p-2 rounded-full transition-all duration-300 
                      ${location.pathname === '/dashboards'
                        ? ' bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white'
                        : 'border-transparent group-hover:bg-gray-200'
                      }`}
                  >
                    Distribute
                  </Link>
                </motion.div>
                {/* <motion.div
                  className="group relative inline-block"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.6 }}
                >
                  <Link
                    to="/marketplace"
                    className="text-[#55595c] text-lg font-medium p-2 rounded-full border-2 border-transparent transition-all duration-300 group-hover:bg-gray-200"
                  >
                    Marketplace
                  </Link>
                </motion.div> */}
                <motion.div
                  className="group relative inline-block"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.8 }}
                >
                  <Link
                    to="/affiliate"
                    className={`nav-link text-[#55595c] text-lg font-medium p-2 rounded-full transition-all duration-300 
                      ${location.pathname === '/affiliate'
                        ? ' bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white'
                        : 'border-transparent group-hover:bg-gray-200'
                      }`}
                  >
                    Earn
                  </Link>
                </motion.div>
              </>
            ) : (
              <motion.div
                className="group relative inline-block"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
              >
                <Link
                  to="/login"
                  className="text-blue-700 text-lg font-medium p-2 rounded-full border-transparent transition-all duration-300 group-hover:bg-gray-200"
                >
                  Login
                </Link>
              </motion.div>
            )}
            <motion.div
              className="group relative inline-block"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
            >
              <Link
                to="/pricing"
                className={`nav-link text-[#55595c] text-lg font-medium p-2 rounded-full transition-all duration-300 
                  ${location.pathname === '/pricing'
                    ? ' bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white'
                    : 'border-transparent group-hover:bg-gray-200'
                  }`}
              >
                Pricing
              </Link>
            </motion.div>
          </div>
        </div>

        {/* User Info - Modified for Mobile */}
        {user && (
          <div className="flex items-center gap-2 lg:w-1/3 justify-end">
            <div className="flex items-center gap-2 bg-gradient-to-r from-[#805af5] to-[#cd99ff] text-white rounded-full">
              {/* Mobile View */}
              <div className="lg:hidden flex items-center px-2 py-1">
                <Wallet className="h-4 w-4" />
                <span className="font-semibold text-sm">{userCredits}</span>
                <Link to="/pricing">
                  <Plus className="h-4 w-4 ml-1 cursor-pointer" />
                </Link>
              </div>
              {/* Desktop View */}
              <div className="hidden lg:flex items-center gap-2 px-2 py-2">
                <Wallet className="h-4 w-4" />
                <span className="font-semibold text-sm">{userCredits} Credits</span>
                <Link to="/pricing">
                  <Plus className="h-4 w-4 cursor-pointer" />
                </Link>
              </div>
            </div>

            {/* Top Up Modal */}
            {isTopUpOpen && (
              <div className="absolute z-50 bg-white shadow-lg rounded-lg p-4" style={{ top: '100%', left: '50', marginTop: '8px' }}>
                <h3 className="text-lg font-semibold">Top Up</h3>
                <p className="text-sm">Choose an amount to top up your credits.</p>
                <div className="flex gap-2 mt-2">
                  <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={() => {/* Handle top up logic */ }}>
                    50 Credits
                  </button>
                  <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={() => {/* Handle top up logic */ }}>
                    100 Credits
                  </button>
                  <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={() => {/* Handle top up logic */ }}>
                    200 Credits
                  </button>
                </div>
                <button className="mt-4 text-red-500" onClick={handleCloseTopUp}>
                  <X className="h-5 w-5 inline" />
                </button>
              </div>
            )}

            {/* User Email/Dropdown - Hidden on Mobile */}
            <div className="hidden lg:block flex items-center gap-2">
              <motion.div
                className="group relative inline-block"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8 }}
              >
                <div
                  onClick={() => setIsUserDropdownOpen(!isUserDropdownOpen)}
                  className="flex items-center cursor-pointer text-[#55595c] text-xs font-medium p-1 rounded-full border-2 border-transparent hover:bg-gray-100"
                >
                  {user.photoURL ? (
                    <img src={user.photoURL} alt="User Avatar" className="h-6 w-6 rounded-full" />
                  ) : (
                    <div className="h-6 w-6 rounded-full bg-gray-300 flex items-center justify-center">
                      <svg className="h-4 w-4 text-gray-600" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm0 1.5a8.5 8.5 0 110 17 8.5 8.5 0 010-17zm0 3a5.5 5.5 0 100 11 5.5 5.5 0 000-11z" />
                      </svg>
                    </div>
                  )}
                  <span className="whitespace-nowrap overflow-hidden text-ellipsis ml-2 mr-1">{user.displayName}</span>
                  <svg
                    className={`w-4 h-4 transition-transform duration-200 ${isUserDropdownOpen ? 'rotate-180' : ''}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>

                {isUserDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1">
                    <button
                      onClick={() => {
                        logOut(navigate);
                        setIsUserDropdownOpen(false);
                      }}
                      className="w-full flex items-center px-4 py-2 text-sm text-red-600 hover:bg-red-50 transition-colors duration-150"
                    >
                      <svg
                        className="w-4 h-4 mr-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                      </svg>
                      Logout
                    </button>
                  </div>
                )}
              </motion.div>
            </div>
          </div>
        )}

        {/* Mobile Dropdown Menu */}
        {isDropdownOpen && (
          <div className="absolute top-full left-0 w-64 bg-white rounded-lg shadow-lg border border-gray-300 mt-2 z-50">
            <Link to="/pricing">
              <button className="text-[#55595c] w-full text-left px-4 py-3 text-sm md:text-base transition-all duration-300 hover:bg-gray-100 hover:text-black rounded-t-lg">
                Pricing
              </button>
            </Link>
            {/* <Link to="/support">
              <button className="text-[#55595c] w-full text-left px-4 py-3 text-sm md:text-base transition-all duration-300 hover:bg-gray-100 hover:text-black">
                Support
              </button>
            </Link> */}

            {user ? (
              <>
                <Link to="/dashboards">
                  <button
                    className={`nav-link text-[#55595c] w-full text-left px-4 py-3 text-sm md:text-base transition-all duration-300 hover:bg-gray-100 hover:text-black ${location.pathname === '/dashboard' ? 'active' : ''
                      }`}
                  >
                    Distribute
                  </button>
                </Link>
                <Link to="/templates">
                  <button className="text-[#55595c] w-full text-left px-4 py-3 text-sm md:text-base transition-all duration-300 hover:bg-gray-100 hover:text-black">
                    Create
                  </button>
                </Link>
                <Link to="/affiliate">
                  <button className="text-[#55595c] w-full text-left px-4 py-3 text-sm md:text-base transition-all duration-300 hover:bg-gray-100 hover:text-black">
                    Earn
                  </button>
                </Link>
                <div className="text-[#55595c] w-full text-left px-4 py-3 text-sm md:text-base border-t border-gray-100">
                  {user.email}
                </div>
                <button
                  className="text-red-600 w-full text-left px-4 py-3 text-sm md:text-base transition-all duration-300 hover:bg-red-100 hover:text-red-800 rounded-b-lg"
                  onClick={() => {
                    logOut(navigate);
                    setIsDropdownOpen(false);
                  }}
                >
                  Logout
                </button>
              </>
            ) : (
              <Link to="/login">
                <button className="text-blue-700 w-full text-left px-4 py-3 text-sm md:text-base transition-all duration-300 hover:bg-gray-100 hover:text-blue-800 rounded-b-lg">
                  Login
                </button>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;