import React, { useState, useRef, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from "../../provider/AuthProvider";
import { serverbaseURL } from "../../constant/index";
import axios from 'axios';
import { Star, Download, MessageCircle, Play, Pause, Edit3, Video } from 'lucide-react';
import { toast } from 'react-toastify';

export default function TemplateDetail() {
  const { id } = useParams();
  const [isPlaying, setIsPlaying] = useState(false);
  const [rating, setRating] = useState(0);
  const [template, setTemplate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [responseState, setResponseState] = useState({});
  const [reviews, setReviews] = useState([]);
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [reviewText, setReviewText] = useState("");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);


  const { user } = useContext(AuthContext);
  const videoRef = useRef(null);
  const userId = user.uid;
  const userName = user.displayName;
  const navigate = useNavigate();




  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    try {
      // Send POST request to initiate the payment
      const { data } = await axios.post(`${serverbaseURL}purchase-template/${id}`, {
        userId: user.uid,
      });

      // Validate the response to ensure required fields (amount, id) are present
      if (!data.amount || !data.id) {
        toast.error('Payment initiation failed. Missing required data.');
        return;
      }

      // Call Razorpay screen with the amount and id
      handleRazorpayScreen(data.amount, data.id); // Assuming amount is already in paise

    } catch (error) {
      // Handle errors from the payment initiation request
      if (error.response) {
        // Server responded with a status code outside the 2xx range
        console.error('Response error data:', error.response.data);
        console.error('Response error status:', error.response.status);
      } else if (error.request) {
        // Request was made but no response received
        console.error('Request error:', error.request);
      } else {
        // General error
        console.error('General error:', error.message);
      }

      toast.error('Payment initiation failed. Please try again.');
    }
  };

  const handleRazorpayScreen = async (amount, razorpayOrderId) => {
    // Dynamically load the Razorpay SDK script
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      // Handle the failure to load the Razorpay SDK
      toast.error('Failed to load Razorpay SDK. Please try again.');
      return;
    }

    // Ensure the amount is in paise
    const amountInPaise = amount;

    // Configure Razorpay payment options
    const options = {
      key: 'rzp_test_VtHvFJBCk13VCG', // Your Razorpay API key
      amount: amountInPaise, // Amount in paise
      currency: 'INR',
      name: 'AutoMovieCreator',
      description: 'Payment for Template',
      image: 'https://papayacoders.com/demo.png',
      razorpayOrderId: razorpayOrderId, // Razorpay order ID
      handler: async function (response) {
        try {
          // Fetch payment details from the server using the payment ID
          const paymentResponse = await axios.get(`${serverbaseURL}template-payment/${response.razorpay_payment_id}`);

          // Prepare the payment confirmation data
          const confirmationData = {
            amount: amount / 100, // Convert to rupees for confirmation
            paymentId: paymentResponse.data.paymentId,
            method: paymentResponse.data.method,
            razorpayOrderId, // Include razorpayOrderId
            success: true,
            userName: 'Auto Movie Creator',
          };

          // Send confirmation data to the server
          await axios.post(`${serverbaseURL}template-payment-confirmation`, confirmationData);

          // Update state and display modal
          setResponseState(paymentResponse.data);
          setIsModalOpen(true);
        } catch (error) {
          // Handle errors during payment confirmation
          console.error('Error during payment confirmation:', error);

          // Send failure status to the server
          await axios.post(`${serverbaseURL}template-payment-confirmation`, {
            amount: amount / 100, // Convert to rupees
            razorpayOrderId,
            userName: 'Auto Movie Creator',
            success: false,
          });
          toast.error('Payment confirmation failed. Please try again later.');
        }
      },
      prefill: {
        name: 'Auto Movie Creator',
        email: 'tech@automoviecreator.com',
      },
      theme: {
        color: '#F4C430',
      },
    };

    // Create Razorpay payment object and open the payment modal
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };







  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const response = await axios.get(`${serverbaseURL}template/${id}`);
        setTemplate(response.data);
        setRating(response.data.rating || 0);
      } catch (error) {
        console.error('Error fetching template:', error);
        setError('Failed to fetch template. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchTemplate();
  }, [id]);



  const handleContactSeller = () => {
    const sellerEmail = template.email;
    window.open(`mailto:${sellerEmail}`, '_blank'); // Opens the user's email client
  };





  const handleRating = async (newRating, id, userId) => {
    // Optimistically update the rating in the UI first
    setRating(newRating);
    toast.success(`You rated this template ${newRating} star(s)!`);

    // Optimistic UI update: Update template's rating with the new rating immediately
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      rating: newRating, // Temporarily set the rating to the new value
    }));

    try {
      // Send rating to the server
      const response = await axios.post(`${serverbaseURL}add-rating/${userId}`, {
        userId: id,
        rating: newRating,
      });

      // If the server responds with a new average rating, update the UI with it
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        rating: response.data.averageRating, // Update with the new average rating from server
      }));

      console.log('Rating added successfully:', response.data);
    } catch (error) {
      // If the request fails, revert the rating back to its previous state
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        rating: prevTemplate.rating, // Revert to original rating
      }));

      console.error('Error adding rating:', error);
      toast.error('Failed to add rating. Please try again later.');
    }
  };


  useEffect(() => {
    // Fetch reviews when the component loads or id changes
    const fetchReviews = async () => {
      try {
        const response = await axios.get(`${serverbaseURL}reviews/template/${id}`);
        setReviews(response.data.reviews);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };
    fetchReviews();
  }, [id]);

  const handleReviewSubmit = async () => {
    if (!reviewText.trim()) {
      toast.warning("Review text cannot be empty.");
      return;
    }

    try {
      const response = await axios.post(`${serverbaseURL}reviews/template/${id}`, {
        userId,
        userName,
        reviewText,
      });
      setReviews([...reviews, { userId, userName, reviewText }]); // Update the reviews list
      setReviewText(""); // Clear input field
    } catch (error) {
      console.error("Error submitting review:", error);
      toast.error("Failed to submit review. Please try again later.");
    }
  };

  const toggleReviewSection = () => {
    setIsReviewOpen(!isReviewOpen);
  };

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };


  const handleBack = () => {
    navigate('/marketplace');
  };

  const handlePreviewVideo = () => {
    setIsPreviewModalOpen(true);
  };


  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="max-w-7xl min-h-screen mt-20 mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">

        <div>
          <div className="relative aspect-video bg-gray-100 rounded-lg overflow-hidden">
            <video
              ref={videoRef}
              src={template.videoPreview}
              className="w-full h-full object-cover"
              controls={false}
              onEnded={() => setIsPlaying(false)}
            />
            <button onClick={togglePlay} className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white">
              {isPlaying ? <Pause className="h-16 w-16" /> : <Play className="h-16 w-16" />}
            </button>
          </div>

          <div className="grid grid-cols-4 gap-4 mt-4">
            {template.gallery?.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Preview ${index + 1}`}
                className="rounded-lg cursor-pointer hover:opacity-75 transition-opacity"
              />
            ))}
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between mb-4">
            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
              {template.category}
            </span>
            <div className="flex items-center">
              <Star className="h-5 w-5 text-yellow-400 fill-current" />
              <span className="ml-1 text-lg font-medium text-gray-900">{template.averageRating}</span>
            </div>
          </div>

          <h1 className="text-3xl font-bold text-gray-900 mb-4">{template.title}</h1>
          <p className="text-gray-600 mb-6">{template.description}</p>

          <div className="flex items-center mb-8">
            <img src={template.brandlogo} alt={template.creatorName} className="h-12 w-12 rounded-full" />
            <div className="ml-4">
              <h3 className="text-lg font-medium text-gray-900">{template.creatorName}</h3>
              <p className="text-sm text-gray-500">Template Creator</p>
            </div>
          </div>

          <div className="space-y-4">
            <button
              onClick={() => handlePayment(template.price)}
              className="w-full bg-indigo-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-indigo-700 transition-colors"
            >
              Purchase for ₹{template.price}
            </button>

            <button
              onClick={handlePreviewVideo}
              className="w-full border border-gray-300 text-gray-700 px-6 py-3 rounded-lg font-medium hover:bg-gray-50 transition-colors flex items-center justify-center"
            >
              <Video className="h-5 w-5 mr-2" />
              Preview Sample Video
            </button>

            <button
              onClick={handleContactSeller}
              className="w-full border border-gray-300 text-gray-700 px-6 py-3 rounded-lg font-medium hover:bg-gray-50 transition-colors flex items-center justify-center"
            >
              <MessageCircle className="h-5 w-5 mr-2" />
              Contact Seller
            </button>


            {/* Review Section */}
            <div className="mt-8 border-t pt-6">
              <button
                onClick={toggleReviewSection}
                className="text-indigo-600 font-medium flex items-center gap-2"
              >
                <Edit3 className="h-5 w-5" />
                {isReviewOpen ? "Hide Reviews" : "View & Add Review"}
              </button>

              {isReviewOpen && (
                <div className="mt-4">
                  {/* Display reviews */}
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">User Reviews</h2>
                  <div className="space-y-4">
                    {reviews.length > 0 ? (
                      reviews.map((review, index) => (
                        <div key={index} className="border p-4 rounded-lg shadow hover:shadow-md transition-shadow">
                          <h3 className="text-lg font-semibold text-indigo-600">{review.userName}</h3>
                          <p className="text-gray-700 mt-1">{review.reviewText}</p>
                        </div>
                      ))
                    ) : (
                      <p className="text-gray-500">No reviews yet. Be the first to write one!</p>
                    )}
                  </div>

                  {/* Review form */}
                  <div className="mt-6">
                    <h2 className="text-lg font-semibold text-gray-900">Write a Review</h2>
                    <textarea
                      className="w-full border border-gray-300 rounded-lg p-2 mt-2 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                      rows="4"
                      value={reviewText}
                      onChange={(e) => setReviewText(e.target.value)}
                      placeholder="Write your review here..."
                    ></textarea>
                    <button
                      onClick={() => handleReviewSubmit()}
                      className="mt-2 px-4 py-2 bg-indigo-600 text-white rounded-lg font-medium hover:bg-indigo-700 transition-colors"
                    >
                      Submit Review
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-4">
              <h4 className="text-lg font-medium text-gray-900">Rate this template:</h4>
              <div className="flex space-x-1">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    onClick={() => handleRating(star, userId, id)}
                    className={`h-8 w-8 flex items-center justify-center ${rating >= star ? 'text-yellow-400' : 'text-gray-400'
                      }`}
                  >
                    <Star className="h-5 w-5" />
                  </button>
                ))}
              </div>
              <button
                onClick={handleBack}
                className="my-4 px-6 py-3 rounded-lg font-medium bg-gray-200 text-gray-700 hover:bg-gray-300 transition-colors"
              >
                ⬅️ Back to Marketplace
              </button>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
            <h2 className="text-2xl font-bold mb-4">Payment Details</h2>
            {responseState.length !== 0 && (
              <ul>
                <li>Amount: {responseState.amount / 100} Rs.</li>
                <li>Currency: {responseState.currency}</li>
                <li>Status: {responseState.status}</li>
                <li>Method: {responseState.method}</li>
              </ul>
            )}
            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {isPreviewModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            {/* Background overlay */}
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
              onClick={() => setIsPreviewModalOpen(false)}
            ></div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            {/* Modal panel */}
            <div className="inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
              <div className="relative">
                {/* Close button */}
                <button
                  onClick={() => setIsPreviewModalOpen(false)}
                  className="absolute top-4 right-4 z-10 p-2 bg-white bg-opacity-25 rounded-full hover:bg-opacity-40 transition-all duration-200"
                >
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>

                {/* Video player */}
                <div className="aspect-video w-full">
                  <video
                    className="w-full h-full object-contain"
                    src={template.videoPreview}
                    controls
                    autoPlay
                  >
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>

              {/* Video information */}
              <div className="bg-white px-4 py-3">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {template.title} - Preview
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Sample video preview of the template
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>

  );
}
