export const nodeTypes = [
  {
    id: 'text-generation',
    name: 'Text Generation',
    description: 'Generate text using various language models',
    icon: '💭',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'model',
        type: 'select',
        options: ['ChatGPT 3.5', 'ChatGPT 4'],
        default: 'ChatGPT 4'
      },
      { name: 'prompt', type: 'text', required: true },
      { name: 'temperature', type: 'range', min: 0, max: 1, step: 0.1, default: 0.7 },
      // { name: 'maxTokens', type: 'number', min: 1, max: 4000, default: 2000 },
      // { name: 'topP', type: 'range', min: 0, max: 1, step: 0.1, default: 0.9 },
      // { name: 'systemMessage', type: 'text' }
    ],
  },
  {
    id: 'image-generation',
    name: 'Text to Image Generation',
    description: 'Generate images from text descriptions',
    icon: '🎨',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'model',
        type: 'select',
        options: ['Leonardo', 'Flux LORA'],
        default: 'Leonardo'
      },
      { name: 'prompt', type: 'text', required: true },
      {
        name: 'ratio',
        type: 'select',
        options: ['1:1', '16:9', '4:3', '3:4', '9:16'],
        default: '1:1'
      },
      {
        name: 'stylePreset',
        type: 'select',
        options: ['Cinematic', 'Digital Art', 'Anime', 'Photography', 'Abstract'],
        default: 'Digital Art',
        showWhen: (props) => props.model === 'Leonardo'
      },
      {
        name: 'loraLink',
        type: 'text',
        required: true,
        showWhen: (props) => props.model === 'Flux LORA',
        description: 'Enter the LORA training set link'
      }
    ],
  },
  {
    id: 'video-generation',
    name: 'Text/Image to Video',
    description: 'Generate videos from text or images',
    icon: '🎥',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'mode',
        type: 'select',
        options: ['Text to Video', 'Image to Video'],
        default: 'Text to Video'
      },
      {
        name: 'textPrompts',
        type: 'text',
        required: true,
        showWhen: (props) => props.mode === 'Text to Video',
        description: 'Enter text prompts for video generation'
      },
      {
        name: 'imagePrompts',
        type: 'text',
        required: true,
        showWhen: (props) => props.mode === 'Image to Video',
        description: 'Enter text prompts for each image'
      },
      {
        name: 'imageUrls',
        type: 'text',
        required: true,
        showWhen: (props) => props.mode === 'Image to Video',
        description: 'Enter image URLs (one per line)'
      },
      {
        name: 'ratio',
        type: 'select',
        options: ['1:1', '16:9', '4:3', '9:16'],
        default: '16:9'
      },
      {
        name: 'length',
        type: 'number',
        min: 1,
        max: 10,
        default: 5
      }
    ],
  },
  {
    id: 'audio-generation',
    name: 'Text to Audio',
    description: 'Generate audio from text using Eleven Labs',
    icon: '🔊',
    inputs: 1,
    outputs: 1,
    properties: [
      { name: 'text', type: 'text', required: true },
      { name: 'voice', type: 'string' },
      // { name: 'stability', type: 'range', min: 0, max: 1, step: 0.1, default: 0.5 },
      // { name: 'similarityBoost', type: 'range', min: 0, max: 1, step: 0.1, default: 0.5 },
      // { name: 'style', type: 'range', min: 0, max: 1, step: 0.1, default: 0.5 },
      // { name: 'speakerBoost', type: 'boolean', default: false },
      {
        name: 'model',
        type: 'select',
        options: ['Eleven labs', 'coming soon'],
        default: 'Eleven labs'
      },
      // {
      //   name: 'outputFormat',
      //   type: 'select',
      //   options: ['MP3', 'WAV'],
      //   default: 'MP3'
      // }
    ],
  },
  {
    id: 'video-composition',
    name: 'Video Composition',
    description: 'Compose video with image, audio and optional captions',
    icon: '🎬',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'imageSource',
        type: 'text',
        required: true,
        description: 'URL or path to the image'
      },
      {
        name: 'audioSource',
        type: 'text',
        required: true,
        description: 'URL or path to the audio file'
      },
      {
        name: 'enableCaptions',
        type: 'boolean',
        default: false,
        description: 'Enable or disable captions'
      }
    ],
  },
  {
    id: 'prompt-input',
    name: 'Prompt Input',
    description: 'Create multiple scene prompts',
    icon: '📝',
    inputs: 0,
    outputs: 1,
    properties: [
      {
        name: 'scenes',
        type: 'scene-list',
        default: [{ id: 1, prompt: '' }]
      }
    ],
  },
  {
    id: 'text-to-video',
    name: 'Text to Video Generation',
    description: 'Generate videos directly from text prompts',
    icon: '🎬',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'prompts',
        type: 'text',
        required: true,
        description: 'Enter text prompts (one per line) to generate videos'
      },
      {
        name: 'height',
        type: 'number',
        default: 720,
        min: 360,
        max: 1080
      },
      {
        name: 'width',
        type: 'number',
        default: 1280,
        min: 640,
        max: 1920
      },
      {
        name: 'topic',
        type: 'text',
        default: 'flow',
        description: 'Topic/category for the video'
      }
    ],
  },
  {
    id: 'image-prompt-video',
    name: 'Image+Prompt Video Generation',
    description: 'Generate videos using paired image and video prompts',
    icon: '🎥',
    inputs: 1,
    outputs: 1,
    properties: [
      {
        name: 'imagePrompts',
        type: 'text',
        required: true,
        description: 'Enter image prompts (one per line)'
      },
      {
        name: 'videoPrompts',
        type: 'text',
        required: true,
        description: 'Enter video prompts (one per line, must match number of image prompts)'
      },
      {
        name: 'height',
        type: 'number',
        default: 720,
        min: 360,
        max: 1080
      },
      {
        name: 'width',
        type: 'number',
        default: 1280,
        min: 640,
        max: 1920
      },
      {
        name: 'topic',
        type: 'text',
        default: 'flow',
        description: 'Topic/category for the video'
      }
    ],
  }
];