import React, { useState, useEffect,useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Search, ShoppingCart, User, PlusCircle, FileText } from 'lucide-react';
import { useCart } from '../../provider/TemplateCartContext';
import axios from 'axios';
import { AuthContext } from "../../provider/AuthProvider";
import { serverbaseURL } from "../../constant/index";

export default function Navbar() {
  const { user } = useContext(AuthContext);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSeller, setIsSeller] = useState(false);
  const { cartItems, removeFromCart } = useCart();
  const navigate = useNavigate();

  // useEffect(() => {
  //   const checkSellerStatus = async () => {
  //     try {
  //       const response = await axios.post(`${serverbaseURL}get_user_details`, {
  //         uid: user.uid,
  //       });
  //       setIsSeller(response.data.isSeller || false);
  //     } catch (error) {
  //       console.error('Error checking seller status:', error);
  //       setIsSeller(false);
  //     }
  //   };

  //   checkSellerStatus();
  // }, []);

  // const handleSellerFeatureClick = (e, path) => {
  //   if (!isSeller) {
  //     e.preventDefault();
  //     setIsModalOpen(true);
  //   } else {
  //     navigate(path);
  //   }
  // };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  return (
    <nav className="bg-white shadow-sm fixed top-0 left-0 w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/templates" className="flex-shrink-0 flex items-center">
              <h1 className="text-2xl font-bold text-indigo-600">AutoMovieCreator</h1>
            </Link>
          </div>

          <div className="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
            <div className="max-w-lg w-full lg:max-w-xs">
              <label htmlFor="search" className="sr-only">Search</label>
              <div className="relative">
               
              
              </div>
            </div>
          </div>

          <div className="flex items-center">
          <button
            onClick={() => navigate('/marketplace/createTemplate')}
            className="p-2 text-gray-600 hover:text-gray-900 flex items-center"
          >
            <PlusCircle className="h-5 w-5 mr-1" />
            Create Templates
          </button>
          <button
            onClick={() => navigate('/marketplace/mytemplate')}
            className="p-2 text-gray-600 hover:text-gray-900 flex items-center"
          >
            <FileText className="h-5 w-5 mr-1" />
            Publish
          </button>
            {/* <Link to="/marketplace" className="p-2 text-gray-600 hover:text-gray-900">
              Marketplace
            </Link> */}
            {/* <button
              onClick={toggleCart}
              className="p-2 text-gray-600 hover:text-gray-900 relative"
            >
              <ShoppingCart className="h-6 w-6" />
              {cartItems.length > 0 && (
                <span className="absolute top-0 right-0 block w-4 h-4 text-xs text-white bg-red-600 rounded-full">
                  {cartItems.length}
                </span>
              )}
            </button>
            <Link to="/marketplace/buyer/dashboard" className="ml-4 p-2 text-gray-600 hover:text-gray-900">
              <User className="h-6 w-6" />
            </Link> */}
          </div>
        </div>
      </div>

      {/* Seller Registration Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
                  <svg 
                    className="h-6 w-6 text-indigo-600" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Become a Seller
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      To create and publish templates, you'll need to register as a seller. Join our marketplace and start sharing your creative work!
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 space-y-2">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={() => {
                    setIsModalOpen(false);
                    navigate('/marketplace/seller-Registration');
                  }}
                >
                  Register as Seller
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Cart Sidebar */}
      {isCartOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-end bg-black bg-opacity-50">
          <div className="w-[350px] bg-white h-full shadow-lg transform translate-x-0 transition-all ease-in-out duration-300">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-xl font-semibold text-center text-gray-800">Your Cart</h2>
              <button onClick={toggleCart} className="text-gray-500 text-xl">
                &times;
              </button>
            </div>

            <div className="p-4 max-h-full overflow-y-auto">
              {cartItems.length > 0 ? (
                <div>
                  {cartItems.map((item) => (
                    <div
                      key={item.id}
                      className="flex flex-col bg-gray-100 p-3 mb-4 rounded-lg shadow-sm"
                    >
                      <div className="flex items-center">
                        <img
                          src={item.thumbnail}
                          alt={item.title}
                          className="w-12 h-12 rounded-md object-cover mr-4"
                        />
                        <div className="flex-1">
                          <h3 className="text-sm font-semibold text-gray-900">{item.title}</h3>
                          <p className="text-xs text-gray-500">{item.category}</p>
                          <div className="flex items-center mt-1">
                            {[...Array(5)].map((_, index) => (
                              <span
                                key={index}
                                className={`${index < item.rating
                                  ? 'text-yellow-400'
                                  : 'text-gray-300'
                                  } text-xs`}
                              >
                                ★
                              </span>
                            ))}
                          </div>
                          <p className="text-sm text-gray-900 mt-2">₹{item.price}</p>
                        </div>
                      </div>

                      <div className="flex justify-end gap-x-1 items-center">
                        {/* View Details Link */}
                        <Link
                          to={`/marketplace/template/${item.id}`}
                          className="text-sm text-indigo-600 hover:text-indigo-800"
                          onClick={toggleCart} // Close cart when "View Details" is clicked
                        >
                          View Details
                        </Link>

                        {/* Remove Item Button */}
                        <button
                          onClick={() => removeFromCart(item.id)} // Call removeFromCart function
                          className="text-sm text-red-500 hover:text-red-700"
                        >
                          <span className="cursor-pointer">Remove</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500">Your cart is empty.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}
