import {
  createBrowserRouter,
} from "react-router-dom";
import MainLayout from "../Layout/MainLayout";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
import DashboardLayout from "../Layout/DashboardLayout";
import View from "../pages/View";
import EditVideo from '../pages/EditVideo';
import ViewGenerations from '../pages/ViewGenerations';

import Billing from "../pages/Billing";
import Support from "../pages/SupportForm";
import PrivateRoute from "./PrivateRoute";

import VideoCreationWizard from "../pages/CreateUI2";
import AffiliateCreate from "../pages/AffiliateCreate";
import Contact from "../pages/Contact";
import Templates from "../pages/Templates";
import TermsAndConditions from "../pages/TermsAndConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ChannelAssets from "../pages/ChannelAssets";
import CampaignDashboard from "../pages/CampaignDashboard";
import MarketPlaceLayout from "../Layout/MarketPlaceLayout";
import BuyerDashboard from "../pages/MarketPlace/BuyerDashboard";
import SellerDashboard from "../pages/MarketPlace/SellerDasboard";
import SellerProfile from "../pages/MarketPlace/SellerProfile";
import TemplateDetail from "../pages/MarketPlace/TemplateDetails";
import TemplatesListing from "../pages/MarketPlace/TemplateListing";
import AddTemplate from "../pages/MarketPlace/AddTemplate";
import SellerRegistration from "../pages/MarketPlace/SellerRegistration";
import MyGenerations from "../pages/MyGenerations";
import SchedulePost from "../pages/schedulePost";
import AdminDashboard from "../pages/AdminDashboard";
import CreateTemplate from "../pages/MarketPlace/createTemplate";
import MyTemplates from "../pages/MarketPlace/MyTemplates";
import SetupAutomation from "../pages/SetupAutomation";
import ReturnPolicyindia from "../pages/ReturnPolicyindia";
import InternationalRefundPolicy from "../pages/InternationalRefundPolicy";
import ChartRecorder from '../pages/ChartRecorder';
import { ContactUs } from "../pages/ContactUs";


const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/register',
        element: <Register />
      },
      {
        path: '/contact',
        element: <Contact />
      },
      {
        path: '/pricing',
        element: <Billing />
      },
      {
        path: '/support',
        element: <Support />
      },
      {
        path: '/terms-and-conditions',
        element: <TermsAndConditions />
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />
      },
      {
        path: '//contact-us',
        element: <ContactUs />
      },
      {
        path: '/ChannelAssets',
        element: <ChannelAssets />
      },
      {
        path: '/InternationalRefundPolicy',
        element: <InternationalRefundPolicy />
      },
      {
        path: '/RefundPolicyindia',
        element: <ReturnPolicyindia />
      },
      {
        path: '/templates',
        element: <Templates />
      },
      {
        path: '/CampaignDashboard',
        element: < CampaignDashboard />
      },
      {
        path: 'my-generations',
        element: <MyGenerations />
      },
      {
        path: 'schedulePost',
        element: <SchedulePost />
      },
      {
        path: 'dashboards',
        element: <PrivateRoute><View /></PrivateRoute>
      },
      {
        path: 'affiliate',
        element: <PrivateRoute><AffiliateCreate /></PrivateRoute>
      },
      {
        path: 'create',
        element: <PrivateRoute><VideoCreationWizard /></PrivateRoute>
      },
      {
        path: 'setup-automation',
        element: <PrivateRoute><SetupAutomation /></PrivateRoute>
      },
      {
        path: '/chart-recorder',
        element: <ChartRecorder />
      }
    ]

  }, {
    path: "dashboard",
    element: <PrivateRoute><DashboardLayout /></PrivateRoute>,
    children: [
      // {
      //   index:true,
      //   element:<PrivateRoute><View/></PrivateRoute>
      // },
      // {
      //   path:'create',
      //   element:<PrivateRoute><VideoCreationWizard/></PrivateRoute>
      // },

      // {
      //   path:'account',
      //   element:<PrivateRoute><Account/></PrivateRoute>
      // },
      {
        path: 'billing',
        element: <PrivateRoute><Billing /></PrivateRoute>
      },

      {
        path: 'edit-video',
        element: <PrivateRoute><EditVideo /></PrivateRoute>
      },
      {
        path: 'viewGenerations',
        element: <PrivateRoute><ViewGenerations /></PrivateRoute>
      },
      {
        path: 'admin-dashboard',
        element: <PrivateRoute><AdminDashboard /></PrivateRoute>
      },
    ],
  },
  {
    path: "marketplace",
    element: <PrivateRoute><MarketPlaceLayout /></PrivateRoute>,
    children: [
      { index: true, element: <TemplatesListing /> },
      { path: "template/:id", element: <TemplateDetail /> },
      { path: "seller/:id", element: <SellerProfile /> },
      { path: "seller/dashboard", element: <SellerDashboard /> },
      { path: "buyer/dashboard", element: <BuyerDashboard /> },
      { path: "addtemplate", element: <AddTemplate /> },
      { path: "createTemplate", element: <CreateTemplate /> },
      { path: "seller-Registration", element: <SellerRegistration /> },
      { path: "mytemplate", element: <MyTemplates /> },
    ],
  },

]);

export default router;