import React from 'react';
import { Shield, Clock, CreditCard, Mail, AlertCircle, DollarSign, Building, Globe, ArrowLeftRight, Phone } from 'lucide-react';

function InternationalRefundPolicy() {
  return (
    <div className="min-h-screen mt-10">
      {/* Header */}
      <header className="">
        <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
          <div className="flex items-center space-x-3">
            <Shield className="w-8 h-8 text-indigo-600" />
            <h1 className="text-3xl font-bold text-gray-900">International Refund Policy</h1>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-md p-6 md:p-8 space-y-8">
          {/* Introduction */}
          <section className="space-y-4">
            <div className="bg-indigo-50 border-l-4 border-indigo-600 p-4 rounded">
              <p className="text-indigo-700">
                At automoviecreator, we are committed to ensuring a seamless and transparent transaction experience for our international customers. This page outlines our policies regarding refunds for international transactions.
              </p>
            </div>
          </section>

          {/* Refund Turnaround Time */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-gray-900 flex items-center gap-2">
              <Clock className="w-6 h-6 text-indigo-600" />
              Refund Turnaround Time
            </h2>
            <div className="grid gap-4 md:grid-cols-3">
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="font-semibold text-gray-900 mb-2 flex items-center gap-2">
                  <Clock className="w-5 h-5 text-indigo-600" />
                  Processing Time
                </h3>
                <p className="text-gray-600">
                  Refunds for international transactions are processed within 15 business days from the date of the refund request approval.
                </p>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="font-semibold text-gray-900 mb-2 flex items-center gap-2">
                  <CreditCard className="w-5 h-5 text-indigo-600" />
                  Refund Method
                </h3>
                <p className="text-gray-600">
                  Refunds are processed using the original method of payment.
                </p>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="font-semibold text-gray-900 mb-2 flex items-center gap-2">
                  <Globe className="w-5 h-5 text-indigo-600" />
                  Currency
                </h3>
                <p className="text-gray-600">
                  Refunds are issued in the same currency as the original transaction.
                </p>
              </div>
            </div>
          </section>

          {/* How to Request a Refund */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-gray-900 flex items-center gap-2">
              <AlertCircle className="w-6 h-6 text-indigo-600" />
              How to Request a Refund
            </h2>
            <div className="bg-gray-50 p-6 rounded-lg space-y-4">
              <div className="space-y-3">
                <h3 className="font-semibold text-gray-900 flex items-center gap-2">
                  <Mail className="w-5 h-5 text-indigo-600" />
                  Contact Customer Service
                </h3>
                <p className="text-gray-600">
                  Reach out to our customer service team via email to initiate a refund request.
                </p>
              </div>
              <div className="space-y-3">
                <h3 className="font-semibold text-gray-900 flex items-center gap-2">
                  <Building className="w-5 h-5 text-indigo-600" />
                  Provide Transaction Details
                </h3>
                <p className="text-gray-600">
                  Include your order number, transaction date, and reason for the refund.
                </p>
              </div>
              <div className="space-y-3">
                <h3 className="font-semibold text-gray-900 flex items-center gap-2">
                  <Clock className="w-5 h-5 text-indigo-600" />
                  Wait for Approval
                </h3>
                <p className="text-gray-600">
                  Our team will review your request and respond within 5 business days.
                </p>
              </div>
            </div>
          </section>

          {/* Conditions for Refund */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-gray-900 flex items-center gap-2">
              <AlertCircle className="w-6 h-6 text-indigo-600" />
              Conditions for Refund
            </h2>
            <div className="grid gap-4 md:grid-cols-2">
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="font-semibold text-gray-900 mb-2 flex items-center gap-2">
                  <Shield className="w-5 h-5 text-indigo-600" />
                  Eligibility
                </h3>
                <p className="text-gray-600">
                  Refunds are only eligible if it is mentioned on website or email as a part of Provided Product or service offering.
                </p>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="font-semibold text-gray-900 mb-2 flex items-center gap-2">
                  <Clock className="w-5 h-5 text-indigo-600" />
                  Time Limit
                </h3>
                <p className="text-gray-600">
                  Refund requests must be made within 15 days of the original transaction date.
                </p>
              </div>
            </div>
          </section>

          {/* Additional Information */}
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-gray-900 flex items-center gap-2">
              <ArrowLeftRight className="w-6 h-6 text-indigo-600" />
              Additional Information
            </h2>
            <div className="grid gap-4 md:grid-cols-2">
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="font-semibold text-gray-900 mb-2 flex items-center gap-2">
                  <DollarSign className="w-5 h-5 text-indigo-600" />
                  Exchange Rates
                </h3>
                <p className="text-gray-600">
                  Refunds are subject to the exchange rate at the time of the refund processing, not the transaction date.
                </p>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="font-semibold text-gray-900 mb-2 flex items-center gap-2">
                  <CreditCard className="w-5 h-5 text-indigo-600" />
                  Fees
                </h3>
                <p className="text-gray-600">
                  Any international transaction fees or bank charges are typically non-refundable.
                </p>
              </div>
            </div>
          </section>

          {/* Contact Section */}
          <section className="bg-indigo-50 p-6 rounded-lg">
            <div className="flex items-start gap-4">
              <Mail className="w-6 h-6 text-indigo-600 flex-shrink-0 mt-1" />
              <div>
                <h3 className="font-semibold text-gray-900 mb-2">Contact Us</h3>
                <p className="text-gray-600">
                  For any queries or further clarification regarding our international refund policy, please do not hesitate to contact us at{' '}
                  <div className="flex items-start mt-3 gap-2">
                    {/* Gmail Logo and Email */}
                    <img src="https://www.gstatic.com/images/branding/product/1x/gmail_2020q4_48dp.png" alt="Gmail" className="w-5 h-5 mt-1" />
                    <p className="text-gray-600">
                      Email: <a href="mailto:info@automoviecreator.com" className="text-indigo-600 hover:underline">info@automoviecreator.com</a>
                    </p>
                  </div>
                  <div className="flex items-start gap-2 mt-2">
                    {/* Phone Icon and Number */}
                    <Phone className="w-5 h-5 text-indigo-600 mt-1" />
                    <a href="tel:+918130339698" className="text-gray-600 hover:underline">
                      Phone: +918130339698
                    </a>
                  </div>

                </p>
              </div>
            </div>
          </section>

          {/* Thank You Note */}
          <section className="bg-gray-50 p-6 rounded-lg text-center">
            <p className="text-gray-600">
              Thank you for choosing automoviecreator. We are dedicated to ensuring your satisfaction with every transaction.
            </p>
          </section>
        </div>
      </main>
    </div>
  );
}

export default InternationalRefundPolicy;