import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../provider/AuthProvider';
import axios from 'axios';
import { serverbaseURL } from '../constant/index.jsx';
import { Calendar, Clock, MessageSquare, Plus, X, Loader2, Send } from 'lucide-react';
import { toast } from 'react-toastify';

function formatDateToISO(date, time) {
  const padToTwoDigits = (num) => (num < 10 ? '0' + num : num);
  const year = date.getFullYear();
  const month = padToTwoDigits(date.getMonth() + 1);
  const day = padToTwoDigits(date.getDate());
  const [hours, minutes] = time.split(':').map(Number);
  const istDate = new Date(Date.UTC(year, date.getMonth(), date.getDate(), hours, minutes, 0));
  istDate.setHours(istDate.getHours() - 5);
  istDate.setMinutes(istDate.getMinutes() - 30);
  const gmtHours = padToTwoDigits(istDate.getUTCHours());
  const gmtMinutes = padToTwoDigits(istDate.getUTCMinutes());
  return `${year}-${month}-${day} ${gmtHours}:${gmtMinutes}:00`;
}

const SchedulePost = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState('');
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [caption, setCaption] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchConnectedAccounts = async () => {
      try {
        const response = await axios.post(`${serverbaseURL}fetch-accounts`, {
          uid: user.uid,
          email: user.email,
        });
        setConnectedAccounts(response.data.accounts);
      } catch (error) {
        console.error('Error fetching connected accounts:', error);
      }
    };
    fetchConnectedAccounts();
  }, [user]);

  const handleDateChange = (event) => {
    setSelectedDate(new Date(event.target.value));
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleSocialConnect = async (provider) => {
    setShowSidebar(false);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.uid || !user.email) {
        console.error("User information is missing from localStorage");
        return;
      }
      const { uid, email } = user;
      const response = await axios.post(`${serverbaseURL}connect-social-account`, {
        provider,
        uid,
        email,
      });
      const { connect_url } = response.data;
      if (connect_url) {
        window.open(connect_url, '_blank');
      }
    } catch (error) {
      console.error("Error connecting social account:", error);
    }
  };


  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const publishAt = formatDateToISO(selectedDate, selectedTime);
      const accountIds = connectedAccounts.filter((a) => a.selected).map((a) => a.id);
      const content = caption;
      const videoTitle = location.state.topic;

      const scheduledPostResponse = await axios.post(`${serverbaseURL}schedule-video-post`, {
        videoUrl: location.state.videoUrl,
        accountIds,
        publishAt,
        content,
        videoTitle,
      });

      if (scheduledPostResponse.status === 200) {
        toast.success('Post scheduled successfully!');
        navigate('/my-generations');
      }
    } catch (error) {
      toast.error('Failed to schedule the post. Please try again.');
      console.error('Error scheduling post:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen shadow-lg rounded-xl p-8  py-10 px-4">
      <div className="max-w-4xl mx-auto">

        <div className="bg-[#efefff]  shadow-lg rounded-xl p-8">
          <h2 className="text-3xl font-semibold font-custom text-black py-4 mb-5">Schedule Post</h2>
          <div className="mb-6">
            <label className="flex items-center text-base font-semibold text-[#55595c] mb-1">
              <Calendar className="w-5 h-5 text-[#55595c]" />
              <span className="ml-2">Select Date</span>
            </label>
            <input
              type="date"
              value={selectedDate.toISOString().slice(0, 10)}
              onChange={handleDateChange}
              className="w-full px-4 py-2 mt-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6] focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200"
            />
          </div>

          <div className="mb-6">
            <label className="flex items-center text-base font-semibold text-[#55595c] mb-1">
              <Clock className="w-5 h-5 text-[#55595c]" />
              <span className="ml-2">Select Time</span>
            </label>
            <input
              type="time"
              value={selectedTime}
              onChange={handleTimeChange}
              className="w-full px-4 py-2 mt-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6] focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200"
            />
          </div>

          <div className="mb-6">
            <label className="flex items-center text-base font-semibold text-[#55595c] mb-1">
              <MessageSquare className="w-5 h-5 text-[#55595c]" />
              <span className="ml-2">Caption</span>
            </label>
            <input
              type="text"
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              placeholder="Enter your caption here"
              className="w-full px-4 py-2 mt-2 rounded-lg bg-[#f9f9f9] border border-[#d1d1d6] focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200"
              required
            />
          </div>


          <div className="form-group py-3">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-slate-900">Connected Accounts</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {connectedAccounts.map((account) => (
                <div
                  key={account.id}
                  className="flex items-center p-4 border-[1px] border-[#805af5] rounded-xl bg-[#f8f8ff] h-full shadow-lg hover:shadow-2xl hover:border-[#0d6efd]"
                >
                  <input
                    type="checkbox"
                    checked={account.selected}
                    onChange={() => {
                      setConnectedAccounts(
                        connectedAccounts.map((a) =>
                          a.id === account.id ? { ...a, selected: !a.selected } : a
                        )
                      );
                    }}
                    className="h-4 w-4 mr-3"
                  />
                  <div>
                    <h4 className="text-gray-800 font-medium text-sm capitalize">{account.name}</h4>
                    <p className="text-sm text-[#55595c]">{account.type}</p>
                  </div>
                </div>
              ))}
              {connectedAccounts.length === 0 && (
                <div className="col-span-full text-md text-center py-4 text-[#55595c]">
                  No social accounts connected. Click "Add Socials" to connect.
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-end gap-4">
            <button
              onClick={() => setShowSidebar(true)}
              className="flex items-center px-6 py-3 bg-gradient-to-r from-[#805af5] to-blue-600 text-white   rounded-md text-md font-semibold hover:shadow-lg transition duration-200"
            >
              <Plus className="w-5 h-5 mr-2" />
              Add Social
            </button>
            <button
              onClick={handleSubmit}
              disabled={!caption || isLoading}
              className="flex items-center gap-2 px-6 py-3 bg-white text-black rounded-lg hover:bg-blue-700 hover:text-white transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                <Send className="w-5 h-5" />
              )}
              {isLoading ? 'Scheduling...' : 'Schedule Post'}
            </button>
          </div>
        </div>
      </div>

      {showSidebar && (
        <div className="sidebar fixed top-0 right-0 w-full md:w-1/4 h-full bg-white shadow-lg z-50 p-6 md:p-8 transition-transform duration-300 ease-in-out transform md:translate-x-0 translate-x-full">
          <button
            onClick={() => setShowSidebar(false)}
            className="absolute top-4 left-4 text-gray-600 hover:text-indigo-600 transition-all duration-200"
          >
            <X className="w-6 h-6" />
          </button>
          <h2 className="text-md font-semibold text-gray-700 mb-4 text-center md:text-left">Connect Social Account</h2>
          <div className="space-y-4 overflow-y-auto max-h-[calc(100vh-100px)]">
            {[
              'facebook',
              'twitter',
              'instagram',
              'linkedin',
              'google',
              'mastodon',
              'tiktok',
              'reddit',
              'youtube',
              'gmb',
              'pinterest',
              'threads',
              'bluesky',
            ].map((provider) => (
              <button
                key={provider}
                onClick={() => handleSocialConnect(provider)}
                className="block w-full text-left px-4 py-3 bg-gray-100 rounded-lg hover:text-violet-500 hover:bg-indigo-50 focus:ring-2 focus:ring-indigo-500 transition-colors duration-300 capitalize"
              >
                {provider.charAt(0).toUpperCase() + provider.slice(1)}
              </button>
            ))}
          </div>
        </div>

      )}
    </div>
  );
};

export default SchedulePost;