import React, { useState } from 'react';
import { Plus, X, ChevronDown, ChevronUp } from 'lucide-react';
import { Button } from './ui/Button';
import { CgAdd } from 'react-icons/cg';

export function SceneListInput({ value = [], onChange }) {
    const [activeScene, setActiveScene] = useState(0);
    const [expandedScenes, setExpandedScenes] = useState({});

    const addScene = (index) => {
        const newScenes = [...value];
        const newId = Math.max(...value.map(s => s.id), 0) + 1;
        newScenes.splice(index + 1, 0, { id: newId, prompt: '' });
        onChange(newScenes);
        setActiveScene(index + 1);
    };

    const removeScene = (index) => {
        const newScenes = value.filter((_, i) => i !== index);
        onChange(newScenes);
        if (activeScene >= newScenes.length) {
            setActiveScene(Math.max(0, newScenes.length - 1));
        }
    };

    const updateScenePrompt = (index, prompt) => {
        const newScenes = [...value];
        newScenes[index] = { ...newScenes[index], prompt };
        onChange(newScenes);
    };

    const toggleScene = (index) => {
        setExpandedScenes(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center mb-4">
                <div className="text-sm font-medium">Total Scenes: {value.length}</div>
                <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => addScene(value.length - 1)}
                    className="text-blue-500"
                >
                    <Plus className="w-4 h-4 mr-2" />
                    Add New Scene
                </Button>
            </div>

            <div className="space-y-2 max-h-[500px] overflow-y-auto">
                {value.map((scene, index) => (
                    <div
                        key={scene.id}
                        className="border rounded-lg bg-white shadow-sm"
                    >
                        <div
                            className="flex items-center justify-between p-3 cursor-pointer hover:bg-gray-50"
                            onClick={() => toggleScene(index)}
                        >
                            <div className="flex items-center space-x-2">
                                {expandedScenes[index] ?
                                    <ChevronUp className="w-4 h-4" /> :
                                    <ChevronDown className="w-4 h-4" />
                                }
                                <span className="font-medium">Scene {index + 1}</span>
                                <span className="text-gray-400 text-sm">
                                    {scene.prompt ?
                                        `(${scene.prompt.slice(0, 30)}${scene.prompt.length > 30 ? '...' : ''})` :
                                        '(Empty)'}
                                </span>
                            </div>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeScene(index);
                                }}
                                className="text-gray-400 hover:text-red-500"
                            >
                                <X className="w-4 h-4" />
                            </button>
                        </div>

                        {expandedScenes[index] && (
                            <div className="p-3 border-t">
                                <textarea
                                    value={scene.prompt}
                                    onChange={(e) => updateScenePrompt(index, e.target.value)}
                                    className="w-full p-2 border rounded min-h-[100px] text-sm"
                                    placeholder={`Enter prompt for scene ${index + 1}`}
                                />
                                <div className="flex justify-end mt-2 space-x-2 text-green-400">
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={() => addScene(index)}
                                    >
                                    <CgAdd className='mr-2'/> Add Next Scene
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}