import React from 'react';
import { ScrollText } from 'lucide-react';

function TermsAndConditions() {
  return (
    <div className="min-h-screen mt-5">
      <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="flex items-center gap-3 mb-8">
          <ScrollText className="w-8 h-8 text-blue-600" />
          <h1 className="text-2xl font-semibold text-gray-900">Terms and Conditions</h1>
        </div>

        {/* Content Container */}
        <div className="bg-white rounded-lg shadow-sm p-6 sm:p-8">
          {/* Agreement Section */}
          <div className="space-y-4">
            <p className="text-[14px] text-[#55595c] leading-relaxed">
              This is a legal agreement between the user (referred to as "You") and automoviecreator (referred to as "the Company"), which governs your limited right to use the automoviecreator website, related services, and products. By using the Service, You agree to the terms of this Agreement. If You do not agree with these terms, You cannot use the Services.
            </p>

            <p className="text-[14px] text-[#55595c] leading-relaxed">
              automoviecreator reserves the right to amend this Agreement, which automatically takes effect on the earliest of the day You use the Service or 10 days after they are posted on the Site. Your use of the Service following the effective date of any modifications to this Agreement constitutes Your acceptance of the Agreement, as modified.
            </p>

            <p className="text-[14px] text-[#55595c] leading-relaxed">
              You confirm that You are at least 18 years old or the applicable age of majority and have the legal right and ability to enter into this Agreement.
            </p>

            {/* User Types Section */}
            <div className="py-2">
              <p className="text-[14px] text-[#55595c] leading-relaxed mb-2">
                You can invite two types of user accounts to Your automoviecreator Service:
              </p>
              <ul className="list-decimal pl-5 space-y-2">
                <li className="text-[14px] text-[#55595c] leading-relaxed">
                  "User" is an authorized employee, consultant, or contractor who can use Your automoviecreator account.
                </li>
                <li className="text-[14px] text-[#55595c] leading-relaxed">
                  "Administrator" is a person authorized to act on behalf of You, responsible for the administration and management of Your automoviecreator account.
                </li>
              </ul>
            </div>

            {/* Services Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                1. Services Offered by automoviecreator
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed space-y-4">
                <p>
                  automoviecreator offers a feature that enables the analysis of voice calls made through a third-party phone system or video conferencing solution.
                </p>
                <p>
                  However, it should be noted that automoviecreator does not provide phone lines and cannot replace any type of phone service, whether it is wired, wireless, or Internet-based. Instead, the automoviecreator service is designed to analyze the audio and video calls made by your calling agents, allowing you to train and coach them. It is important to understand that the automoviecreator Service does not support any form of call surveillance without the knowledge of your users. You are responsible for informing your users that the service is only for official calls and training purposes.
                </p>
                <p>
                  Furthermore, any limitations in your third-party VoIP service will also affect how you can use the automoviecreator Service.
                </p>
              </div>
            </div>

            {/* Quality and Maintenance Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                2. Quality and Maintenance
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed space-y-4">
                <p>
                  While automoviecreator strives to ensure that the automoviecreator Service is functioning with minimum disruptions, delays, or other imperfections, it cannot guarantee that the Service will always function flawlessly. The Service is transmitted through public internet lines and the public switched telephone network, which may result in power outages or internet service disruptions, potentially causing some disruptions, such as packet loss and delay, that could interfere with the quality of Your communications.
                </p>
                <p>
                  automoviecreator reserves the right to modify technical features to keep pace with the latest demands and technological developments or to comply with Applicable Laws. Additionally, automoviecreator may need to repair, improve, and/or upgrade the automoviecreator Service, which may necessitate restricting, limiting, suspending, interfering, and/or interrupting the Service at any time at its sole discretion.
                </p>
              </div>
            </div>

            {/* Limited License Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                3. Limited License
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed space-y-4">
                <p>
                  Under this Agreement, automoviecreator provides You with a limited, revocable, non-exclusive, and non-transferable license to use the Service for your own personal, enterprise, and limited commercial use, subject to certain conditions. You agree not to resell the Service, modify, reverse engineer, decompile, or disassemble any part of it, or copy, adapt, alter, modify, translate, or create derivative works of the Service without written permission from automoviecreator.
                </p>
                <p>
                  You also agree not to use the Service for any illegal purposes, transmit any material that is unlawful, harassing, libelous, invasive of another's privacy, abusive, threatening, obscene, or infringes the rights or intellectual property of others. Additionally, You may not allow others to use the Service or transfer rights to it.
                </p>
                <p>
                  You also may not circumvent or disable any technological features or measures in the Service for protection of intellectual property rights or use the Service in conjunction with any device, program, or service designed to circumvent technological measures employed to control access to, or the rights in, a content file or other work protected by the copyright law of any jurisdiction. Violation of these conditions will result in the immediate termination of Your license to the Service.
                </p>
              </div>
            </div>

            {/* Use of automoviecreator Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                4. Use of automoviecreator
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed space-y-4">
                <p>
                  These Terms of Use state that you and anyone using your automoviecreator account are responsible for any use of automoviecreator, whether via the website or mobile device. You agree to only use automoviecreator Services in a manner that is authorized by these Terms of Use or in writing. It is important that any information you provide to automoviecreator is accurate and complete.
                </p>
                <p>
                  You are not permitted to use the automoviecreator Services in any way that is illegal, abusive, interfering, or otherwise inappropriate. This includes violating any laws, regulations, published policies, intellectual property rights, or the privacy rights of others. You must not transmit any objectionable content, including content that is sexually explicit, pornographic, obscene, or hateful.
                </p>
                <p>
                  You are also prohibited from engaging in commercial exploitation, stalking, harassing, harming others, impersonating someone else, or engaging in fraudulent activity with respect to third parties. Additionally, you are required to comply with any applicable third-party policies or requirements that automoviecreator has communicated to you.
                </p>
                <p>
                  You and your designated administrators may appoint additional administrators for your automoviecreator account. Each administrator must agree to and comply with these Terms of Use. You are responsible for ensuring that all administrators read and comply with these terms and are liable for any breach of the terms by such administrators.
                </p>
              </div>
            </div>

            {/* Pricing and Billing Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                5. Pricing and Billing
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed space-y-4">
                <p>
                  During the free trial period, automoviecreator will not charge you for using their services. However, once the trial period ends or if you wish to access additional services not included in the trial, you will need to sign up for a paid plan and provide your credit card information for payment.
                </p>
                <p>
                  By agreeing to this agreement, you authorize automoviecreator to charge your credit card for any unpaid charges related to the services and agree to allow your credit card issuer to pay these charges without a signed receipt.
                </p>
                <p>
                  automoviecreator and/or any billing agent acting on their behalf may charge your credit card on a recurring basis each month/year, beginning on the anniversary of your initial registration for a paid plan, until all outstanding fees are paid in full. You are responsible for keeping your credit card information up to date, and any charges or holds placed on your credit card are solely your responsibility.
                </p>
                <div className="pl-4 border-l-4 border-blue-100 my-4">
                  <p className="italic">
                    Please note: If you mistakenly provide a debit card number, all charges will be applied to the debit card until you provide a credit card number.
                  </p>
                </div>
                <p>
                  If you are on a paid plan, your usage and monthly fees will be charged directly to your credit card, along with any additional fees such as local taxes. Late payment fees may be charged at a rate of 5% per month for amounts not paid by the due date. In the event of non-payment and the need for collection, a collection fee at the maximum percentage permitted by applicable law, not to exceed 18%, will be charged to cover internal collection-related costs.
                </p>
                <p>
                  If you use automoviecreator Service on a mobile device using a data connection, it may use your personal data plan's allowance. If you use a mobile data connection outside of your home country, you may incur roaming fees from your operator.
                </p>
                <div className="bg-gray-50 p-4 rounded-lg my-4">
                  <p className="font-medium mb-2">Invoice Disputes:</p>
                  <p>
                    If you dispute any portion of an invoice, you must notify automoviecreator within 10 calendar days of the invoice's issuance and provide documentation substantiating the basis for the dispute. Failure to dispute any portion of an invoice within 10 calendar days will result in the waiver of your right to dispute that invoice.
                  </p>
                </div>
                <p>
                  Lastly, automoviecreator is not obligated to transfer to you any specific complaints or requests for information received from law enforcement agencies as they are confidential. However, Enthu.AI may still charge you an administrative fee for any such requests for information.
                </p>
              </div>
            </div>

            {/* Fair Use Policy Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                6. Fair Use Policy
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed space-y-4">
                <p>
                  automoviecreator may offer plans that provide "unlimited" usage or minutes for normal business use in dialogues between two or more individuals. However, automoviecreator reserves the right to disconnect or suspend your service with prior notice if it determines that your use of the service violates any part of the Fair Use Policy.
                </p>
                <p>
                  The Service Description may be updated from time to time to add or remove services and improve their descriptions, and your continued use of the Services constitutes your acceptance of the new terms.
                </p>
              </div>
            </div>

            {/* Proprietary Rights Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                7. Proprietary Rights
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed space-y-4">
                <p>
                  automoviecreator owns and controls all aspects of the Service, including any content provided on the site, unless otherwise stated. The Service is intended for personal and commercial use only, and any copying or downloading of information or software must preserve any copyright or other notices included with the content.
                </p>
                <p>
                  You are not authorized to modify, copy, distribute, transmit, display, perform, reproduce, publish, license, frame, create derivative works from, transfer, or use any information, software, products, or services from the Service for commercial or public purposes without prior written permission from automoviecreator.
                </p>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="font-medium mb-2">Trademark Notice:</p>
                  <p>
                    All trademarks, trade names, service marks, and logos on the site belong to automoviecreator and may not be used in any way that is not expressly authorized in these Terms and Conditions or in writing by automoviecreator.
                  </p>
                </div>
              </div>
            </div>

            {/* Refund Policy Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                8. Refund Policy
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed space-y-4">
                <p>
                  When you cancel your account, you will not receive a refund for previous payments. Your card will not be charged from the next billing cycle. If you remove a user from your account, a new downgrade plan will be applicable from the next billing cycle.
                </p>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="font-medium mb-2">Refunds are only accepted in the following circumstances:</p>
                  <ul className="list-disc pl-5 space-y-1">
                    <li>Duplicate payment</li>
                    <li>Charges made by mistake after account cancellation</li>
                    <li>A refund requested during the initial trial period if the service fee was already paid</li>
                  </ul>
                </div>
                <p>
                  No claim for losses or damages related to the Agreement can be made more than six (6) months after the date of the event giving rise to such damages is known or should have been known.
                </p>
              </div>
            </div>

            {/* Content License Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                9. Content License
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed">
                <p>
                  By providing any content or feedback to automoviecreator, you are granting us a non-exclusive, perpetual, worldwide, irrevocable, royalty-free, and sub-licensable (through multiple tiers) license to use, copy, modify, create derivative works from, distribute, and display such content or feedback in any form or medium now known or later developed. This license includes the right to use any and all copyrights or other intellectual property rights that you have in the content or feedback.
                </p>
              </div>
            </div>

            {/* Disclaimer Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                10. Disclaimer
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed space-y-4">
                <div className="pl-4 border-l-4 border-yellow-100">
                  <p>
                    The content on the automoviecreator website is provided "as is". automoviecreator does not make any express or implied warranties and hereby denies all other warranties, including implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other rights violation.
                  </p>
                </div>
                <p>
                  Moreover, automoviecreator does not guarantee or make any representations regarding the accuracy, probable outcomes, or reliability of the use of the materials on its website or any linked sites. The automoviecreator Service is reliant on your third-party telephony provider; hence, we do not ensure the continuous or error-free functioning of any aspect of the Service.
                </p>
              </div>
            </div>

            {/* Limitations Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                11. Limitations
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed space-y-4">
                <p>
                  automoviecreator and its suppliers shall not be liable for any indirect, special, punitive, consequential or similar damages, including loss of data or profit, or interruption of business, arising out of the use or inability to use the Service, even if automoviecreator or its authorized representative has been advised of the possibility of such damages.
                </p>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p>
                    In any event, the maximum liability of automoviecreator or its officers, directors, employees, affiliates, agents, licensors, representatives, attorneys, and business partners, regardless of the type of action whether in contract, tort (including negligence), or otherwise, shall not exceed $100.
                  </p>
                </div>
              </div>
            </div>

            {/* Your Responsibility Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                12. Your Responsibility to Protect Data and Password
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed space-y-4">
                <p>
                  You have the responsibility to ensure the security and confidentiality of your personal data and login details. It is crucial that you immediately report any unauthorized use of your account or any breaches to automoviecreator.
                </p>
                <div className="pl-4 border-l-4 border-red-100">
                  <p>
                    automoviecreator will not be held liable for any losses resulting from the misuse of your login details, with or without your knowledge. You may also be held responsible for any charges or fees incurred due to your failure to safeguard your account information and/or report unauthorized use promptly.
                  </p>
                </div>
              </div>
            </div>

            {/* Compliance with Laws Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                13. Compliance with Laws
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed space-y-4">
                <p>
                  You are required to comply with all applicable laws and regulations when using the automoviecreator Service, including national, state, international, and other laws related to privacy, telemarketing, Internet marketing, and electronic communication.
                </p>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="font-medium mb-2">Important Notice:</p>
                  <p>
                    automoviecreator may access and share your personal details, including demographics, billing, call logs, usage, IP addresses, and user details, as required by law enforcement agencies, governing authorities, or regulatory bodies.
                  </p>
                </div>
              </div>
            </div>

            {/* Termination Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                14. Termination of License/Account/Service
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed space-y-4">
                <p>
                  automoviecreator has the right to terminate Your use of the Service at any time with or without cause. If You breach any part of automoviecreator's Fair Use Policy, it will be considered "taking advantage" and could result in termination of Your account.
                </p>
                <div className="pl-4 border-l-4 border-blue-100">
                  <p>
                    You may terminate this Agreement at any time by emailing us at  <a href="mailto:info@automoviecreator.com" class="text-blue-500 underline hover:text-blue-700">
                      info@automoviecreator.com
                    </a>

                   <span> or directly from Your automoviecreator account. Termination will become effective at the end of the current billing cycle.</span>
                  </p>
                </div>
              </div>
            </div>

            {/* Governing Law Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                15. Governing Law
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed">
                <p>
                  Any legal dispute related to the automoviecreator site will be governed by the laws of the UT of Chandigarh, India, without regard to its conflict of law provisions. The exclusive jurisdiction for any legal action arising from this agreement will be in a state or Indian court located in the UT of Chandigarh.
                </p>
              </div>
            </div>

            {/* Legality of Use Section */}
            <div className="border-t pt-6 mt-6">
              <h2 className="text-[20px] text-[#000] font-[500] font-custom mb-4">
                16. Legality of Use
              </h2>
              <div className="text-[14px] text-[#55595c] leading-relaxed">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p>
                    Before using automoviecreator's transcription service, it is your responsibility to verify its legality in your country. Any phone call that is prohibited or restricted by your country's laws must not be transcribed using the service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default TermsAndConditions;
