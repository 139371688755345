import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../provider/AuthProvider";
import axios from "axios";
import { serverbaseURL } from "../constant/index";
import { RiMailLine } from 'react-icons/ri';
import { motion } from "framer-motion";
import { FaWhatsapp, FaPinterest, FaLinkedin, FaTwitter, FaFacebook, FaCopy, FaGift } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import MyTemplates from "./MarketPlace/MyTemplates";




const AffiliateCreate = () => {
  const [paypalEmail, setPaypalEmail] = useState("");
  const { user } = useContext(AuthContext);
  // const [referralCode, setReferralCode] = useState("");
  const [referralLink, setReferralLink] = useState("");
  // const [loading, setLoading] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [baseUrl] = useState("https://hostinger.in?REFERRALCODE=");
  const [copied, setCopied] = useState(false);
  const [activeButton, setActiveButton] = useState('affiliate'); // Default is 'affiliate'
  const userId = user?.uid;

  const [activeTab, setActiveTab] = useState('affiliate');

  // Handle tab switching
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Dialog state
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    onClose: () => { },
  });

  const openDialog = (message, onClose = () => { }) => {
    setDialog({ open: true, message, onClose });
  };

  const closeDialog = () => {
    if (dialog.onClose) dialog.onClose();
    setDialog({ open: false, message: "", onClose: () => { } });
  };



  const handleCopy = () => {
    navigator.clipboard.writeText(referralLink); // Copy the referral link to clipboard
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
  };



  // Fetch referral code on component mount
  const fetchReferralCode = async () => {
    if (!userId) return; // Ensure userId is present
    try {
      const response = await axios.get(`${serverbaseURL}get-referral-code/${userId}`);

      setReferralLink(response.data.referralLink || "");
      // Store the updated email in state
      setUpdatedEmail(response.data.updatedEmail || "");
    } catch (error) {
      console.error("Error fetching referral code:", error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchReferralCode(); // Fetch referral code when the component mounts or userId changes
    }
  }, [userId]);




  // Handle PayPal email update
  const handleUpdateEmail = async () => {
    if (!paypalEmail.trim()) {
      openDialog("Please enter a valid PayPal email.");
      return;
    }

    if (window.confirm("Are you sure you want to update your PayPal account email?")) {
      try {
        const response = await axios.put(`${serverbaseURL}update-payment-email`, {
          userId,
          email: paypalEmail,
        });

        const { message, updatedEmail } = response.data;

        // Show all success messages from the backend
        openDialog(`Success!\n\nMessage: ${message}\nUpdated PayPal Email: ${updatedEmail}`);
        await fetchReferralCode(); // Fetch the updated referral link
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;

        // Check if additional error details exist
        const additionalErrorDetails = error.response?.data?.error || "";

        // Display error message with any additional details
        openDialog(`Error updating PayPal email:\n\nMessage: ${errorMessage}\n${additionalErrorDetails}`);
        console.error("Error updating PayPal email:", error);
      }
    }
  };




  const shareToSocialMedia = (platform) => {
    const url = referralLink;

    switch (platform) {
      case "facebook":
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, "_blank");
        break;
      case "twitter":
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=Check%20this%20out!`, "_blank");
        break;
      case "linkedin":
        window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=Referral%20Link`, "_blank");
        break;
      case "whatsapp":
        window.open(`https://wa.me/?text=${encodeURIComponent(url)}`, "_blank");
        break;
      case "pinterest":
        window.open(`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&media=&description=Referral%20Link`, "_blank");
        break;
      default:
        break;
    }
  };


  return (
    <div>
      <div className="flex justify-center gap-4 items-center mt-6 p-4">

        {/* Tab Navigation */}
        <div className="flex justify-center items-center pt-5">
          <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-8 items-center justify-center w-full max-w-xs sm:max-w-none">
            {/* Templates Button */}
            <motion.button
              onClick={() => handleTabChange('affiliate')}
              className={`px-8 py-3 rounded-full font-semibold text-lg shadow-md transition-all duration-300 ${activeTab === 'affiliate'
                ? "bg-gradient-to-r from-[#805af5] to-blue-600 text-white shadow-lg"
                : "bg-gray-200 text-gray-600 hover:bg-gradient-to-r hover:from-[#6eaffb] hover:to-[#4d8bfd] hover:text-white hover:shadow-lg"
                }`}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              Affiliate Link
            </motion.button>

            {/* My Generations Button - Only show if user is logged in */}
            {user && (
              <motion.button
                onClick={() => handleTabChange('mytemplates')}
                className={`px-8 py-3 rounded-full font-semibold text-lg shadow-md transition-all duration-300 ${activeTab === 'mytemplates'
                  ? "bg-gradient-to-r from-[#805af5] to-blue-600 text-white shadow-lg"
                  : "bg-[#efefff] text-gray-600 hover:bg-gradient-to-r from-[#805af5] to-blue-600 hover:text-white hover:shadow-lg"
                  }`}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                My Templates
              </motion.button>
            )}
          </div>
        </div>


        {/* <NavLink
          to="/affiliate"
          className={`bg-gradient-to-r from-[#805af5] to-blue-600 text-white shadow-lg px-4 py-2 rounded mr-4 inline-block transition-all transform ${activeButton === 'affiliate'
            ? 'bg-[#3b82f6] text-white border-2 border-black scale-110'
            : 'bg-gradient-to-r from-[#805af5] to-blue-600 text-white'
            }`}
          onClick={() => setActiveButton('affiliate')}
        >
          Affiliate Link
        </NavLink>

       
        <NavLink
          to="/marketplace/mytemplate"
          className={`bg-gradient-to-r from-[#805af5] to-blue-600 text-white shadow-lg px-4 py-2 rounded inline-block transition-all transform ${activeButton === 'template'
            ? 'bg-[#3b82f6] text-white border-2 border-black scale-110'
            : 'bg-gradient-to-r from-[#805af5] to-blue-600 text-white'
            }`}
          onClick={() => setActiveButton('template')}
        >
          My Template
        </NavLink> */}
      </div>

      {/* Content based on active tab */}
      <div>
        {activeTab === 'affiliate' ? (
          <>
            <div className=" flex justify-evenly ">

              <div className="px-4 md:px-12 min-h-screen w-[720px] mt-16">
                <div className="bg-gradient-to-b from-[#eef2ff] to-[#f7f8ff] max-w-2xl mx-auto px-6 py-8 md:px-10 shadow-2xl rounded-xl border border-gray-200">
                  {/* Header */}
                  <motion.h2
                    initial={{ y: -30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}
                    className="text-2xl font-bold mb-4 text-gray-800 text-center"
                  >
                    Share Your Referral Link
                  </motion.h2>

                  <div className="space-y-12">
                    {/* Affiliate Link Section */}
                    <motion.div
                      initial={{ opacity: 0, x: -50 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.6, delay: 0.2 }}
                      className="bg-white p-4 rounded-lg shadow-lg"
                    >
                      <h2 className="text-base text-gray-600 mb-4 leading-relaxed">
                        Refer a new client and earn a <span className="font-bold text-[#4f75e1]">20% commission</span>. They'll get a <span className="font-bold text-[#4f75e1]">20% discount</span> too.
                      </h2>
                      <div className="flex items-center">
                        <input
                          type="text"
                          name="affiliate"
                          className="w-full px-4 py-2 rounded-lg bg-[#f9f9f9] border border-gray-300 focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200 text-gray-700"
                          value={`${referralLink}`}
                          readOnly
                        />
                        <button
                          onClick={handleCopy}
                          className="ml-2 p-2 rounded-full bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 shadow-md transition-all"
                        >
                          <FaCopy className="h-5 w-5 text-white" />
                        </button>
                      </div>
                      {copied && (
                        <p className="text-sm text-green-500 mt-2">Copied to clipboard!</p>
                      )}
                    </motion.div>

                    {/* Social Media Share Section */}
                    <div className="mt-8">
                      <p className="font-medium text-lg text-gray-700 mb-3">Share via Social Media</p>
                      <div className="flex flex-wrap gap-4">
                        <button
                          onClick={() => shareToSocialMedia('facebook')}
                          className="p-3 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition-all"
                        >
                          <FaFacebook className="h-6 w-6" />
                        </button>
                        <button
                          onClick={() => shareToSocialMedia('twitter')}
                          className="p-3 bg-[#1da1f2] text-white rounded-full shadow-lg hover:bg-[#1991d0] transition-all"
                        >
                          <FaTwitter className="h-6 w-6" />
                        </button>
                        <button
                          onClick={() => shareToSocialMedia('linkedin')}
                          className="p-3 bg-blue-800 text-white rounded-full shadow-lg hover:bg-blue-900 transition-all"
                        >
                          <FaLinkedin className="h-6 w-6" />
                        </button>
                        <button
                          onClick={() => shareToSocialMedia('whatsapp')}
                          className="p-3 bg-green-500 text-white rounded-full shadow-lg hover:bg-green-600 transition-all"
                        >
                          <FaWhatsapp className="h-6 w-6" />
                        </button>
                        <button
                          onClick={() => shareToSocialMedia('pinterest')}
                          className="p-3 bg-red-500 text-white rounded-full shadow-lg hover:bg-red-600 transition-all"
                        >
                          <FaPinterest className="h-6 w-6" />
                        </button>
                      </div>
                    </div>

                    {/* PayPal Email Section */}
                    <motion.div
                      initial={{ opacity: 0, x: 50 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.6, delay: 0.4 }}
                      className="bg-white p-4 rounded-lg shadow-lg"
                    >
                      <h2 className="flex items-center text-lg font-bold text-gray-700 mb-3">
                        <RiMailLine size={24} className="text-[#4f75e1] mr-2" />
                        PayPal Account Email
                      </h2>
                      <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
                        <input
                          type="email"
                          name="paypal"
                          className="w-full px-4 py-2 rounded-lg bg-[#f9f9f9] border border-gray-300 focus:border-[#4f75e1] focus:ring-[#4f75e1] focus:outline-none transition duration-200 text-gray-700"
                          placeholder="PayPal Account"
                          value={paypalEmail}
                          onChange={(e) => setPaypalEmail(e.target.value)}
                        />
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className="bg-gradient-to-r from-[#805af5] to-blue-600 text-white py-2 px-6 rounded-md text-sm font-bold hover:shadow-xl transition duration-200"
                          onClick={handleUpdateEmail}
                        >
                          Update
                        </motion.button>
                      </div>
                      <p className="text-center text-sm font-medium text-gray-500 mt-6">
                        You will receive your commission here:
                      </p>
                      <p className="text-center text-md font-semibold text-gray-900">
                        <span className="text-gray-500">PayPal Account:</span> {updatedEmail || "No PayPal account updated yet."}
                      </p>
                    </motion.div>
                  </div>
                </div>
              </div>





              {dialog.open && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white rounded-lg shadow-lg p-6 w-4/5 max-w-md text-center">
                    <p className="text-gray-800 font-medium text-lg">{dialog.message}</p>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={closeDialog}
                      className="bg-gradient-to-r from-[#805af5] to-blue-600 text-white px-4 py-2 rounded-md shadow mt-4 transition duration-200"
                    >
                      OK
                    </motion.button>
                  </div>
                </div>
              )}


              {/* Right section remains unchanged */}
              <div className="px-4 md:px-12 min-h-screen w-[720px] mt-16">
                <div className="bg-gradient-to-b from-[#eef2ff] to-[#f7f8ff] max-w-2xl mx-auto px-6 py-10 md:px-10 shadow-2xl rounded-xl border border-gray-200">
                  <motion.h2
                    initial={{ y: -30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}
                    className="text-2xl font-bold mb-6 text-gray-800 text-center tracking-wide"
                  >
                    How Do Referrals Work?
                  </motion.h2>

                  <div className="space-y-12">
                    {/* Referral Steps Section */}
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.6, delay: 0.2 }}
                    >
                      <div className="flex gap-4 mb-8 items-center">
                        <div className="p-2 bg-blue-100 rounded-full">
                          <svg
                            viewBox="0 0 24 24"
                            className="h-8 w-8 text-blue-600"
                          >
                            <path
                              fill="currentColor"
                              d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"
                            />
                          </svg>
                        </div>
                        <div>
                          <h3 className="font-medium text-lg text-gray-800 mb-1">You send an invite</h3>
                          <p className="text-sm text-gray-600">
                            Pick a plan for them or send them a referral link so they can
                            choose themselves.
                          </p>
                        </div>
                      </div>

                      <div className="flex gap-4 mb-8 items-center">
                        <div className="p-2 bg-green-100 rounded-full">
                          <svg
                            viewBox="0 0 24 24"
                            className="h-8 w-8 text-green-600"
                          >
                            <path
                              fill="currentColor"
                              d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
                            />
                          </svg>
                        </div>
                        <div>
                          <h3 className="font-medium text-lg text-gray-800 mb-1">
                            They buy a hosting or your products
                          </h3>
                          <p className="text-sm text-gray-600">
                            Plans of 12 months or longer are eligible for referral rewards.
                          </p>
                        </div>
                      </div>

                      <div className="flex gap-4 mb-8 items-center">
                        <div className="p-2 bg-yellow-100 rounded-full">
                          <svg
                            viewBox="0 0 24 24"
                            className="h-8 w-8 text-yellow-600"
                          >
                            <path
                              fill="currentColor"
                              d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z"
                            />
                          </svg>
                        </div>
                        <div>
                          <h3 className="font-medium text-lg text-gray-800 mb-1">
                            They're active for at least 45 days
                          </h3>
                          <p className="text-sm text-gray-600">
                            The referred client keeps their subscription active and doesn't
                            request a refund.
                          </p>
                        </div>
                      </div>

                      <div className="flex gap-4 items-center">
                        <div className="p-2 bg-purple-100 rounded-full">
                          <FaGift className="h-8 w-8 text-purple-600" />
                        </div>
                        <div>
                          <h3 className="font-medium text-lg text-gray-800 mb-1">
                            You get your commission
                          </h3>
                          <p className="text-sm text-gray-600">
                            Your earnings are sent to you via PayPal or Wire Transfer.
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>


            </div>
          </>
        ) : (
          <MyTemplates className="mb-8" />
        )}
      </div>
    </div>
  );
};

export default AffiliateCreate;
