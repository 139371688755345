import React, { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../provider/AuthProvider";
import axios from "axios";
import { serverbaseURL } from "../constant/index";
import { toast } from 'react-toastify';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { googleSignIn, setLoading, setPostLoginCallback, setRedirectAfterLogin, redirectAfterLogin } =
    useContext(AuthContext);

  useEffect(() => {
    if (location.state?.from) {
      setRedirectAfterLogin(location.state.from.pathname);
    }
    else {
      setRedirectAfterLogin("/");
    }
  }, [location, setRedirectAfterLogin]);


  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      const googleData = await googleSignIn();
      const user = googleData.user;
      const userData = {
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        uid: user.uid,
      };

      const saveUserToDB = async () => {
        try {
          const response = await axios.post(`${serverbaseURL}user`, userData);
          if (response.status === 201) {
            console.log("User successfully registered:", response.data);
          } else {
            console.log("User already exists:", response.data);
          }
        } catch (error) {
          console.error("Error saving user to database:", error);
          toast.error("An error occurred while saving your data. Please try again.");
        }
      };

      setPostLoginCallback(() => saveUserToDB);
      navigate(redirectAfterLogin);
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      toast.error("An error occurred during login. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
      <div className="w-full max-w-md bg-white rounded-lg p-8 shadow-md ">
        <h1 className="text-gray-800 text-3xl text-center font-semibold">
          Login
        </h1>
        <p className="text-gray-500 text-center mt-2">Access your account</p>
        <div className="flex justify-center items-center mt-6">
          <button
            className="w-full bg-blue-600 text-white rounded-lg px-6 py-3 font-medium shadow-sm hover:bg-blue-700 transition duration-150"
            onClick={handleGoogleSignIn}
          >
            Sign in with Google
          </button>
        </div>
        <div className="flex justify-center items-center gap-1 mt-6 text-gray-600">
          <p className="text-sm">Not registered yet?</p>
          <Link to="/register" className="text-blue-600 hover:underline">
            Register
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
